
import { Step4 } from "../../../partials/modals/create-app-stepper/steps/Step4"
import  { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import { fetchLoginCookie, decryptData } from '../../../../api'
import { useParams } from "react-router-dom";
import styles from './AgentToolPanel.module.css'
import CloseIcon from '@mui/icons-material/Close';

interface props {
  setShowIndexModal: Dispatch<SetStateAction<boolean>>
  showIndexModal: boolean
  handleButtonClick: (buttonName: string) => void
  onFetchClick?: () => void;
  setIsFetchingData?:Dispatch<SetStateAction<boolean>>
  categ?:number
  emailAlias?: string
}

export const IndexData = ({ setShowIndexModal, showIndexModal, handleButtonClick, onFetchClick ,setIsFetchingData, categ, emailAlias}: props) => {
  const {agentId} = useParams()

  const handleClose = () => {
    handleButtonClick('chat')
    setShowIndexModal(false);
  }

  //for indexing
  const [selectedAgent, setSelectedAgent] = useState<number>();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [uploadError, setUploadError] = useState<string>("");

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);

    if (userId) {
      fetchUserActiveAgentConfig()
      setLoggedInUserId(userId);

    }

  }, [selectedOption])
  const fetchUserActiveAgentConfig = async () => {
    try {
      if(agentId){
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        setSelectedAgent(parsedAgentID);
      } 
      if (categ) {
        setSelectedAgent(categ);
      }
    }
     catch (error) {
      console.error(error);
    }
  };


  return (
    <div>

      <Modal style={{marginLeft:"30px"}} 
        show={showIndexModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
        keyboard={true}

      > <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>
          Select Data Source
          </Modal.Title>
        <div style={{cursor:"pointer"}} className='closing' onClick={handleClose}><CloseIcon/></div>

        </Modal.Header>

        <Modal.Body >

          
          <Step4 emailAlias={emailAlias!} setIsFetchingData={setIsFetchingData} flag={true}  uploadError={uploadError} loggedInUserId={loggedInUserId} setUploadError={setUploadError} selectedAgent={selectedAgent || categ} selectedOption={selectedOption} setSelectedOption={setSelectedOption} onFetchClick={onFetchClick}></Step4>
        </Modal.Body>
      </Modal>

    </div>
  )
}

