import { Link } from 'react-router-dom';
import { Header } from './Header';
import styles from './TermsOfService.module.css'

export const TermsOfService = () => {
    return (
        <>
            <Header />
            <div className={styles.content}>
                <h2 className={` ${styles.heading}`}>TERM OF USE</h2>
                <p>This is a legal and binding agreement between you, the user (referred to as “user” or “you”) and Data Science Dojo (referred as “we”, “us” or “Data Science Dojo”) stating terms of use that govern your use of our application, as defined below. Please read these Data Science Dojo terms of use, Privacy Policy and other policies on the application before accessing or using our application. By visiting or using our application you acknowledge that you have the legal capacity to bind yourself to this agreement and you agree to become bound by the terms and conditions of this agreement. If you are using or visiting our application on behalf of any entity, you represent and warrant that you are authorized to accept these Terms on such entity’s behalf, and that such entity agrees to indemnify you and Data Science Dojo for violations of these Terms. If you do not agree to all of the terms and conditions of this agreement notify Data Science Dojo immediately and must not use-and not authorized to use- all or any portion of the application and services (as defined below). Our acceptance is expressly conditioned upon your assent to all the terms and conditions of this agreement, to the exclusion of all other terms. If these terms and conditions are considered an offer by us, acceptance is expressly limited to these terms. </p>
                <h2 className={` ${styles.heading}`}>YOUR RIGHT TO USE OUR APPLICATION</h2>
                <p>These Terms of Use permit you to use the application for your personal, non-commercial use only. Except as provided above, no right, title or interest in or to the application or any content on the application is transferred to you, and all rights not expressly granted are reserved by Data Science Dojo and its affiliates. Any use of the application not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws. </p>
                <p>You shall NOT, without the prior written consent of Data Science Dojo: </p>
                <ul>
                    <li>Reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our application. </li>
                    <li>Decompile, reverse engineer, disassemble or create derivative works of our application.</li>
                    <li>Users may not transmit content that is obscene, offensive, defamatory or otherwise objectionable. </li>
                    <li>Users may not transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code. </li>
                    <li>Remove, alter or obscure any copyright, trademark, patent designations or other proprietary rights notices from copies of materials from our application. </li>
                    <li>Access or use for any commercial purposes any part of our application or any services or materials available through the application. </li>
                    <li>use the application or Service for any mission or life-critical purposes, including the migration of any data which, if lost or corrupted, could endanger the health or safety of any person; </li>
                    <li>use the application or Service in ways that may be harmful to others or Data Science Dojo’s reputation, including the use of any portion of the Services as a destination linked from any unsolicited bulk messages or unsolicited commercial messages; or </li>
                    <li>Interrupt, or attempt to interrupt, the operation of the application in any way. </li>
                    <li>Co-brand, frame, or hyper-link to the application, or authorize or engage any other party to do any of the foregoing. For purposes of this Agreement, "co-brand" or "co-branding" means to display a name, logo, trademark, or other means of attribution or identification of any party in such a manner as is reasonably likely to give a user the impression that such other party has the right to display, publish, or distribute this application or content accessible within this application. </li>
                </ul>
                <h2 className={` ${styles.heading}`}>INTELLECTUAL PROPERTY RIGHTS </h2>
                <p>The application and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by Data Science Dojo and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. </p>
                <p>The “Data Science Dojo” name, logo, and seal is the trademark of Data Science Dojo C-Corporation. You may not use this Trademark, or any variation thereof, without our prior written consent. You may not use this Trademark, or any variation thereof, for promotional purposes, or in any way that deliberately or inadvertently claims, suggests or, in our sole judgment, gives the appearance or impression of a relationship with or endorsement by us. Should you use this Trademark with or without our prior written consent, you agree that any goodwill developed by your use of the mark shall accrue to the Grantor. You agree to not adopt a mark in the future that is or may be confusingly similar to this Trademark. You may not register a domain name that is confusingly similar to this Trademark. In addition, the look and feel of the application, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Data Science Dojo and may not be copied, imitated or used, in whole or in part, without our prior written permission. All other trademarks, registered trademarks, product names and names or logos mentioned on the application are the property of their respective owners. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us. </p>
                <p>Data Science Dojo does not take responsibility for the content or other sites that you may find when searching or accessing our products or services. The Privacy Policy and Terms of Use of the sites that you visit will administer that material. </p>
                <p>Data Science Dojo has all the rights to disable or prohibit access to the users who do not respect and involve in the infringement of Data Science Dojo intellectual property. </p>
                <p>Nothing in this Agreement or in the materials should be construed as granting, by implication, estoppel or otherwise, any license or right to use the Trademark without the written permission of the owner of the Trademark. </p>

                <h2 className={` ${styles.heading}`}>TERMINATION </h2>
                <p>We reserve the right to terminate your use of this application if you violate the Terms of Use or any rules or guidelines posted on the application or for any other reason in our discretion. In case of such termination, you must cease all use of the application, and Data Science Dojo may immediately revoke your access to the application and our services without refund of any fees. Our failure to insist upon or enforce your strict compliance with these Terms will not constitute a waiver of any of our rights. </p>
                <h2 className={` ${styles.heading}`}>REPORTING COPYRIGHT VIOLATIONS </h2>
                <p>The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S. copyright law. If you believe in good faith that materials posted on the Daja Science Dojo application infringe your copyright, you (or your agent) may send Data Science Dojo a “Notification of Claimed Infringement” requesting that the material be removed, or access to it blocked. The notice must include the following information: </p>

                <ul>
                    <li>A physical or electronic signature of a person authorized to act on behalf of the owner of the works that have been allegedly infringed; </li>
                    <li>Identification of the copyrighted work alleged to have been infringed (or if multiple copyrighted works located on the Daja Science Dojo Service are covered by a single notification, a representative list of such works); </li>
                    <li>Identification of the specific material alleged to be infringing or the subject of infringing activity, and information reasonably sufficient to allow Data Science Dojo to locate the material on the Data Science Dojo Service; </li>
                    <li>Your name, address, telephone number, and email address (if available);</li>
                    <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and </li>
                    <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. </li>
                </ul>
                <p>If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send Data Science Dojo a counter-notice.</p>
                <p>Notices and counter-notices must meet the then-current statutory requirements imposed by the DMCA; see <a target='_blank' href='https://www.copyright.gov/'>here </a> for details. Notices and counter-notices with respect to the Data Science Dojo Service should be sent to 2205 152nd Ave NE Redmond, WA 98052. <a href='mailto:data-protection@datasciencedojo.com' target='_blank'>(data-protection@datasciencedojo.com)</a>. Consult your legal advisor and see 17 U.S.C. § 512 before filing a notice or counter-notice as there are penalties for false claims under the DMCA.</p>

                <h2 className={` ${styles.heading}`}>MISCELLANEOUS  </h2>
                <p>These Terms of Use constitute the entire agreement between you and Data Science Dojo. There is no agency, partnership or joint venture relationship between any user and Data Science Dojo arising solely through the use of this application. These Terms of Use are binding upon your successors, assigns, heirs and executors. If any provision of these Terms of Use is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Term of Use, which shall remain in full force and effect. The failure of Data Science Dojo to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or condition. Any claim or cause of action arising out of or related to the application, or these Terms of Use must be filed within one year after such claim or cause of action arose. The section titles of these Terms of Use are merely for convenience and will not have any effect on the substantive meaning of this agreement. </p>

                <h2 className={` ${styles.heading}`}>PRIVACY POLICY & CONSENT  </h2>
                <p>The Application’s Privacy Policy is located  <Link to='/privacy-policy' target='_blank'>
                    here
                </Link>. By using the application, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy. Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, as a resident of the EEA, you have certain rights in relation to your personal information and must affirmatively consent to the processing of your personal information or data. </p>

                <h2 className={` ${styles.heading}`}>DISCLAIMER OF WARRANTY  </h2>
                <p>The material and any information, content, or services made available to you are provided “as is” and “as available” without warranty of any kind (express, implied, or otherwise), including, without limitation, any implied warranties of merchantability, fitness for a particular purpose, and non-infringement, except insofar as any such implied warranties may not be disclaimed under applicable law. </p>
                <p>WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT: (A) THE DATA SCIENCE DOJO APPLICATION WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (B) DATA SCIENCE DOJO APPLICATION, INCLUDING THE SERVERS ON WHICH THE APPLICATION IS OPERATED, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; (C) YOUR INFORMATION, INCLUDING YOUR PERSONAL INFORMATION, WILL NOT BE DISCLOSED OR (D) INFORMATION PROVIDED ON THE APPLICATION IS COMPLETE, ACCURATE, ERROR-FREE, OR UP-TO-DATE. </p>
                <h2 className={` ${styles.heading}`}>LIMITATION OF LIABILITY  </h2>
                <p>IN NO EVENT WILL DATA SCIENCE DOJO, ITS AFFILIATES OR SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APPLICATION, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE APPLICATION OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE APPLICATION OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. </p>
                <h2 className={` ${styles.heading}`}>INJUNCTIVE RELIEF  </h2>
                <p>You agree that any breach of your obligations with respect to Company’s proprietary or intellectual rights will result in irreparable injury to Company for which money damages re inadequate, you therefore agree that Company shall be entitled to seek injunctive relief, without the requirement of posting a bond, in addition to any other relief that a court may deem proper. </p>
                <h2 className={` ${styles.heading}`}>CHANGES  </h2>
                <p>Data Science Dojo reserves the right to change or modify these Terms, or any policy or guideline of the application, at any time and in our sole discretion. Any changes or modifications will be effective immediately upon posting the revisions on the application, and you waive any right you may have to receive specific notice of such changes or modifications. Your continued use of the application following the posting of changes or modifications will confirm your acceptance of such changes or modifications. Therefore, you should frequently review the Terms and applicable policies to understand the terms and conditions that apply to your use of the application. If you do not agree to the amended terms, you must stop using the application. </p>
                <p>If you have any questions regarding the application, please contact Data Science Dojo at <a href='mailto:data-protection@datasciencedojo.com' target='_blank'>data-protection@datasciencedojo.com</a>. </p>
                <h2 className={` ${styles.heading}`}>INTERNATIONAL USERS  </h2>
                <p>Data Science Dojo Services are operated from the United States but may be accessed in other parts of the world. If you are located outside of the United States and you access or use Data Science Dojo Services, you acknowledge, understand and agree that you are doing so at your own initiative and at your own risk and that it is your responsibility (and not Data Science Dojo) to make sure that your use of Data Science Dojo Services complies with all applicable local laws in the jurisdiction from where you access or use Data Science Dojo Services. If you are located in the United Kingdom, for purposes of the Data Protection Act 1998, Data Science Dojo is the data controller with respect to any information collected by it in the United Kingdom and then transferred to Data Science Dojo in the United States. By submitting any personally identifiable information to us through Data Science Dojo Services, you consent to the transfer, processing and storage of your information in the United States in accordance with our Privacy Policy. </p>
                <h2 className={` ${styles.heading}`}>APPLICABLE LAW/ FORUM SELECTION  </h2>
                <p>You agree that these Terms and any claim or dispute arising out of or relating to these Terms, or any content or service obtained from or through this agreement be governed by the laws of the State of Washington, excluding its conflicts of law provisions, when applicable, and the laws of the United States of America when applicable. You agree that all such claims and disputes will be heard and resolved exclusively in the federal or state courts located in and serving Seattle, Washington. You consent to the personal jurisdiction of and exclusive venue in the federal courts located in and serving Seattle, Washington as the legal forum for any such dispute. And you waive and agree not to assert any objection to such proceedings in those courts (including any defense or objection of lack of proper jurisdiction or venue or inconvenience of forum). </p>
                <h2 className={` ${styles.heading}`}>INDEMNIFICATION  </h2>
                <p>You agree to defend, hold harmless, and indemnify Data Science Dojo and Data Science Dojo course Participants, and their respective subsidiaries, affiliates, officers, faculty, students, fellows, owners governing board members, agents, and employees with respect to any claim, demand, cause of action, debt or liability, including reasonable attorneys’ fees, to the extent that it is based upon a claim that, if true, would constitute a breach of any of your obligations under these Terms. </p>
                <h2 className={` ${styles.heading}`}>ARBITRATION </h2>
                <p>Excluding claims for injunctive or other equitable relief, for claims where the total amount sought is less than fifty thousand U.S. Dollars ($50,000.00 USD) the parties shall first attempt to resolve the dispute through binding non-appearance-based arbitration using a mutually agreed upon established alternative dispute resolution (“ADR”) provider. The parties and the selected ADR provider shall not involve any personal appearance by the parties or by witnesses, unless otherwise mutually agreed by the parties; rather, the arbitration shall be conducted, at the option of the party seeking relief, by telephone, or via written submissions alone. Any judgment rendered by the Arbitrator shall be entered in a court of competent jurisdiction. </p>
                <p>You agree that arbitration for any cause of action brought by you against the Company or its affiliates related to the material, or your use of our services must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred from adjudication. </p>
                <h2 className={` ${styles.heading}`}>YOUR COMMENTS AND CONCERNS  </h2>
                <p>This application is operated by Data Science Dojo. All feedback, comments, requests for technical support and other communications relating to the application should be directed to: <a href='mailto:data-protection@datasciencedojo.com' target='_blank'>(data-protection@datasciencedojo.com)</a>.

                </p>

            </div>
        </>
    );
}