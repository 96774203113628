import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { getEnv } from '../api'

const App = () => {

  const [gtmId, setGtmId] = useState<any>(null);

  useEffect(() => {
    fetchGtagEnv();
  }, []);

  useEffect(() => {
    if (gtmId) {
      // Inject the GTM script into the head
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      document.head.appendChild(script);

      // Inject the GTM noscript into the body
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);

      // Cleanup function to remove the script and noscript when the component is unmounted or gtmId changes
      return () => {
        document.head.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, [gtmId]);

  const fetchGtagEnv = async () => {
    try {
      const response = await getEnv("gtag");
      if (response) {
        setGtmId(response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
