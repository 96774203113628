import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useState, useEffect, useRef, SetStateAction, Dispatch } from 'react'
import {
  fetchLoginCookie,
  decryptData,
  getUserNotifications,
  readNotification,
  readAllNotifications,
} from '../../../../api'
import CircularProgress from '@mui/material/CircularProgress'
import { NotificationResponse } from '../../../../api/models'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { encryptData } from '../../../../api'
import CloseIcon from '@mui/icons-material/Close';




interface NotificationTabProps {
  isClicked: boolean;
  handleMarkAllAsReadClick: () => void;
  openNotif: boolean;
  setopenNotif: Dispatch<SetStateAction<boolean>>;
}

export const NotificationTab = ({ isClicked, handleMarkAllAsReadClick, openNotif, setopenNotif }: NotificationTabProps) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [allNotifications, setAllNotifications] = useState<NotificationResponse[]>([])
  const [userID, setUserID] = useState<number | null>(null)
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const userInfo = fetchLoginCookie()
    const decryptedRes = decryptData(userInfo)
    const userId = parseInt(decryptedRes?.id)
    setUserID(userId)
  }, [])

  useEffect(() => {
    callgetUserNotification()
  }, [isClicked])

  const callgetUserNotification = async () => {
    if (userID) {
      const response = await getUserNotifications(userID);
      const sortedResponse = response.sort((a, b) => {
        return new Date(a.created_on).getTime() - new Date(b.created_on).getTime();
      });


      setAllNotifications(sortedResponse);
      setIsLoading(false);
    }

  };


  const handleNotificationClick = async (agentId: number | null, id: number) => {
    try {
      readNotification(id)
      if (agentId) {
        const agentID = encodeURIComponent(encryptData(agentId));
        navigate(`/agent/${agentID}?notification=true`);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const formatTimeDifference = (created_on: string) => {
    const createdOn = new Date(created_on)
    const now = new Date()
    const timeDifference = now.getTime() - createdOn.getTime()
    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (days > 0) {
      return `${days} day${days === 1 ? '' : 's'} ago`
    } else if (hours > 0) {
      return `${hours} hr${hours === 1 ? '' : 's'} ago`
    } else if (minutes > 0) {
      return `${minutes} min${minutes === 1 ? '' : 's'} ago`
    } else {
      return `${seconds} second${seconds === 1 ? '' : 's'} ago`
    }
  }

  const handleMarkAllAsRead = async () => {
    try {
      setIsLoading(true)
      const response = await readAllNotifications(userID)
      const sortedResponse = response.sort((a, b) => {
        return new Date(a.created_on).getTime() - new Date(b.created_on).getTime();
      });
      setAllNotifications(sortedResponse)

      setIsLoading(false)
      handleMarkAllAsReadClick();
    } catch (error) {
      console.error(error)
    }
  }

  const parseNotificationHeading = (notification: string) => {
    const parts = notification.split(' - ');
    return parts[0];
  }

  const parseNotificationBody = (notification: string) => {
    const firstHyphenIndex = notification.indexOf(' - ');
   
    if (firstHyphenIndex === -1) {
        return notification;
    }
   
    return notification.substring(firstHyphenIndex + 3);
}

  const closenotifdiv = () => {
    if (openNotif == true) {
      const element = document.getElementsByClassName('notifModal')[0] as HTMLElement;
      element.click()
    }
  }
  const divRef = useRef<HTMLDivElement>(null);



  return (

    <div ref={divRef}
      className='notif menu menu-sub menu-sub-dropdown menu-column w-300px w-lg-375px '
      data-kt-menu='true'
      style={{ marginRight: '-25px', zIndex: '10000' }}
    >
      <div
        className='d-flex bgi-no-repeat rounded-top justify-content-between'
        style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')` }}
      >
        <h4 className='text-white px-9 mt-8 mb-6 fw-light'>Notification</h4>
        <div style={{
          background: "none", marginTop: "25px", cursor: "pointer", marginRight: '10px'
        }} onClick={closenotifdiv}><CloseIcon style={{ color: "white" }}></CloseIcon></div>
      </div>
      <div className='tab-content' >
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          {allNotifications.length > 0 && <div
            className='d-flex justify-content-end align-items-center px-6'
            onClick={handleMarkAllAsRead}
            style={{

              marginBottom: '-15px',
              marginRight: '5px',
            }}
          >
            <div
              className='markread'
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                padding: '7px',
                margin: '6px',
                color: 'var(--bs-primary)',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '5px',

              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#F1FAFF'
                e.currentTarget.style.color = 'var(--bs-primary)'
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = 'transparent'
                e.currentTarget.style.color = 'var(--bs-primary)'
              }}
            >
              <CheckCircleIcon
                color='inherit'
                onClick={handleMarkAllAsRead}
                style={{ marginRight: '5px' }}
              />
              Mark all as read
            </div>
          </div>}

          {!isLoading ? (
            allNotifications.length > 0 ? (
              <div
                className='scroll-y mh-325px my-5 px-1'
                style={{ maxWidth: "370px" }}
              >
                {allNotifications
                  .slice()
                  .reverse()
                  .map((notification, index) => (
                    <div
                      key={`alert${index}`}
                      style={{ cursor: notification.agent ? 'pointer' : 'default', wordWrap: "break-word" }}
                      className={`d-flex flex-stack py-2 px-2 ${!notification.is_read ? 'bg-light-primary' : ''
                        }`}
                      onClick={() => {
                        handleNotificationClick(notification.agent, notification.id)
                      }}
                    >
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-35px me-4'>
                          <span className={clsx('symbol-label', `bg-light-primary`)}>
                            <NotificationsIcon color='primary' />
                          </span>
                        </div>

                        <div className='mb-0 me-2' style={{ maxWidth: "304px", marginLeft: "-3px", }}>
                          <a className='text-gray-400 fs-8'>{parseNotificationHeading(notification.content)}</a>
                          <div className={`fs-7 ${notification.agent ? 'text-hover-primary' : ''} fw-normal`}>
                            {parseNotificationBody(notification.content)}
                          </div>
                          <span className='badge badge-light fs-9'>
                            {formatTimeDifference(notification.created_on)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className='text-center my-5'>No notifications yet...</div>
            )
          ) : (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: '150px' }}
            >
              <CircularProgress color='primary' style={{ margin: '10px' }} />
            </div>
          )}
        </div>
      </div>
    </div>


    // </div>
  )
}