import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ScrollTopComponent,
  DrawerComponent,
  ToggleComponent,
  StickyComponent,
} from '../../../assets/ts/components'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'

interface props {
  triggerScrollBottom: boolean;
  setTriggerScrollBottom: Dispatch<SetStateAction<boolean>>;
}
export function ScrollTop({ triggerScrollBottom, setTriggerScrollBottom }: props) {
  const { pathname } = useLocation()
  const isFirstRun = useRef(true)

  useEffect(() => {
    if(triggerScrollBottom){
      scrollBottom()
      setTriggerScrollBottom(false)
    }
  }, [triggerScrollBottom])
  const pluginsReinitialization = () => {
    setTimeout(() => {
      StickyComponent.reInitialization()
      setTimeout(() => {
        ToggleComponent.reinitialization()
        DrawerComponent.reinitialization()
      }, 70)
    }, 140)
  }

  const scrollTop = () => {
    ScrollTopComponent.goTop()
  }
  const scrollBottom = () => {
    var bodyElement = document.body;
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }
  const updateHeaderSticky = () => {
    const stickyHeader = document.body.querySelectorAll(`[data-kt-sticky-name="header"]`)
    if (stickyHeader && stickyHeader.length > 0) {
      const sticky = StickyComponent.getInstance(stickyHeader[0] as HTMLElement)
      if (sticky) {
        sticky.update()
      }
    }
  }
  const handleScroll = () => {

    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const bodyHeight = document.body.scrollHeight;
    const nearTop = scrollPosition < windowHeight / 2;
    const nearBottom = scrollPosition > bodyHeight - windowHeight * 1.5;

    const scrollTopButton = document.getElementById('kt_scrolltop');
    const scrollBottomButton = document.getElementById('kt_scrollbottom');

    if (nearTop && scrollTopButton && scrollBottomButton) {
      scrollTopButton.style.display = 'none';
      scrollBottomButton.style.display = 'flex';
    }

    if (nearBottom && scrollBottomButton && scrollTopButton) {
      scrollBottomButton.style.display = 'none';
      scrollTopButton.style.display = 'flex';
    }
  };
  window.addEventListener('scroll', handleScroll);
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      pluginsReinitialization()
    }

    updateHeaderSticky()
    setTimeout(() => {
      scrollTop()
    }, 0)
  }, [pathname])

  return (<>

    <div id='kt_scrolltop' className='scrolltop' data-kt-scrolltop='true' onClick={scrollTop}>
      <ArrowUp size={20} style={{ color: "white" }} />
    </div>

    <div className='scrollBottom' onClick={scrollBottom} id='kt_scrollbottom'>
      <ArrowDown size={20} style={{ color: "white" }} />
    </div>
  </>

  )
}
