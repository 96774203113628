import Menu from '@mui/material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import { Divider, List, ListItem } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';


const EditRoleMenu = ({ anchorEl, handleClose, handleRoleUpdate, userRoleId, removeOneTeamUser, userRole, userId, isSuper }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.05)',
                },
            }}
        >
            <List>
                <ListItem
                    onClick={() => handleRoleUpdate('Owner')}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <div style={{ width: '24px' /* Adjust width as needed */, marginRight: '8px' }}>
                        {userRoleId === 'Owner' && <CheckIcon style={{ color: 'green' }} />}
                    </div>
                    Owner
                </ListItem>
                <Divider />
                <ListItem
                    onClick={() => handleRoleUpdate('Viewer')}
                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                >
                    <div style={{ width: '24px', marginRight: '8px' }}>
                        {userRoleId === 'Viewer' && <CheckIcon style={{ color: 'green' }} />}
                    </div>
                    Viewer
                </ListItem>
                <Divider />
                <ListItem
                    onClick={userRole === 'Viewer' && !isSuper ? () => { } : () => removeOneTeamUser(userId)}
                    style={{ cursor: userRole === 'Viewer' && !isSuper ? 'not-allowed' : 'pointer', color: 'red', display: 'flex', alignItems: 'center' }}
                    disabled={userRole === "Viewer" && !isSuper}
                >
                    <div style={{ width: '24px', marginRight: '8px' }}>
                        <PersonRemoveIcon style={{ color: 'red' }} />
                    </div>
                    Remove from team
                </ListItem>
            </List>
        </Menu>
    );
};

export default EditRoleMenu;