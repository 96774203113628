/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, SetStateAction, useEffect } from 'react'
import { Card2 } from '../../../../_metronic/partials/content/cards/Card2'
import { AgentCardResponse, AgentCardResponse2, AgentCategoryResponse, RatingsResponse, ReviewResponse, decryptData, fetchLoginCookie, getAgentByCategory, getAgentByProject, getAllRatings, getUserRole } from '../../../../api';
import { useState } from 'react'
import bot from '../../../../media_dsd/bot.png'
import { CreateAppModal } from '../../../../_metronic/partials'
import { KTSVG } from '../../../../_metronic/helpers';
import CircularProgress from '@mui/material/CircularProgress';
import BotDark from '../../../../media_dsd/bot_white.png'
import styles from '../ProfileStyle.module.css'
import { Link, useParams } from 'react-router-dom';
type Props = {
  setAgentUpdateAfterDelete: Dispatch<SetStateAction<boolean>>;
  agentUpdateAfterDelete: boolean;
  setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;

}
export function Templates({setRefreshChatPageOnAgentCreation, agentUpdateAfterDelete, setAgentUpdateAfterDelete }: Props) {

  const { agentId } = useParams()
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [ispromptDataLoading, setispromptDataLoading] = useState(true);
  const [agentActiveConfig, setAgentActiveConfig] = useState<number>();
  const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const [agentsByCategoryData, setAgentsByCategoryData] = useState<AgentCategoryResponse[]>([]);
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const width = window.innerWidth;
  const [userRole, setUserRole] = useState('');

  const handleClose = () => {
    setShowCreateAppModal(false)
  }
  useEffect(() => {
    // Function to check and update the screen width
    function handleScreenWidth() {
      setIsScreenSmall(window.innerWidth <= 405);
    }

    // Add an event listener to listen for changes in screen width
    window.addEventListener('resize', handleScreenWidth);

    // Initial check when the component mounts
    handleScreenWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleScreenWidth);
    };
  }, []);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const activeProject = localStorage.getItem("Active-Project");

    if (userId) {
      setLoggedInUserId(userId);
      getAgentsByCategory();
      getAllAgentRatings()
    }
    if (activeProject) {
      const parts = activeProject.split('-');
      const projectId = parseInt(parts[0]);

      if (!isNaN(projectId)) {
        fetchUserRole(projectId)
        getAgentsOfProject(projectId);
        fetchUserActiveAgentConfig(userId);
      }
    }
  }, [localStorage.getItem("Active-Project")]);

  const getAgentsOfProject = async (project_id: number) => {
    try {
      const response = await getAgentByProject(project_id);
      setispromptDataLoading(false)
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserActiveAgentConfig = async (id: any) => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        setAgentActiveConfig(parsedAgentID);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllAgentRatings = async () => {
    try {
      const response = await getAllRatings();
      setRatingsData(response);
    } catch (error) {
      console.error(error);

    }
  };
  const getAgentsByCategory = async () => {
    try {
      const response = await getAgentByCategory();
      setAgentsByCategoryData(response);
    } catch (error) {
      console.error(error);

    }
  };
  const agentResponses = agentsByCategoryData.map((item) => item.agent);
  const fetchUserRole = async (projectID: any) => {
    const result = await getUserRole(projectID);
    if (result) {
      setUserRole(result.role)
    }
  }
  return (
    <div >
      <div className='d-flex flex-wrap flex-stack mb-6' >
        <h3 className={` my-2 ${styles.title}`}>
          Featured Agents

        </h3>

        <div className='d-flex align-items-center my-2'>

          <div className='d-flex align-items-center position-relative my-1'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search agent'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginRight: '0.8rem', background: 'white', fontWeight: 300 }}
            />
          </div>

          <div>
            <Link to="/configureAgent">
              <button className={` btn btn-md ${styles.customButton}`}>{
                isScreenSmall ? '+' :
                  'Add Agent '
              }</button>
            </Link>
          </div>

          <CreateAppModal setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} getAgentsOfProject={getAgentsOfProject} naming='Agent' show={showCreateAppModal} handleClose={handleClose} currentUserId={loggedInUserId!} />
        </div>
      </div>
      <div className='row g-6 g-xl-9'>
        {!ispromptDataLoading && loggedInUserId ? (
          <>
            {agentResponses && agentResponses.length > 0 ? (agentResponses
              .filter((item) => {
                // Filter agents based on search query
                return item.agent_name.toLowerCase().includes(searchTerm.toLowerCase());
              })
              .map((item: AgentCardResponse2, index: number) => {
                return (
                  <div className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`}>

                    {/* <div className='col-sm-6 col-lg-3 mt-6' style={{ maxWidth: "20rem", minWidth: '20rem', margin: width > 670 ? '0' : 'auto' }}> */}
                    <Card2
                      setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                      agentUpdateAfterDelete={agentUpdateAfterDelete}
                      setAgentUpdateAfterDelete={setAgentUpdateAfterDelete}
                      key={index}
                      data={item}
                      currentUserId={loggedInUserId!}
                      viewMode='default'
                      allRatings={allRatings}
                      setRatingsData={setRatingsData}
                      userRole={userRole}
                    /></div>
                );
              }
              )
            ) :
              (<p className={styles.muted}>No Agents Found</p>)}
          </>) : (
          <div className={styles.CircularProgress}>
            <CircularProgress /></div>
        )}

      </div>

      <br></br>
      <br></br>

    </div>
  )
}
