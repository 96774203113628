/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useRef, useEffect, ChangeEvent, SetStateAction, Dispatch } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { StepperComponent } from '../../../assets/ts/components'
import { KTSVG } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import styles from '../../../../app/modules/profile/ProfileStyle.module.css'
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2";
import { decryptData, fetchLoginCookie, getAllJobFunctions, JobFunction, AgentCardResponse, getOneAgent, AgentCategoryResponse, getAgentByCategory, getOneAgentForMagicLink } from "../../../../api";
// import Cookies from "universal-cookie";
import { SelectChangeEvent } from '@mui/material'
import { PreconfigAgents } from './steps/PreconfigAgents'
import { useLocation } from 'react-router-dom'

type Props = {
  show: boolean
  handleClose: () => void
  naming: string
  Agentdata?: AgentCardResponse;
  onAgentSuccess?: () => void;
  AgentsByCategory?: AgentCategoryResponse;
  currentUserId: number;
  getAgentsOfProject: (project_id: number) => Promise<void>
  setAgentUpdateAfterDelete:Dispatch<SetStateAction<boolean>>;
  agentUpdateAfterDelete:boolean
  setRefreshChatPageOnAgentCreation:Dispatch<SetStateAction<boolean>>;
}

const MAX_TEXT_LENGTH = 150;
const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({setRefreshChatPageOnAgentCreation, setAgentUpdateAfterDelete,agentUpdateAfterDelete, getAgentsOfProject, Agentdata, show, handleClose, naming, onAgentSuccess, currentUserId, AgentsByCategory }: Props) => {

  const [orgName, setOrgName] = useState("");
  const [industryArea, setIndustryArea] = useState<string[]>([]);
  const [domain, setDomain] = useState("");
  const [instruction, setInstruction] = useState(""); // State for the current input
  const [instructions, setInstructions] = useState<string[]>([]); // State for list of instructions
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sampleQuestion1, setSampleQuestion1] = useState("sampleQuestion1");
  const [sampleQuestion2, setSampleQuestion2] = useState("sampleQuestion2");
  const [sampleQuestion3, setSampleQuestion3] = useState("sampleQuestion3");
  const [agentName, setAgentName] = useState("");
  const [customIndustryArea, setCustomIndustryArea] = useState<string[]>([]);
  const [customInstructions, setCustomInstructions] = useState<string[]>([]); // State for list of instructions
  const [clonedFrom, setClonedFrom] = useState<number | null>(null);
  const [industryOptions, setIndustryOptions] = useState<string[]>([]); // Add type annotation for industryOptions
  const [ioLoading, setIoLoading] = useState<boolean>(true); // Add type annotation for isLoading
  const [allJobFunctions, setAllJobFunctions] = useState<JobFunction[]>([]); // State for list of all instructions present in DB
  const [showTemplates, setShowTemplates] = useState(false);
  const [projectId, setProjectId] = useState<number>(-1)
  const [agentData, setAgentData] = useState<AgentCardResponse[]>([]);
  const [reload, setReload] = useState(false)
  const [hasNameError, setHasNameError] = useState(false)
  const [isNameExists, setIsNameExists] = useState<boolean>(false)
  const [emailAlias, setEmailAlias] = useState<string>("");


  const width = window.innerWidth;
  //metronic
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [hasError, setHasError] = useState(false)
  const [firstStepIndex, setFirstStepIndex] = useState<number>(1);
  const [agentsByCategoryData, setAgentsByCategoryData] = useState<AgentCategoryResponse[]>([]);
  const [creatingAgent, setCreatingAgent] = useState(false)
  const [needAlias, setNeedAlias] = useState<boolean>(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));

  useEffect(() => {

    const activeProject = localStorage.getItem("Active-Project")
    const parts = activeProject?.split('-');
    if (parts) {
      const projectId = parseInt(parts[0]);
      setProjectId(projectId)
    }
  })
  const showAllTemplates = () => {
    setShowTemplates(!showTemplates);

  };

  const handleAgentCloneClick = async (id: number) => {
    try {
      let response;
      if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
         response = await getOneAgentForMagicLink(id);
      }else{
         response = await getOneAgent(id);
      }
      if(response){
        setAgentName(response.agent_name);
        const industry_areas = response.industry_area.split(' , ')
        setIndustryArea(industry_areas);
        setCustomIndustryArea([])
        setDomain(response.domain);
        setInstructions(response.instructions.map((item) => item.instruction));
        setClonedFrom(response.id);
        setShowTemplates(!showTemplates);
        setSampleQuestion1(response.sample_q1);
        setSampleQuestion2(response.sample_q2);
        setSampleQuestion3(response.sample_q3);
        setEmailAlias(response.alias)
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  const handleDeleteInstruction = (instructionToDelete: string) => {
    // Filter the instructions array to remove the instruction with the provided text
    const updatedInstructions = instructions.filter(
      (instruction) => instruction !== instructionToDelete
    );
    setInstructions(updatedInstructions);
  }
  const handleAddInstruction = () => {
    if (instruction.trim() !== "") {
      setInstructions([...instructions, instruction]);
      setInstruction(""); // Reset the input after adding the chip
    }
  }
  // const createAgentConfig = async () => {
  //   setCreatingAgent(true)
  //   setIsLoading(true);
  //   const userInfo = fetchLoginCookie();
  //   const decryptedRes = decryptData(userInfo);
  //   const userId = parseInt(decryptedRes?.id)
  //   const userEmail = decryptedRes?.user;

  //   let selectedIndustryArea = industryArea.join(' , '); // Default to industryArea value
  //   if (industryArea.includes('Other') && customIndustryArea.length > 0) {
  //     selectedIndustryArea = customIndustryArea.join(' , '); // Join array elements with "and"
  //   }
  //   try {

  //     const request: AgentCardRequest = {
  //       agent_name: agentName,
  //       user_id: userId,
  //       project_id: projectId,
  //       industry_area: selectedIndustryArea,
  //       domain: domain,
  //       sample_q1: sampleQuestion1,
  //       sample_q2: sampleQuestion2,
  //       sample_q3: sampleQuestion3,
  //       instructions: instructions,
  //       cloned_from: clonedFrom,
  //       created_by: userEmail,
  //       need_alias:needAlias
  //     };
  //     const result = await createAgent(request);
  //     setSelectedAgent(result.id)
  //     setIsLoading(false);
  //     if (result && result.id) {
  //       localStorage.setItem("Active-Prompt-Id", result.id.toString());
  //       getAgentsOfProject(projectId);
  //       setAgentUpdateAfterDelete(!agentUpdateAfterDelete)
  //       Swal.fire({
  //         icon: 'success',
  //         title: 'Success',
  //         text: 'Agent created successfully! Now, index data for your agent.',
  //         confirmButtonText: 'Ok'
  //       }).then((result) => {
  //         if (result.isConfirmed) {

  //           setAgentName("");
  //           setIndustryArea([]);
  //           setCustomIndustryArea([])
  //           setDomain("");
  //           setSampleQuestion1("");
  //           setSampleQuestion2("");
  //           setSampleQuestion3("");
  //           setInstruction("");
  //           setInstructions([]);
  //           setClonedFrom(null);
  //           setCreatingAgent(false)

  //           if (!stepper.current) {
  //             return
  //           }
  //           stepper.current.goNext()
  //           // onAgentSuccess();

  //         }
  //       })
  //     } else {
  //       setHasError(true)
  //       setCreatingAgent(false)
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Oops...',
  //         text: 'Failed to Create Agent'
  //       });
  //       return
  //     };
  //   } catch (error) {

  //     setIsLoading(false);
  //     setCreatingAgent(false)
  //     setHasError(true)
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'Failed to Create Agent'
  //     });
  //     return
  //   }

  // };

  const modalclose = () => {
    // const activeProject = localStorage.getItem("Active-Project");
    // if (activeProject) {
    //   const parts = activeProject.split('-');
    //   const projectId = parseInt(parts[0]);

    //   // if (!isNaN(projectId)) {

    //   //   // if (reload) {
    //   //   //   getAgentsOfProject(projectId);
    //   //   // }
    //   // }
    // }
    setFirstStepIndex(1)
    setHasError(false)
    // setReload(false)
    handleClose()
  }

  //toast

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const checkJob = () => {

    if (!industryArea.length) {

      return false
    }

    if (industryArea.includes('Other')) {

      if (!customIndustryArea.length || customIndustryArea[0] === '') {
        return false
      }

    }

    return true


  }
  const checkAppBasic = (): boolean => {
    const job = checkJob()

    if (!agentName || !domain || !job) {
      return false
    }
    return true
  }
  // const samples = (): boolean => {
  //   if (!sampleQuestion1 || !sampleQuestion2 || !sampleQuestion3) {
  //     return false
  //   }
  //   return true
  // }
  const inst = (): boolean => {
    if (!(instructions.length > 0)) {
      return false
    }
    return true
  }
  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
    setFirstStepIndex(1)
  }


  const nextStep1 = async () => {
    if (!stepper.current) {
      return
    }
    if (!checkAppBasic()) {
      setHasError(true)
      return
    }
    if (hasNameError) {
      return
    }
    stepper.current.goNext()
    setFirstStepIndex(stepper.current.getCurrentStepIndex())
  }
  const nextStep2 = async () => {
    if (!stepper.current) {
      return
    }
    if (!inst()) {
      setHasError(true)
      return
    }
    await submit()
    if (hasError) {
      return
    }
  }
  const nextStep3 = async () => {
    if (!stepper.current) {
      return
    }
    // setReload(true)
    stepper.current.goNext()
  }
  const handleModalClose = ()=>{
    handleClose()
    setFirstStepIndex(1)
  }
  const submit = async () => {

    if (
      !agentName ||
      (!industryArea && !customIndustryArea) || // Check if either field is present
      !domain ||
      instructions.length == 0
    ) {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please fill out all the fields before submitting.',
      });
      return;
    } else {


      // await createAgentConfig()

    }
  };
  const handleIndustryArea = async (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    setIndustryArea(selectedValues);
    // Clear custom industry area input when selecting a predefined option
    if (!selectedValues.includes('Other')) {
      setCustomIndustryArea([]);
      try {
        const selectedInstructions: string[] = [];
        selectedValues.forEach((selectedValue) => {
          const jobFunction = allJobFunctions.find((jobFn) => jobFn.job_function === selectedValue);
          if (jobFunction) {
            selectedInstructions.push(...jobFunction.instructions);
          }
        });
        if (selectedInstructions.length > 0) {
          if (customInstructions.length === 0) {
            setInstructions(selectedInstructions);
          } else {
            const mergedInstructions = [...customInstructions, ...selectedInstructions];
            setInstructions(mergedInstructions);
          }
        } else {

          setInstructions(customInstructions);
        }
      } catch (error) {
        // console.error("Error fetching sample Instructions:", error);
      }
    } else {
      setIndustryArea(['Other']);
      setInstructions(customInstructions);
    }
  };
  const isOtherOptionSelected = industryArea.includes('Other'); // Check if 'Other' is included in the array
  // Custom validation function for the "Other" option
  const isCustomIndustryAreaValid = isOtherOptionSelected && customIndustryArea.some(value => value.trim() !== '');

  const handleCustomIndustryArea = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomIndustryArea([event.target.value]); // Wrap value in an array

  };

  //for indexing
  const [selectedAgent, setSelectedAgent] = useState<number>(-1);
  const [selectedOption, setSelectedOption] = useState<string>("file");

  // const [result, setResult] = useState<string[]>([]);
  const [promptData, setPromptData] = useState<AgentCardResponse[]>([]);


  const [uploadError, setUploadError] = useState<string>("");

  const [success, setSuccess] = useState<boolean>(true);

  const [loggedInUserId, setLoggedInUserId] = useState<number>();

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    if (userId) {
      setLoggedInUserId(userId);
      // getAllAgentConfigs();
      getAgentsByCategory();
    }
  }, [selectedOption]);

  // const getAllAgentConfigs = async () => {
  //   try {
  //     const response = await getAllAgents();
  //     setAgentData(response);
  //   } catch (error) {
  //     console.error(error);

  //   }
  // };

  const getAgentsByCategory = async () => {
    try {
      const response = await getAgentByCategory();
      setAgentsByCategoryData(response);
    } catch (error) {
      console.error(error);

    }
  };

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered '
      show={show}
      onHide={handleModalClose}
      onEntered={loadStepper}
      backdrop={true}
      size='xl'
     

    >
      <div className='modal-header'>
        <h2 style={{ fontSize: '24px', fontWeight: '400', lineHeight: '36px', }}>Create Agent</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={modalclose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {(stepper.current === null || stepper.current.getCurrentStepIndex() === 1) && (
          <button className={`btn btn-lg ${styles.customButton}`} onClick={showAllTemplates}>
            Use Templates
          </button>
        )}
      </div>
      {showTemplates && (
        <div className='modal-body py-lg-10 px-lg-10'>
          <PreconfigAgents setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} onCloneClick={handleAgentCloneClick} currentUserId={currentUserId!}
            onCloseClick={showAllTemplates} agentsByCategory={agentsByCategoryData} />
        </div>
      )}
      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px mb-10' style={{ marginBottom: width > 670 ? '0' : '2rem' }}>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title' style={{ fontWeight: '400', lineHeight: '24px', }}>Details</h3>

                    <div className='stepper-desc'>Name your {naming}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              {/* <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
              {/* <div className='stepper-wrapper'> */}
              {/* begin::Icon*/}
              {/* <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div> */}
              {/* begin::Icon*/}

              {/* begin::Label*/}
              {/* <div className='stepper-label'>
                    <h3 className='stepper-title'>Sample Prompts</h3>

                    <div className='stepper-desc'>Write sample prompts for agent</div>
                  </div> */}
              {/* begin::Label*/}
              {/* </div> */}
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              {/* <div className='stepper-line h-20px'></div> */}
              {/* end::Line*/}
              {/* </div> */}
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title' style={{ fontWeight: '400', lineHeight: '24px', }}>Instructions</h3>

                    <div className='stepper-desc'>Add instructions for agent</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title' style={{ fontWeight: '400', lineHeight: '24px', }}>Index</h3>

                    <div className='stepper-desc'>Index your data</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title' style={{ fontWeight: '400', lineHeight: '24px', }}>Completed</h3>

                    <div className='stepper-desc'>Review and Submit</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <Step1 isNameExists={isNameExists} setIsNameExists={setIsNameExists} setHasNameError={setHasNameError} customIndustryArea={customIndustryArea} isCustomIndustryAreaValid={isCustomIndustryAreaValid} handleCustomIndustryArea={handleCustomIndustryArea} industryOptions={industryOptions} ioLoading={ioLoading} domain={domain} setDomain={setDomain} setJob={handleIndustryArea} Job={industryArea} valAgent={agentName} setAgent={setAgentName} setOrg={setOrgName} Org={orgName} naming={naming} hasError={hasError} needAlias={needAlias} setNeedAlias={setNeedAlias} />
              {/* <Step2 setSampleQuestion3={setSampleQuestion3} setSampleQuestion2={setSampleQuestion2} setSampleQuestion1={setSampleQuestion1} sampleQuestion1={sampleQuestion1} sampleQuestion2={sampleQuestion2} sampleQuestion3={sampleQuestion3} hasError={hasError} /> */}
              <Step3 creatingAgent={creatingAgent} isLoading={isLoading} nextStep={nextStep2} prevStep={prevStep} handleDeleteInstruction={handleDeleteInstruction} instructions={instructions} instruction={instruction} setInstruction={setInstruction} handleAddInstruction={handleAddInstruction} hasError={hasError} />
              <Step4 emailAlias={emailAlias} flag={false} uploadError={uploadError} nextStep={nextStep3} loggedInUserId={loggedInUserId} setSuccess={setSuccess} setUploadError={setUploadError} selectedAgent={selectedAgent} success={success} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
              <Step5 loggedInUserId={loggedInUserId} selectedAgent={selectedAgent} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>

                <div className='me-2'>
                  {(stepper.current && stepper.current.getCurrentStepIndex() === 3 && stepper.current.getCurrentStepIndex() === 4) ? ( //done
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={prevStep}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1'
                      />{' '}
                      Previous
                    </button>
                  ) : null}


                </div>
                <div>
                  {/* {(stepper.current && stepper.current.getCurrentStepIndex() === 4) ? ( //done
                    <button
                      className='btn btn-lg btn-primary'
                      onClick={() => handleAgentActivateClick(selectedAgent)}
                    >
                      Submit{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'

                      />
                    </button>

                  ) : null} */}




                  {(stepper.current && firstStepIndex === 1) ? <>
                    <button //done
                      type='button'
                      className='btn btn-lg '
                      style={{
                        cursor: 'pointer',
                        color: 'white',
                        backgroundColor: '#20808d'
                      }}
                      data-kt-stepper-action='next'
                      onClick={nextStep1}
                    >
                      Next Step


                    </button>
                  </> : <></>}

                  {/* {
                    (stepper.current && stepper.current.getCurrentStepIndex() === 3 //done
                    ) && (!success) ? <>
                      <button
                        type='button'
                        className='btn btn-lg btn-light-danger me-3'
                        data-kt-stepper-action='previous'
                        onClick={cancelIndex}
                      >
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                        Cancel
                      </button>

                    </> : <></>
                  } */}

                  {/* {stepper.current?.getCurrentStepIndex() === 3 && (
                    success ? (
                      <button
                        type='button'
                        className='btn btn-lg btn-light-primary me-3'
                        data-kt-stepper-action='previous'
                        onClick={nextStep}
                      >
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr064.svg' />
                        Next
                      </button>
                    ) : null
                  )} */}

                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>

    </Modal>,
    modalsRoot
  )
}

export { CreateAppModal }