import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTable, ColumnInstance, Row } from 'react-table';
import { CustomHeaderColumn } from '../table/columns/CustomHeaderColumn';
import { CustomRow } from '../table/columns/CustomRow';
// import { usersColumns } from './columns/_columns';
import { KTCardBody } from '../../../../../../_metronic/helpers';
import { CircularProgress } from '@mui/material';
import "./UsersTable.css"

import { Column } from 'react-table';
import { UserInfoCell } from '../table/columns/UserInfoCell';
import { UserActionsCell } from '../table/columns/UserActionsCell';
import { UserSelectionCell } from '../table/columns/UserSelectionCell';
import { UserCustomHeader } from '../table/columns/UserCustomHeader';
import { UserSelectionHeader } from '../table/columns/UserSelectionHeader';
import { User } from '../core/_models';


interface UserTableProps {
  setFilteredUsers: Dispatch<SetStateAction<User[]>>;
  setAllUsers: Dispatch<SetStateAction<User[]>>;
  filteredUsers: any[];
  isLoading:boolean;
}
const UsersTable = ({filteredUsers, isLoading, setFilteredUsers, setAllUsers}:UserTableProps) => {

  const usersColumns: ReadonlyArray<Column<User>> = [
    {
      Header: (props) => <UserSelectionHeader tableProps={props} />,
      id: 'selection',
      Cell: ({ ...props }) => <UserSelectionCell id={props.row.original.id} user={props.row.original}/>, // Use your custom data
    },
    {
      Header: (props) => <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
      accessor: 'name', // Match the key in your custom data
      Cell: ({ ...props }) => <UserInfoCell user={props.row.original} />, // Use your custom data
    },
    {
      Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
      accessor: 'role', // Match the key in your custom data
    },
    {
      Header: (props) => <UserCustomHeader tableProps={props} title='Joined day' className='min-w-125px' />,
      accessor: 'date_joined', // Match the key in your custom data
    },
    {
      Header: (props) => <UserCustomHeader tableProps={props} title='Actions' className='min-w-100px' />,
      id: 'actions',
      Cell: ({ ...props }) => <UserActionsCell id={props.row.original.id } user={props.row.original} setFilteredUsers={setFilteredUsers} setAllUsers={setAllUsers}/>, // replace with user id
    },
  ];

  const [Data, setData] = useState<User[]>([])

  useEffect(()=>{

    setData(filteredUsers)

  }, [filteredUsers])

  const data = useMemo(() => Data, [Data]);
  const columns = useMemo(() => usersColumns, []);
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({ columns, data});

  return (
    <KTCardBody className='py-4'>
      {isLoading? <div className='loader'><CircularProgress /></div>: (<div className='table-responsive px-8'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600' style={{fontWeight:"400"}} {...getTableBodyProps()}>

            {rows.length > 0 ? (
              rows.map((row: Row, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} setFilteredUsers={setFilteredUsers}/>;
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>)}
    </KTCardBody>
  );
};

export { UsersTable };
