import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import {User} from '../../core/_models'

type Props = {
  tableProps: PropsWithChildren<HeaderProps<User>>
}

const UserSelectionHeader: FC<Props> = ({tableProps}) => {

  return (
    <th {...tableProps.column.getHeaderProps()} className='w-10px pe-2'>
      <></>
    </th>
  )
}

export {UserSelectionHeader}
