/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchLoginCookie, decryptData, getAgentAliasFlag } from '../../../api'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
type Props = {
  onProjectIDArrival: (id: number) => void;
}


const ConfigureHeader: React.FC<Props> = ({ onProjectIDArrival }) => {
  const [showAdminButton, setShowAdminButton] = useState(false);
  const [agentAliasFlag, setAgentAliasFlag] = useState(false);
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    fetchAgentAliasFlag()
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";

    if (isSuperUser) {
      setShowAdminButton(true);
    }
  }, [])

  const fetchAgentAliasFlag = async () => {
    const response = await getAgentAliasFlag()
    setAgentAliasFlag(response)
  }
  const [value, setValue] = React.useState('analytics');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === 'analytics') {
      navigate('/admin/analytics')
    } else if (newValue === 'user-settings') {
      navigate('/admin/user-settings')
    } else if (newValue === 'chatlogs') {
      navigate('/admin/chatlogs')
    } else {
      navigate('/admin/agentalias')
    }

  };

  return (
    <div className='card mb-5 mb-xl-10' >

      <div className='card-body p-0 pb-0'>
        <div className='d-flex overflow-auto h-48px' style={{ alignItems: "center", justifyContent: "center" }}>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>

            <Tabs value={location.pathname.split('/')[2]} onChange={handleChange}>
              <Tab style={{ textTransform: 'capitalize', fontSize: '13px' }} value='analytics' label="Analytics" />
              <Tab style={{ textTransform: 'capitalize', fontSize: '13px' }} value='user-settings' label="Users Settings" />
              {showAdminButton &&
               
                <Tab style={{ textTransform: 'capitalize', fontSize: '13px' }} value='chatlogs' label="Chatlogs" />
              }
              {
                 agentAliasFlag && <Tab style={{ textTransform: 'capitalize', fontSize: '13px' }} value='agentalias' label="Alias" />}

            </Tabs>
          </ul>
        </div>
      </div>
    </div>
  )
}

export { ConfigureHeader }
