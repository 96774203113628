// import { Pivot, PivotItem } from "@fluentui/react";
// import styles from "./AnalysisPanel.module.css";
import { SupportingContent } from "./SupportingContent";
import { AskResponse } from "../../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { ThoughtProcessContent } from "./ThoughtProcessContent"; // Import the new component for thought process rendering

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    citationHeight: string;
    answer: AskResponse;
}


export const AnalysisPanel = ({answer, activeTab, activeCitation }: Props) => {
  
    let headerText = "Content"; // Default header text

    if (activeTab === AnalysisPanelTabs.ThoughtProcessTab) {
        headerText = "Thought process";
    } else if (activeTab === AnalysisPanelTabs.SupportingContentTab) {
        headerText = "Supporting content";
    } else if (activeTab === AnalysisPanelTabs.CitationTab) {
        headerText = "Citation";
    }
    const downloadPdf = () => {
        // Create a hidden anchor element
        const anchor = document.createElement("a");
        anchor.href = activeCitation!;
        anchor.download = "file.pdf"; // Set the desired file name for the downloaded PDF
        document.body.appendChild(anchor);

        // Trigger a click event on the anchor to start the download
        anchor.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(anchor);
    };

    return (
        <div>

                {activeTab === AnalysisPanelTabs.ThoughtProcessTab && (
                    <ThoughtProcessContent thoughtProcess={answer.thoughts} />
                )}
                {activeTab === AnalysisPanelTabs.SupportingContentTab && (
                    <SupportingContent supportingContent={answer.data_points} />
                )}
                {activeTab === AnalysisPanelTabs.CitationTab && (
                    //   <iframe title="Citation" src={activeCitation} width="100%" height={citationHeight} />
                    <a href={activeCitation} download="file.pdf" onClick={downloadPdf}>
                        Download PDF
                    </a>
                )}
            {/* </PivotItem>

        </Pivot> */}
    </div>
    );
};
