import ReactDOMServer, { renderToStaticMarkup } from "react-dom/server";
import { getCitationFilePath } from "../../../api";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

// import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
    followupQuestions: string[];
    indexes_fin: string[];

};

export function parseAnswerToHtml(reference: { order: number; url: string; number: number; }[], answer: string, indexes: string[], sources: string, followup_questions: string[], onCitationClicked: (citationFilePath: string) => void): HtmlParsedAnswer {
    const citations: string[] = [];
    const followupQuestions: string[] = [...followup_questions];
    let indexes_fin: string[] = [];
    const checkedItemsIndex: string[] = [];



    // Extract any follow-up questions that might be in the answer
    // let parsedAnswer = answer.replace(/<<([^>>]+)>>/g, (match, content) => {
    //     followupQuestions.push(content);
    //     return "";
    // });

    // trim any whitespace from the end of the answer after removing follow-up questions
    let parsedAnswer = answer.trim()

    // parsedAnswer = parsedAnswer.replace(/```([^`]+)```/g, (match, content) => {
    //     let languageMatch = match.match(/```([^\s]+)/);
    //     if (languageMatch) {
    //         let language = languageMatch[1];
    //         return renderToStaticMarkup(
    //             <SyntaxHighlighter language={language} style={solarizedlight}>
    //                 {content}
    //             </SyntaxHighlighter>
    //         );
    //     } else {
    //         return content
    //     }
    // });
    // console.log('PARSED ANSWER',parsedAnswer)


    // const fragments: (string | JSX.Element)[] = parts.map((part, index) => {
    //     if (index % 2 === 0) {
    //         return part
    //     } else {
    //         let citationIndex: number;
    //         if (citations.indexOf(part) !== -1) {
    //             citationIndex = citations.indexOf(part) + 1;
    //         } else {
    //             citations.push(part);
    //             citationIndex = citations.length;
    //         }

    //         const referenceItem = reference.find((x) => parseInt(part) == x.number);
    //         console.log(parseInt(part), '=== ', referenceItem?.number)
    //         if (referenceItem) {
    //             const path = getCitationFilePath(referenceItem.url);
    //             const anchorProps: JSX.IntrinsicElements['a'] = {
    //                 className: 'supContainer',
    //                 title: part,

    //             };

    //             if (referenceItem.url.includes('https://')) {
    //                 anchorProps.href = referenceItem.url;

    //                 anchorProps.target = "_blank"
    //             } else {
    //                 // anchorProps.href = '#';

    //             }

    //             return renderToStaticMarkup(
    //                 <a key={index}  {...anchorProps} onClick={()=>onClick(path)}>
    //                     <sup>{citationIndex}</sup>
    //                 </a>
    //             );
    //         }

    //         return renderToStaticMarkup( <a></a>);
    //     }
    // });


    // const fragments: (string | JSX.Element)[] = parts.map((part, index) => {
    //     if (index % 2 === 0) {
    //         return part;
    //     } else {
    //         let citationIndex: number;
    //         if (citations.indexOf(part) !== -1) {
    //             citationIndex = citations.indexOf(part) + 1;
    //         } else {
    //             citations.push(part);
    //             citationIndex = citations.length;
    //         }

    //         const referenceItem = reference.find((x) => parseInt(part) == x.number);

    //         if (referenceItem) {
    //             console.log(parseInt(part), '=== ', referenceItem.number)
    //             const path = getCitationFilePath(referenceItem.url);

    //             // Create a function to handle the click event

    //             if (referenceItem.url.includes('https://')) {
    //                 return (

    //                     <button type="button" key={index} className="supContainer" title={part}   onClick={()=>openLinkInNewTab(referenceItem.url)} >
    //                         <sup>{citationIndex}</sup>
    //                     </button>
    //                 )
    //             }
    //             else {
    //                 return (
    //                     <button type="button" className="supContainer" title={part} onClick={()=>handleClick(path)}><sup>{citationIndex}</sup></button>
    //                 )
    //             }
    //         }

    //         // If the reference item is not found, return an empty string
    //         return '';
    //     }
    // });


    if (indexes?.length) {
        const splitList_indexes = indexes.map(item => {
            const splitItem = item.split(':');
            if (splitItem.length === 3 && citations.includes(splitItem[0]) && !checkedItemsIndex.includes(splitItem[0])) {
                if (splitItem[1].includes('http')) {
                    indexes_fin.push('https:' + splitItem[2]);
                    checkedItemsIndex.push(splitItem[0]); // Add item to checkedItems
                }

            }
            else if (splitItem.length === 2 && citations.includes(splitItem[0]) && !checkedItemsIndex.includes(splitItem[0])) {
                indexes_fin.push(splitItem[1]);
                checkedItemsIndex.push(splitItem[0]); // Add item to checkedItems

            }

            return indexes_fin;
        });
    }


    const answerHtml = parsedAnswer;
    // const answerHtml = ReactDOMServer.renderToString(<>{fragments}</>);

    return {
        answerHtml,
        citations,
        followupQuestions,
        indexes_fin,

    };
}
