
import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import styles from "./Answer.module.scss";
import clsx from "clsx";
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import BoltIcon from '@mui/icons-material/Bolt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import searchingGif from '../../../media_dsd/search-icon-gif.gif';

export const AnswerLoading = () => {
    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const [width, setWidth] = React.useState(false);

    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        if (window.innerWidth > 670) {
            setWidth(true)
        }
        else {
            setWidth(false)
        }

        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    }, []);

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 50000000000); // Increase the interval to slow down the speed (e.g., 1000ms for 1 second)

        return () => {
            clearInterval(timer);
        };
    }, []);
    const animatedStyles = useSpring({

        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack verticalAlign="space-between" className={styles.m10}>

                <Stack.Item className={clsx(
                    " rounded",
                    "mw-lg-900px"
                )}>
                    <div className={styles.quickSearchParent}>
                        <img src={searchingGif} alt="Searching..." height='25' width='25' />
                        {/* <ManageSearchIcon className={styles.boltIcon} /> */}
                        <div className={styles.header}>Sources</div>
                    </div>
                    <div className={styles.loadingSourcesParent}>
                        <div className={styles.LoadingSources}></div>
                        <div className={styles.LoadingSources}></div>
                        {
                            width && <div className={styles.LoadingSources}></div>
                        }

                        {/* <div className={styles.LoadingSources}></div> */}
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <div className={styles.sumup}>

                        <OfflineBoltIcon className={styles.iconContainer} ></OfflineBoltIcon>

                        <h3 className={styles.header}>Summing Up</h3>
                    </div>
                </Stack.Item>
                <Stack.Item

                    className={clsx(
                        " rounded",
                        "bg-light-primary",
                        "text-dark",
                        "text-start",
                        "mb-4",
                        "mw-lg-700px"
                    )}
                >
                    <LinearProgress

                        value={progress}
                        valueBuffer={buffer}
                        className={styles.LinearProgress}
                        sx={{
                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#3b3b3b', // Color of the progress bar
                            },
                        }}
                    />
                    <LinearProgress

                        value={progress}
                        valueBuffer={buffer}
                        className={styles.LinearProgress}
                        sx={{
                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#3b3b3b', // Color of the progress bar
                            },
                        }}
                    />

                    <LinearProgress

                        value={progress}
                        valueBuffer={buffer}
                        className={`${styles.LinearProgress} ${styles.lastLinearProgress}`}
                        sx={{

                            '& .MuiLinearProgress-barColorPrimary': {
                                backgroundColor: '#3b3b3b', // Color of the progress bar
                            },
                        }}
                    />

                </Stack.Item>
            </Stack>


        </animated.div>
    );
};
