import React, { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react';
import { NewChatRequest, NewChatResponse, NewChatTurn, createAgentChat } from '../../../../api';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PersonIcon from '@mui/icons-material/Person';
import dsdicon from '../../../../media_dsd/torriLogo.png'
import styles from '../ProfileStyle.module.css'
import BuildIcon from '@mui/icons-material/Build';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';

export interface Message {
    role: string;
    content: string;
    new_image: boolean;
    agent_image: string|File;
}
interface MessageProp {
    message: Message
}

const ChatImage: React.FC<{ agent_image: string|File }> = ({ agent_image }) => {
    return (
        <div style={{ width: "100%" }}>
            <img className={styles.agentImg} src={agent_image.toString()} alt="Agent Image" width={192} height={192} />
        </div>
    );
}

export const ChatMessage = ({ message }: MessageProp) => {
    const isUserMessage = message.role === 'user';

    return (
        <div style={{ width: "100%", }}>

            {isUserMessage && message.content && (
                <>
                    <div style={{ display: "flex", justifyContent: "start" }}><h6><PersonIcon style={{ color: "#20808d", fontSize: "16px" }}></PersonIcon>You</h6></div>
                    <p style={{ textAlign: "justify", }}>{message.content}</p>
                </>
            )}
            {!isUserMessage && message.content && (
                <>

                    <div style={{ display: "flex", justifyContent: "start" }}><img style={{ marginRight: "5px" }} src={dsdicon} width={14} height={14}></img><h6>Ejento Builder</h6></div>
                    <p style={{ textAlign: "justify", }}>{message.content}</p>
                    {
                        message.new_image ? <ChatImage agent_image={message.agent_image} /> : null
                    }

                </>

            )}

        </div>

    );
};


const ChatInterface: React.FC<{ updateAgentName: (name: string) => void, updateAgentDesc: (desc: string) => void, updateAgentImageUrl: (imageUrl: string | File) => void, chatHistory: NewChatTurn[], setChatHistory: Dispatch<SetStateAction<NewChatTurn[]>>, messages: Message[], setMessages: Dispatch<SetStateAction<Message[]>>, agentName: string, setAgentName: Dispatch<SetStateAction<string>>, agentDesc: string, setAgentDesc: Dispatch<SetStateAction<string>>, userMessage: string, setUserMessage: Dispatch<SetStateAction<string>>, agentImageUrl: string|File, setAgentImageUrl: Dispatch<SetStateAction<string|File>>, showImg: boolean, setShowImg: Dispatch<SetStateAction<boolean>>, loading: boolean, setLoading: Dispatch<SetStateAction<boolean>>, promptValue: string, setPromptValue: Dispatch<SetStateAction<string>>, category: number | null, setCategory: Dispatch<SetStateAction<number | null>>, loggedInUserId: number , setLoggedInUserId: Dispatch<SetStateAction<number>>, loggedInUserEmail: string, setLoggedInUserEmail: Dispatch<SetStateAction<string>>, projectId: number, setProjectId: Dispatch<SetStateAction<number>>, domain: string, setDomain: Dispatch<SetStateAction<string>>, edit: boolean, disabled: boolean, setDisabled: Dispatch<SetStateAction<boolean>> }> = ({ updateAgentName, updateAgentImageUrl, chatHistory, setChatHistory, agentName, setAgentName, setAgentImageUrl, agentImageUrl, showImg, setShowImg, userMessage, setUserMessage, loading, setLoading, promptValue, setPromptValue, category, setCategory, loggedInUserId, setLoggedInUserId, loggedInUserEmail, setLoggedInUserEmail, projectId, setProjectId, domain, setDomain, updateAgentDesc, agentDesc, setAgentDesc, edit, disabled, setDisabled, messages, setMessages, }) => {

    const [error, setError] = useState<boolean>(false);
    const [previousUserMessage, setPreviousUserMessage] = useState('');
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const [regenResp, setRegenResp] = useState<boolean>(false);
    const [disabledSendBtn, setDisabledSendBtn] = useState<boolean>(false);

    const scrollToBottom = () => {
        chatContainerRef.current?.scrollTo(0, chatContainerRef.current.scrollHeight);
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        updateAgentName(agentName);
    }, [agentName]);

    useEffect(() => {
        updateAgentImageUrl(agentImageUrl);
    }, [agentImageUrl]);

    useEffect(() => {
        updateAgentDesc(agentDesc);
    }, [agentDesc]);

    useEffect(() => {
        if (category) {
            setDisabled(false)
        }
    }, [category]);

    useEffect(() => {
        if(loading){
            setError(false)
        }
    }, [loading])

    useEffect(() => {
        if (regenResp) {
            handleSendMessage();
            setRegenResp(false)
        }
    }, [regenResp]);

    const handleSendMessage = async () => {
        if (userMessage.trim() !== '') {
            setDisabledSendBtn(true)
            setPreviousUserMessage(userMessage);
            const newUserInput: NewChatTurn = { role: 'user', content: userMessage };
            const newMessage: Message = { role: 'user', content: userMessage, agent_image: '', new_image: false };
            setChatHistory([...chatHistory, newUserInput]);
            if(!regenResp){
                 setMessages([...messages, newMessage]);
            }
            setUserMessage('');
            try {
                const requestData: NewChatRequest = {
                    history: [...chatHistory, newUserInput],
                    created_by: loggedInUserEmail,
                    user_id: loggedInUserId!,
                    project_id: projectId,
                    agent_id: category,
                    flags: {edit_mode : edit}
                };
                setLoading(true);
                const result = await createAgentChat(requestData);
                const agentResponse: Message = { role: 'ai', content: result?.response, agent_image: result?.agent_state.agent_image, new_image: result?.new_image };
                setMessages(prevMessages => [...prevMessages, agentResponse]);

                if (result?.agent_state.prefix_prompt) {
                    setPromptValue(result.agent_state.prefix_prompt);
                }

                if (result?.agent_state.agent_image) {
                    setAgentName(result.agent_state.agent_name);
                }
                setChatHistory(result.history);
                setLoading(false);
                if (result.agent_state.description) {
                    setDomain(result.agent_state.description);
                }

                if (result?.agent_id) {
                    setCategory(result.agent_id);
                    setDisabled(false);
                }
                if (result?.new_image) {
                    setAgentImageUrl(result.agent_state.agent_image);
                }
                setDisabledSendBtn(false)
            } 
            catch (error) {
                console.error('Error occurred while calling createAgentChat:', error);
                setLoading(false);
                setError(true);
            }
        }
    };


    const handleRegenerateResponse = () => {
        setUserMessage(previousUserMessage);
        setError(false);
        setRegenResp(true)
    };

    const handleUserInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUserMessage(event.target.value);
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disabledSendBtn) {
            handleSendMessage();
        }
    }; 

    return (
        <div className={`${styles.mainChatContainer}`} >
            <div className="chat-container p-3 border-rounded" style={{ height: "55vh", flexGrow: "8", overflowY: "auto", overflowX: "hidden" }} ref={chatContainerRef}>
                <div style={{ display: "flex", justifyContent: "start" }}>
                    <img style={{ marginRight: "3px" }} src={dsdicon} width={14} height={14} />
                    <h6>Ejento Builder</h6>
                </div>
                <p style={{ textAlign: "justify", fontSize: "13px" }}>
                    {edit ? `Welcome back! Is there anything ${agentName} should be doing better? Anything you'd like to change?` : "Hello! I'm Ejento Builder and I'm here to help you build a custom agent. To get started, could you please tell me what kind of custom agent you'd like to create?"}
                </p>
                {messages.map((message, index) => (
                    <React.Fragment key={index}>
                        <ChatMessage message={message} />
                    </React.Fragment>
                ))}
                {loading && (
                    <div style={{ display: "flex" }}>
                        <div className={styles.loader}>
                            <BuildIcon className={styles.buildIcon} style={{ fontSize: "12px", color: "#20808d", marginLeft: "2px", textAlign: "center", marginBottom: "4px" }} />
                        </div>
                        <p style={{ marginLeft: "4px", paddingTop: "2px" }}>Updating Agent...</p>
                    </div>
                )}
                {error && (
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <p style={{ color: 'gray' }}>There was an error generating a reponse</p>
                        <button className={styles.regenerateBtn} onClick={handleRegenerateResponse}><LoopOutlinedIcon style={{ color: "white" }} /> Regenerate</button>
                    </div>
                )}
            </div>


            <div style={{ flexGrow: "2", paddingTop: "8%", }}>
                <div className="col-12" style={{ paddingRight: "13px" }}>
                    <div className="input-group " >
                        <input style={{ borderRadius: "4px", height: "40px" }}
                            type="text"
                            className="form-control"
                            placeholder="Message Ejento Builder"
                            value={userMessage}
                            onChange={handleUserInput}
                            onKeyPress={handleKeyPress}
                        />
                        <button style={{ border: "none", padding: "0px", borderRadius: "4px" }} disabled={disabledSendBtn} className="chatArrowIcon input-group-prepend" onClick={handleSendMessage}>
                            <div className="sendBtn input-group-text" style={{ cursor: "pointer" }}>
                                <ArrowUpwardIcon />
                            </div>
                        </button>

                    </div>
                </div>
            </div>
        </div>


    );
};

export { ChatInterface };