import { SidebarMenuItem } from './SidebarMenuItem'
import { useState, useEffect, SetStateAction, Dispatch, } from 'react';
import { getTeamsByUser, fetchLoginCookie, decryptData, getProjectsByTeam, ProjectResponse, TeamResponse, getAllTeams, UserTeamResponseByUser, UserTeamResponseByTeam, getUsersByTeam, createRecentTeam, getRecentTeam, RecentTeamResponse, createRecentProject, getRecentProject, RecentProjectResponse, getRecentAgents, StarredAgentResponse } from '../../../../../api';
import { useContext } from 'react';
import { UserContext } from '../Sidebar';
import { AddButtonProject } from './AddButtonProject';
import { EditButtonTeam } from './EditButtonTeam';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import styles from './sidemenuStyles.module.scss'
import { Autocomplete, IconButton, TextField, Tooltip } from '@mui/material';
import { Modal } from "react-bootstrap";
import { AddButtonTeam } from './AddButtonTeam';
import ChevronRight from "@mui/icons-material/ChevronRight";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Clock, Plus, Star, IntersectThree, FolderSimpleStar, Users, Shield, CaretUp, CaretDown, FloppyDisk, CaretDoubleRight, CaretRight, CaretLeft } from "@phosphor-icons/react";
import clsx from 'clsx';
import { KTSVG } from '../../../../helpers';
import { useLayout } from '../../../core';
interface props {
    projectChange: boolean
    setProjectChange: Dispatch<SetStateAction<boolean>>;
    toggle: boolean;
    setToggle: Dispatch<SetStateAction<boolean>>;
}

const SidebarMenuMain = ({ projectChange, setProjectChange, toggle, setToggle }: props) => {
    const navigate = useNavigate();
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [loggedInUserOrgId, setLoggedInUserOrgId] = useState<number>();
    const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>("");
    const [userProjectsData, setUserProjectsData] = useState<ProjectResponse[]>([]);
    const team = useContext(UserContext);
    const [activeTeam, setActiveTeam] = useState<number>(-1)
    const [renderedProjectItems, setRenderedProjectItems] = useState<React.ReactNode[]>([]);
    const [teamProjects, setTeamProjects] = useState<React.ReactNode[]>([]);
    const anchorRef = useRef<HTMLAnchorElement | null>(null);
    const [isClicked, setIsClicked] = useState<boolean>(false)
    const [showAdminButton, setShowAdminButton] = useState<boolean>(false)
    const [reloadProject, setreloadProject] = useState(false)
    const [isNavigate, setNavigate] = useState(false)
    const [projectsDropdown, setprojectsDropdown] = useState(true);
    const [teamsDropdown, setteamsDropdown] = useState(true);
    const [teams, setTeams] = useState<TeamResponse | null>(null);
    const [isStaff, setIsStaff] = useState(false);
    const [isSuper, setIsSuper] = useState(false);
    const colors = ['#f8df72', '#a69ff3', '#9ee7e3', '#cd95ea', '#a69ff3'];
    const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
    const [createProjectCalled, setCreateProjectCalled] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
    const [selectedTeamName, setSelectedTeamName] = useState('');
    const [projectPresent, setprojectPresent] = useState<boolean>(false)
    const [isOpen, setisopen] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState('');
    const [browseteams, setbrowseteams] = useState<boolean>(false)
    const [displayedAllTeams, setDisplayedAllTeams] = useState<TeamResponse[]>([]);
    const [displayedUserTeams, setDisplayedUserTeams] = useState<UserTeamResponseByUser[]>([]);
    const [filteredUserTeams, setFilteredUserTeams] = useState<UserTeamResponseByUser[]>([]);
    const [filteredAllTeams, setFilteredAllTeams] = useState<TeamResponse[]>([]);
    const [teamUsersData, setTeamUsersData] = useState<Record<number, any[]>>({});
    const [selectedTeam, setSelectedTeam] = useState<TeamResponse | null>(null);
    const [recentTeams, setRecentTeams] = useState<RecentTeamResponse[]>([]);
    const [recentProjects, setRecentProjects] = useState<RecentProjectResponse[]>([]);
    const [chevronClicked, setChevronClicked] = useState(false);
    const [hoveredProject, setHoveredProject] = useState<number | null>(null);
    const [hoveredBrowseTeam, setHoveredBrowseTeam] = useState<number | null>(null);
    const [adminHover, setAdminHover] = useState<boolean>(false)
    const [currentActiveProject, setCurrentActiveProject] = useState(0)
    const [recentAgent, setRecentAgent] = useState<StarredAgentResponse[]>([]);

    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const location = useLocation()

    const fetchTeamUsersData = async () => {
        try {
            let fetchedTeams
            if (isSuper) {
                fetchedTeams = await getAllTeams();
                setDisplayedAllTeams(fetchedTeams);
                setFilteredAllTeams(fetchedTeams);
                if (fetchedTeams.length > 0) {
                    const usersByTeamPromises = fetchedTeams.map(async (team) => {
                        const users = await getUsersByTeam(team.id);
                        setTeamUsersData(prevData => ({
                            ...prevData,
                            [team.id]: users
                        }));
                    });
                    await Promise.all(usersByTeamPromises);
                }

            }
            else {
                fetchedTeams = await getTeamsByUser(loggedInUserId);
                setDisplayedUserTeams(fetchedTeams);
                setFilteredUserTeams(fetchedTeams);
                if (fetchedTeams.length > 0) {
                    const usersByTeamPromises = fetchedTeams.map(async (myteam) => {
                        const users = await getUsersByTeam(myteam.team.id);
                        setTeamUsersData(prevData => ({
                            ...prevData,
                            [myteam.team.id]: users
                        }));
                    });
                    await Promise.all(usersByTeamPromises);
                }
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (displayedAllTeams.length > 0) {
            const filteredTeams = displayedAllTeams.filter(myteam =>
                myteam.team_name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredAllTeams(filteredTeams);
        }
        else {
            const filteredTeams = displayedUserTeams.filter(myteam =>
                myteam.team.team_name.toLowerCase().includes(query.toLowerCase())
            );
            setFilteredUserTeams(filteredTeams);
        }

    };



    useEffect(() => {
        setDisplayedAllTeams(displayedAllTeams);
        setDisplayedUserTeams(displayedUserTeams);

    }, [displayedAllTeams, displayedUserTeams]);


    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        const orgId = parseInt(decryptedRes?.org_id);
        const userEmail = decryptedRes?.user;
        const isStaffUser = decryptedRes?.is_staff?.toLowerCase() === "true";
        const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";

        if (isSuperUser) {
            setIsSuper(true);
        }
        if (isStaffUser) {
            setIsStaff(true);
        }
        if (isStaffUser || isSuperUser) {
            setShowAdminButton(true)
        }
        if (userId) {
            setLoggedInUserId(userId);
            setLoggedInUserOrgId(orgId);
            setLoggedInUserEmail(userEmail);
        }

        if (!isSuper) {
            checkIfTeamsExist()
        }
        else {
            fetchRecentTeams();
        }

    }, []);


    const handleTeamName = (name: string) => {
        const parts = name.trim()
        if (parts.length === 1) {
            return parts;
        }
        if (parts.length > 1) {
            return parts;

        }
        return ''
    }

    const checkIfTeamsExist = async () => {
        if (loggedInUserId) {
            const myTeams = await getTeamsByUser(loggedInUserId);
            if (myTeams.length === 0) {
                navigate("/templates/");
            }
            else {
                fetchRecentTeams();
            }
        }
    }


    useEffect(() => {
        if (!isSuper) {
            checkIfTeamsExist()
        }
        else {
            fetchRecentTeams();
        }

    }, [loggedInUserId]);

    useEffect(() => {
        fetchRecentProjects();
    }, [projectChange]);


    const fetchRecentTeams = async () => {
        const active_project = localStorage.getItem('Active-Project');
        try {
            if (loggedInUserId) {
                const recentAgentData = await getRecentAgents(loggedInUserId);
                setRecentAgent(recentAgentData);
                const recentTeamData = await getRecentTeam(loggedInUserId);
                setRecentTeams(recentTeamData);
    
                if (recentTeamData.length === 0 && (localStorage.getItem("originalUrl") === null) && !(window.location.href.includes('admin'))) {
                    navigate('/recentagents/');
                } else {
                    fetchRecentProjects();
                }
    
                if (!active_project && (localStorage.getItem("originalUrl") === null) && !(window.location.href.includes('admin'))) {
                    if (recentAgentData.length === 0) {
                        navigate('/templates/');
                    } else {
                        navigate('/recentagents/');
                    }
                }
                
                localStorage.removeItem("originalUrl");
            }
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };
    

    const fetchRecentProjects = async () => {
        try {
            if (loggedInUserId) {
                const recentProjectData = await getRecentProject(loggedInUserId);
                setRecentProjects(recentProjectData);
            }
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };


    const handleActiveTeam = async (team: TeamResponse) => {
        setTeams(team)
        setCreateProjectCalled(false);
        setChevronClicked(false)
        localStorage.setItem('Active-Team', team.id.toString())
        setprojectsDropdown(true);
        setSelectedIndex(0);
        try {
            const response = await createRecentTeam(loggedInUserId!, team.id);

            if (response.id) {
                const recentTeamData = await getRecentTeam(loggedInUserId!);
                setRecentTeams(recentTeamData);

            }
        } catch (error) {
            console.error('Error handling active team:', error);
        }
    }


    useEffect(() => {
        const currentUrl = window.location.href;
        const keyword = '&share=';
        if (currentUrl.includes(keyword)) {
            setNavigate(false)
        }
        else {
            setNavigate(true)
        }
    }, [])

    useEffect(() => {
        const active_team = localStorage.getItem('Active-Team')
        if (active_team) {
            const parts = active_team.split('-');
            const teamId = parseInt(parts[0]);
            setActiveTeam(teamId)
        }

        else if (createProjectCalled) {
            setCreateProjectCalled(false);
        }


    }, [userProjectsData, isClicked])

    const getProjectsOnModal = async (teamId: number) => {
        const userProjects = await getProjectsByTeam(teamId);
        setprojectPresent(false)

        if (userProjects && userProjects.length > 0) {
            setprojectPresent(true)
            let projectItems = userProjects.sort((a, b) => {
                const regex = /(\d+)/;
                const aMatch = regex.exec(a.project_name);
                const bMatch = regex.exec(b.project_name);
                const alphabeticalComparison = a.project_name.localeCompare(b.project_name);
                if (alphabeticalComparison !== 0) {
                    return alphabeticalComparison;
                }

                const aNumber = aMatch ? parseInt(aMatch[0]) : 0;
                const bNumber = bMatch ? parseInt(bMatch[0]) : 0;

                return aNumber - bNumber;
            }).map((userProject, index) => (
                <SidebarMenuItem
                    currentActiveProject={currentActiveProject}
                    key={userProject.id}
                    anchorRef={index === 0 ? anchorRef : null}
                    onclick={async () => {
                        setCurrentActiveProject(0)
                        // setCurrentActiveProject(userProject.project.id)
                        localStorage.setItem("Active-Project",
                            `${userProject.id}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`)
                        closemodal()

                        try {
                            const response = await createRecentProject(loggedInUserId!, userProject.id);
                            if (response.id) {
                                const recentProjectData = await getRecentProject(loggedInUserId!);
                                setRecentProjects(recentProjectData);
                                setCurrentActiveProject(recentProjectData[0].project.id)

                            }
                        } catch (error) {
                            console.error('Error handling active team:', error);
                        }
                        try {
                            const response = await createRecentTeam(loggedInUserId!, userProject.team);

                            if (response.id) {
                                const recentTeamData = await getRecentTeam(loggedInUserId!);
                                setRecentTeams(recentTeamData);
                            }
                        } catch (error) {
                            console.error('Error handling active team:', error);
                        }
                    }}
                    to="/project/agents/agents"
                    title={userProject.project_name}
                    hasBullet={false}
                    project_id={userProject.id}
                    isTextColorBlack={true}
                    truncate={false}
                    userId={loggedInUserId!} />));
            setTeamProjects(projectItems);
        }
    }

    const toggleProjectDropdown = () => {
        setprojectsDropdown(!projectsDropdown);
    };
    const toggleTeamsDropdown = () => {
        setteamsDropdown(!teamsDropdown);

    };
    const closemodal = () => {
        setIsTeamModalOpen(false);

    };
    const handleAdminMouseEnter = () => {
        setAdminHover(true)
    }
    const handleAdminMouseLeave = () => {
        setAdminHover(false)
    }
    const { config } = useLayout()
    const appSidebarDefaultMinimizeDesktopEnabled =
        config?.app?.sidebar?.default?.minimize?.desktop?.enabled
    const appSidebarDefaultCollapseDesktopEnabled =
        config?.app?.sidebar?.default?.collapse?.desktop?.enabled
    const toggleType = appSidebarDefaultCollapseDesktopEnabled
        ? 'collapse'
        : appSidebarDefaultMinimizeDesktopEnabled
            ? 'minimize'
            : ''
    const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
    const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default


    const [userTeamsData, setUserTeamsData] = useState<UserTeamResponseByUser[]>([]);
    const [superUserTeamsData, setSuperUserTeamsData] = useState<TeamResponse[]>([]);
    const [projects, setProjects] = useState<ProjectResponse[]>([]);
    const [selectedModalTeam, setSelectedModalTeam] = useState<TeamResponse | null>(null);
    const [selectedProject, setSelectedProject] = useState<ProjectResponse | null>(null);
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [projectInputValue, setProjectInputValue] = useState<string>('');


    const handleTeamSelect = async (event: any, team: TeamResponse | null) => {
        if (team) {
            try {
                setInputValue(team.team_name);
                setSelectedModalTeam(team);
                setSelectedProject(null);
                setProjects([])
                const teamProjects = await getProjectsByTeam(team.id);
                setProjects(teamProjects);
            } catch (error) {
                console.error(error);
            }
        } else {
            setSelectedModalTeam(null);
            setProjects([]);
            setInputValue('');
        }
    };


    useEffect(() => {
        if (showProjectModal) {
            getAllMyTeams(loggedInUserId!, isSuper)
        }
    }, [showProjectModal])


    const handleAddAgentClick = () => {
        if (selectedModalTeam && selectedProject) {
            setShowProjectModal(false)
            localStorage.setItem("Active-Team", selectedModalTeam.id.toString());
            localStorage.setItem("Active-Project", selectedProject.id.toString());
        }

    };

    const handleModalClick = () => {
        setShowProjectModal(true)
        setSelectedModalTeam(null)
        setSelectedProject(null)

    };

    const getAllMyTeams = async (user_id: number, is_superuser: boolean) => {
        let response;
        try {
            if (is_superuser) {
                response = await getAllTeams();
                if (response.length) {
                    setSuperUserTeamsData(response)
                }
            } else {
                response = await getTeamsByUser(user_id);
                if (response.length) {
                    setUserTeamsData(response);
                }
            }

        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className='d-flex flex-column' style={{ backgroundColor: "#f9f9f9" }}>
            <>
                {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
                    <div
                        id='kt_app_sidebar_toggle'
                        className={clsx(`pt-7 ps-4`,
                            { active: appSidebarDefaultMinimizeDefault }
                        )}
                        data-kt-toggle='true'
                        data-kt-toggle-state={toggleState}
                        data-kt-toggle-target='body'
                        data-kt-toggle-name={`app-sidebar-${toggleType}`}
                        onClick={() => setToggle(!toggle)}
                        style={{ cursor: 'pointer' }}
                    >

                        {
                            !toggle ? (
                                <Tooltip title="Collapse" placement="top">
                                    <div className={styles.showToggleBtn}>
                                        <CaretLeft size={19} className="me-2" weight="light" />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Expand" placement="top">
                                    <IconButton style={{ borderRadius: '4px' }}>
                                        <CaretRight size={19} weight="bold" />
                                    </IconButton>
                                </Tooltip>
                            )
                        }


                    </div>
                )}</>
            <>
                {
                    !toggle &&
                    <div className={`mt-5 mb-9 ms-4 ${styles.Parent}`} >
                        <>
                            <div style={{ cursor: "pointer" }} className={`menu-item ${styles.dropdowncontent}`}>
                                <div onClick={handleModalClick}
                                    className={
                                        ` menu-content  pt-1 ${styles.menuItem}` +
                                        (location.pathname === '/configureAgent' && ` ${styles.active}`)
                                    }>
                                    <span className={`menu-title ${styles.tabText} ps-4`}> <Plus size={16} weight="light" /><span >Create Agent</span> </span>
                                </div>
                            </div>

                            <div className={`menu-item ${styles.dropdowncontent}`}>
                                <div
                                    className={
                                        ` menu-content  pt-1 ${styles.menuItem}` +
                                        (location.pathname === '/recentagents' && ` ${styles.active}`)
                                    }>
                                    <Link className={`menu-title ${styles.tabText} ps-4`} to='/recentagents' ><Clock size={20} weight="light" /><span >Recent</span> </Link>
                                </div>
                            </div>

                            <div className={`menu-item ${styles.dropdowncontent}`}>
                                <div

                                    className={
                                        ` menu-content  pt-1 ${styles.menuItem}` +
                                        (location.pathname === '/starredagents/' && ` ${styles.active}`)
                                    }>
                                    <Link className={`menu-title ${styles.tabText} ps-4`} to='/starredagents/' ><Star size={20} weight="light" /><span>Favorites</span> </Link>
                                </div>
                            </div>
                            <div className={`menu-item ${styles.dropdowncontent}`}>
                                <div

                                    className={
                                        ` menu-content pt-1  ${styles.menuItem}` +
                                        (location.pathname === '/templates/' && ` ${styles.active}`)
                                    }>
                                    <Link className={`menu-title ${styles.tabText} ps-4`} to="/templates/" ><FolderSimpleStar size={20} weight="light" /><span>Templates</span> </Link>
                                </div>
                            </div>


                        </>

                        <div className='d-flex justify-content-between align-items-center' style={{ width: '16rem', }}>
                            <div className={`${styles.mainContainer}`} style={{ minWidth: '14rem' }}>
                                <Tooltip title="Your top 5 recent teams" style={{ color: "red" }}>
                                    <div className={` ${styles.header}`} onClick={toggleTeamsDropdown} >
                                        <div className={`menu-title me-6 ${styles.heads}`} style={{ display: 'flex', alignItems: 'center' }}>
                                            Teams
                                            <div className={`${styles.icon} ${styles.headsIcon}`} style={{ marginLeft: '10px', marginTop: "-1px" }}>
                                                {teamsDropdown ? <CaretDown size={15} weight="light" /> : <CaretUp size={15} weight="light" />}
                                            </div>
                                        </div>
                                    </div>
                                </Tooltip>
                            </div>

                            <div className={`${styles.icon}`}><IconButton style={{ color: "black" }}> <AddButtonTeam
                                userId={loggedInUserId!}
                                orgId={loggedInUserOrgId!}
                                userEmail={loggedInUserEmail!}
                                staffUser={isStaff}
                                superUser={isSuper}
                                onTeamProjectEditSuccess={() => fetchTeamUsersData()}
                                recentTeams={recentTeams}
                                setRecentTeams={setRecentTeams}
                            /></IconButton>
                            </div>
                        </div>
                        {teamsDropdown && (
                            <div style={{ marginLeft: '2rem' }}>
                                {
                                    (
                                        recentTeams.length > 0 ? recentTeams
                                            .slice()
                                            .map((recentTeam, index) => {
                                                const teamName = handleTeamName(recentTeam.team.team_name).toString();
                                                const isHovered = index === hoveredIndex;
                                                return (
                                                    <div
                                                        key={index}
                                                        onClick={() => {
                                                            setChevronClicked(true);
                                                            setSelectedTeamName(handleTeamName(recentTeam.team.team_name).toString());
                                                            setIsTeamModalOpen(true);
                                                            setSelectedTeam(recentTeam.team);
                                                            getProjectsOnModal(recentTeam.team.id);
                                                        }}
                                                        className={`${styles.showrecentTeams}`}
                                                        style={{
                                                            backgroundColor: isHovered ? '#d8e8f1' : chevronClicked ? 'transparent' : 'transparent',
                                                            color: isHovered ? '#20808d' : 'black',
                                                            textDecoration: isHovered ? 'underline' : 'none',
                                                            display: "flex",
                                                            transition: 'background-color 0.4s ease',
                                                            borderRadius: '16px', padding: '4px 0.9rem 5px 0.9rem', width: '15rem'

                                                        }}
                                                        onMouseEnter={() => setHoveredIndex(index)}
                                                        onMouseLeave={() => setHoveredIndex(null)}
                                                    >
                                                        <div style={{ flex: "90%", marginLeft: "4px", overflow: "hidden" }}
                                                            className={`menu-title pt-1 fs-12 menu-item `}

                                                        >
                                                            <div className={` ${styles.whcol}`}>
                                                                {/* <Users size={19} weight="light" color={isHovered ? ' #20808d ' : 'black'} /> */}
                                                                <Tooltip title={teamName}><span>{teamName.length > 15 ? `${teamName.slice(0, 14)}...` : teamName}</span></Tooltip>
                                                            </div>
                                                        </div>
                                                        <div className={`menu-content`} >

                                                            <div>
                                                                <IconButton
                                                                >
                                                                    <ChevronRight fontSize='small' style={{ color: "black" }}></ChevronRight>
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                            : (
                                                <div className='menu-item' >
                                                    <div className='menu-content'>
                                                        <span className={`${styles.whcol} menu-title menu-section text-uppercase fs-8 px-5`}>
                                                            Click on browse teams
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                    )
                                }
                            </div>
                        )}

                        <div className={`${styles.mainContainer}`}  >
                            <Tooltip title="Your top 5 recent projects">
                                <div className={`${styles.header}`} onClick={toggleProjectDropdown}>
                                    <div className={`me-6 ${styles.heads}`} >Projects</div>
                                    <div style={{ marginTop: "-4px", marginLeft: "2px" }} className={` ${styles.icon} ${styles.headsIcon}`}  >
                                        {
                                            projectsDropdown ? <CaretDown size={15} weight="light" className='mt-1' /> : <CaretUp size={15} weight="light" className='mt-1' />
                                        }
                                    </div>
                                </div>
                            </Tooltip>
                            {projectsDropdown && (
                                <div style={{ marginLeft: '2rem' }}>
                                    {recentProjects
                                        .map((recentProject, index) => (
                                            <div key={index} className='menu-content align-items-center' style={{
                                                display: "flex",
                                                backgroundColor: hoveredProject === recentProject.project.id ? '#d8e8f1' : 'transparent', wordBreak: "break-word",
                                                color: hoveredProject === recentProject.project.id ? '#20808d ' : 'black',
                                                textDecoration: hoveredProject === recentProject.project.id ? 'underline' : 'none',
                                                transition: 'background-color 0.4s ease',
                                                borderRadius: '16px', padding: '0.1rem 0.9rem 0.3rem 0.9rem', width: '13.5rem'
                                            }}
                                                onMouseOver={() => setHoveredProject(recentProject.project.id)}
                                                onMouseOut={() => setHoveredProject(null)}>
                                                <div style={{ flex: "90%" }} onClick={() => setChevronClicked(true)}>
                                                    <SidebarMenuItem
                                                        hoveredProject={hoveredProject}
                                                        key={recentProject.project.id}
                                                        anchorRef={index === 0 ? anchorRef : null}
                                                        currentActiveProject={currentActiveProject}
                                                        onclick={async () => {
                                                            setCurrentActiveProject(0)
                                                            localStorage.setItem("Active-Project",
                                                                `${recentProject.project.id}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`);
                                                            setIsClicked(true);
                                                            try {

                                                                const response = await createRecentProject(loggedInUserId!, recentProject.project.id);
                                                                if (response.id) {
                                                                    const recentProjectData = await getRecentProject(loggedInUserId!);
                                                                    setRecentProjects(recentProjectData);
                                                                    setCurrentActiveProject(recentProjectData[0].project.id)
                                                                }
                                                            } catch (error) {
                                                                console.error('Error handling active team:', error);
                                                            }
                                                            try {
                                                                const response = await createRecentTeam(loggedInUserId!, recentProject.project.team);

                                                                if (response.id) {
                                                                    const recentTeamData = await getRecentTeam(loggedInUserId!);
                                                                    setRecentTeams(recentTeamData);
                                                                }
                                                            } catch (error) {
                                                                console.error('Error handling active team:', error);
                                                            }
                                                        }}
                                                        to="/project/agents/agents"
                                                        title={recentProject.project.project_name}
                                                        hasBullet={false}
                                                        project_id={recentProject.project.id}
                                                        userId={loggedInUserId!}
                                                        truncate={true}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>

                        <Tooltip title="Explore teams that you're part of">
                            <div className={`menu-item ${styles.browseButton}`} style={{ padding: '0.4rem 0.6rem 0.4rem 0.8rem', marginRight: '1rem' }} onClick={async () => { setbrowseteams(true); setSearchQuery(""); await fetchTeamUsersData(); }}>

                                <span className={`menu-title ${styles.browse}`}>Browse teams</span>
                            </div>
                        </Tooltip>
                        {showAdminButton && <div style={{ backgroundColor: "f9f9f9" }} className={`menu-item ${styles.admindiv}`} >
                            <div className='menu-content'>
                                <div className='meu-title pt-4 pb-2'>
                                    <span className=' menu-section text-uppercase fs-8 ls-1'>Admin Panel</span>
                                </div>
                            </div>
                            <div className='menu-item'>
                                <div
                                    className={
                                        ` menu-content ${styles.admin}` + (location.pathname === '/admin/analytics' && ` ${styles.active}`)
                                    }

                                    onMouseEnter={handleAdminMouseEnter}
                                    onMouseLeave={handleAdminMouseLeave}
                                >
                                    <Link className='menu-title d-flex align-items-center ps-2' to='/admin/analytics' style={{ textDecoration: location.pathname === '/admin/analytics' ? 'underline' : '' }}><Shield size={20} color={adminHover ? '#20808d' : 'black'} weight="light" /><span style={{ color: adminHover ? "#20808d" : "black", textDecoration: adminHover ? "underline" : "" }}>Admin</span> </Link>
                                </div>
                            </div>
                        </div>}

                        <Modal show={browseteams} className={styles.mtop} onHide={() => {
                            setbrowseteams(false);
                        }}
                        >
                            <Modal.Header closeButton  >
                                <Modal.Title className={styles.modalTitle} id="contained-modal-title-vcenter" >
                                    Browse teams
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{ height: "420px" }}>
                                <div >
                                    <div className='d-flex align-items-center position-relative my-1'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className='svg-icon-1 position-absolute ms-6'
                                        />
                                        <input
                                            type='text'
                                            data-kt-user-table-filter='search'
                                            className='form-control form-control-solid w-full ps-14'
                                            placeholder='Search Team Name'
                                            value={searchQuery}
                                            onChange={handleInputChange}
                                            style={{ fontWeight: 300 }}
                                        />
                                    </div>


                                    <br></br>
                                    <div className={`${styles.browsebanner}`}>
                                        <p className={`${styles.browseteamhead}`}>Teams ({filteredAllTeams.length || filteredUserTeams.length})</p>
                                        <p className={`${styles.browseteammember}`}>Members</p>

                                    </div>

                                    <div className={`${styles.teamNamesContainer} ${styles.browse}`} >
                                        {(filteredAllTeams.length > 0 ? filteredAllTeams.map((myteam, index) => {
                                            const teamName = handleTeamName(myteam.team_name).toString();
                                            return (
                                                <div
                                                    key={index}
                                                    className={` ${styles.teamNameRow} ${styles.partone}`} style={{
                                                        cursor: "pointer", padding: '0.1rem', backgroundColor: hoveredBrowseTeam === index
                                                            ? (activeTheme === 'dark' ? 'rgb(221 221 221)' : 'rgb(221 221 221)') : 'transparent', borderRadius: "5px"
                                                    }}
                                                    onClick={() => { handleActiveTeam(myteam); setbrowseteams(false); }}
                                                    onMouseOver={() => setHoveredBrowseTeam(index)}
                                                    onMouseOut={() => setHoveredBrowseTeam(null)}
                                                >
                                                    <div className={`menu-title pt-2 fs-12 menu-item ${styles.teamicon} ${styles.browseteam}`} >

                                                        <div className={`${styles.browseTeamName}`}>
                                                            <Users size={19} weight="light" />
                                                            &nbsp;&nbsp;{teamName}

                                                        </div>

                                                        <div className={`${styles.browseUsers}`}>
                                                            {teamUsersData[myteam.id]?.slice(0, 5).map((user, index) => (
                                                                <React.Fragment key={index}>
                                                                    {index > 0 && ', '}{user.user.first_name}
                                                                </React.Fragment>
                                                            ))}
                                                            {teamUsersData[myteam.id]?.length > 3 && <span> +{teamUsersData[myteam.id].length - 3}&nbsp;other</span>}
                                                        </div>


                                                        <div>

                                                        </div>




                                                    </div>
                                                </div>
                                            );

                                        }) : (filteredUserTeams.length > 0 ? filteredUserTeams.map((myteam, index) => {
                                            const teamName = handleTeamName(myteam.team.team_name).toString();
                                            return (
                                                <div
                                                    key={index}
                                                    className={` ${styles.teamNameRow} ${styles.partone}`} style={{
                                                        cursor: "pointer", backgroundColor: hoveredBrowseTeam === index
                                                            ? (activeTheme === 'dark' ? '#36454F' : '#E8E8E8') : 'transparent', borderRadius: "5px"
                                                    }}
                                                    onClick={() => { handleActiveTeam(myteam.team); setbrowseteams(false); }}
                                                    onMouseOver={() => setHoveredBrowseTeam(index)}
                                                    onMouseOut={() => setHoveredBrowseTeam(null)}
                                                >
                                                    <div className={`menu-title pt-2 fs-12 menu-item ${styles.teamicon} ${styles.browseteam}`} >

                                                        <div className={`${styles.browseTeamName}`} >
                                                            <Users size={20} weight="light" color='black' />
                                                            &nbsp;&nbsp;{teamName}

                                                        </div>

                                                        <div className={`${styles.browseUsers}`} >
                                                            {teamUsersData[myteam.team.id]?.slice(0, 5).map((user, index) => (
                                                                <React.Fragment key={index}>
                                                                    {index > 0 && ', '}{user.user.first_name}
                                                                </React.Fragment>
                                                            ))}
                                                            {teamUsersData[myteam.team.id]?.length > 3 && <span> +{teamUsersData[myteam.team.id].length - 3}&nbsp;other</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            );

                                        }) : <p>No Teams Found</p>))}
                                    </div>
                                </div>

                            </Modal.Body>
                        </Modal>


                        <Modal className={`${styles.modalcont} ${styles.addprojModal}`} show={isTeamModalOpen} onHide={closemodal}>
                            <div >
                                <Modal.Header style={{ height: "10px", fontWeight: 400 }} closeButton>
                                    <Modal.Title className={`${styles.addProjTitle}`} >{selectedTeamName}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className={`${styles.addProjBody}`} >
                                        <div className={`${styles.hovereffect} ${styles.padding}`}  >
                                            {selectedTeam && <AddButtonProject
                                                setCurrentActiveProject={setCurrentActiveProject}
                                                title='add'
                                                userId={loggedInUserId!}
                                                orgId={loggedInUserOrgId!}
                                                userEmail={loggedInUserEmail!}
                                                team_id={selectedTeam!.id}
                                                onTeamProjectEditSuccess={() => handleActiveTeam(selectedTeam)}
                                                setreloadProject={setreloadProject}
                                                reloadProject={reloadProject}
                                                createProjectCalled={createProjectCalled}
                                                setCreateProjectCalled={setCreateProjectCalled}
                                                isOpen={isOpen}
                                                setisopen={setisopen}
                                                isTeamModalOpen={isTeamModalOpen}
                                                setIsTeamModalOpen={setIsTeamModalOpen}
                                                recentProjects={recentProjects}
                                                setRecentProjects={setRecentProjects}

                                            />}

                                        </div>
                                        <div className={`${styles.hovereffect} ${styles.padding}`}>

                                            {selectedTeam && <EditButtonTeam
                                                setRenderedProjectItems={setRenderedProjectItems}
                                                userProjectsData={userProjectsData}
                                                setActiveTeam={setActiveTeam}
                                                orgId={loggedInUserOrgId!}
                                                userEmail={loggedInUserEmail!}
                                                team_name={selectedTeam!.team_name}
                                                team_id={selectedTeam!.id}
                                                created_by={selectedTeam!.created_by}
                                                onTeamProjectEditSuccess={() => {
                                                    handleActiveTeam(selectedTeam);
                                                }}
                                                setTeam={team?.setTeam}
                                                projectsExist={projectPresent}
                                            />
                                            }

                                        </div>
                                        <hr />
                                        <h6 style={{ fontWeight: 400 }}>Projects</h6>
                                        <div className={`${styles.showproj}`}  >
                                            {projectPresent ? (teamProjects
                                                .map((project, index) => (
                                                    <div key={index} className={`menu-title menu-item  ${styles.showrecentProj}`}>
                                                        {project}
                                                    </div>
                                                ))) : <span className='menu-title menu-section text-center text-muted text-uppercase fs-9 ls-1 px-6'>No Projects Yet</span>

                                            }
                                        </div>

                                    </div>

                                </Modal.Body>
                            </div>

                        </Modal>

                        <Modal
                            show={showProjectModal}
                            onHide={() => setShowProjectModal(false)}
                            className="mtop"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Team and Project Selection</Modal.Title>
                            </Modal.Header>

                            <Modal.Body style={{ height: "420px" }}>
                                <div>
                                    <Autocomplete
                                        size="small"
                                        style={{ cursor: 'pointer', width: "93%", marginBottom: '20px' }}
                                        value={selectedModalTeam}
                                        onChange={(event, newValue) => handleTeamSelect(event, newValue)}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                                        // options={userTeamsData.map((userTeam) => userTeam.team)}
                                        options={isSuper ? superUserTeamsData : userTeamsData.map((userTeam) => userTeam.team)}
                                        getOptionLabel={(option) => option.team_name}
                                        sx={{
                                            marginLeft: "2%",
                                            ".MuiInputBase-root": {
                                                borderRadius: "7px !important",
                                                width: "99%",
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Team"
                                                size="small"
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ padding: "7px", borderBottom: "0.2px solid lightgray" }}>
                                                <span style={{ display: "flex", color: "grey", fontSize: "1rem" }}>
                                                    {option.team_name}
                                                </span>
                                            </li>
                                        )}
                                    />

                                    {selectedModalTeam && (
                                        <div>
                                            <Autocomplete
                                                size="small"
                                                style={{ cursor: 'pointer', width: "93%", marginBottom: '20px', marginTop: "7%" }}
                                                value={selectedProject}
                                                onChange={(event, newValue) => setSelectedProject(newValue)}
                                                inputValue={projectInputValue}
                                                onInputChange={(event, newInputValue) => setProjectInputValue(newInputValue)}
                                                options={projects}
                                                getOptionLabel={(option) => option.project_name}
                                                sx={{
                                                    marginLeft: "2%",
                                                    ".MuiInputBase-root": {
                                                        borderRadius: "7px !important",
                                                        width: "99%",
                                                    },
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Project"
                                                        size="small"
                                                        variant="outlined"
                                                        style={{ width: "100%" }}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <li {...props} style={{ padding: "7px", borderBottom: "0.2px solid lightgray" }}>
                                                        <span style={{ display: "flex", color: "grey", fontSize: "1rem" }}>
                                                            {option.project_name}
                                                        </span>
                                                    </li>
                                                )}
                                            />
                                        </div>
                                    )}

                                    {selectedModalTeam && selectedProject && (
                                        <div style={{ marginTop: '18rem', textAlign: "right" }}>
                                            <Link to="/configureAgent">
                                                <button
                                                    style={{
                                                        backgroundColor: "#20808d",
                                                        borderColor: "#20808d",
                                                        color: "white",
                                                        padding: "10px 20px",
                                                        borderRadius: "5px",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        outline: "none",
                                                    }}
                                                    onClick={handleAddAgentClick}
                                                >
                                                    Next
                                                </button>
                                            </Link>
                                        </div>
                                    )}

                                </div>
                            </Modal.Body>
                        </Modal>


                    </div>
                }
            </>

        </div >
    )
}

export { SidebarMenuMain }













