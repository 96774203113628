
// import React from "react";
import DOMPurify from "dompurify";
import styles from "./ThoughtProcessContent.module.css";



interface Props {
  thoughtProcess: string | null | undefined;
}

export const ThoughtProcessContent = ({ thoughtProcess }: Props) => {
  // Handle the case where thoughtProcess is null by providing an empty string as default
  const sanitizedThoughtProcess = DOMPurify.sanitize(thoughtProcess || "");

  return (
    <div className={styles.thoughtProcessDiv} >
      <p dangerouslySetInnerHTML={{ __html: sanitizedThoughtProcess }}></p>
    </div>
  );
};

