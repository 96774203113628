import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { Card2 } from "../../../../_metronic/partials/content/cards/Card2";
import { StarredAgentResponse, getRecentAgents, RatingsResponse, decryptData, fetchLoginCookie, getAllRatings } from '../../../../api';
import bot from '../../../../media_dsd/bot.png';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../../profile/ProfileStyle.module.css';
import style from './agents.module.css';

interface props {
    setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;
}
const RecentAgent = ({setRefreshChatPageOnAgentCreation}:props) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [ispromptDataLoading, setispromptDataLoading] = useState(true);
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
    const [recentAgent, setRecentAgent] = useState<StarredAgentResponse[]>([]);
    // const agentResponses = agentsByCategoryData.map((item) => item.);
    const width = window.innerWidth;
    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);

        if (userId) {
            setLoggedInUserId(userId);
            getRecentAgent(userId);
            getAllAgentRatings()
        }

    }, []);

    const getAllAgentRatings = async () => {
        try {
            const response = await getAllRatings();
            setRatingsData(response);
        } catch (error) {
            console.error(error);

        }
    };
    const getRecentAgent = async (id: any) => {
        try {
            const response = await getRecentAgents(id);
            setRecentAgent(response);
            setispromptDataLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={style.mb3}>
            <p className={`mt-6 text-muted ${style.smHeading}`}>Browse Your Recent Agents</p>
            <div className={`${styles.agentCardMain} row g-6 g-xl-9`} style={{flexDirection: "column", alignItems: "center"}} >
            <div className='row g-3 g-xl-9' style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                    {!ispromptDataLoading && loggedInUserId ? (
                        <>
                            {recentAgent.length > 0 && recentAgent
                                .filter((item) => {
                                    // Filter agents based on search query
                                    return item.agent.agent_name.toLowerCase().includes(searchTerm.toLowerCase());

                                })
                                .map((item: StarredAgentResponse, index: number) => {
                                    // Render other items except the activated one
                                    return (
                                        <div key={index} className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`}>
                                            <Card2
                                                setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                                                agentUpdateAfterDelete={false}
                                                key={index}
                                                data={item.agent}
                                                currentUserId={loggedInUserId!}
                                                viewMode='default'
                                                allRatings={allRatings}
                                                setRatingsData={setRatingsData}
                                            /></div>
                                    );
                                })}</>) : (
                        <div className={style.CircularProgress}>
                            <CircularProgress /></div>
                    )}

                </div>
            </div>

        </div>
    )
}
export { RecentAgent }