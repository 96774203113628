import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Card2 } from '../../../content/cards/Card2'
import { KTSVG } from '../../../../helpers';
import { AgentCardResponse, AgentCardResponse2, AgentCategoryResponse, RatingsResponse, decryptData, fetchLoginCookie, getAgentByProject, getAllRatings } from '../../../../../api'
import { Modal, Button } from 'react-bootstrap';
import { Divider, Grid, ListItem } from '@mui/material'
import styles from './PreconfigAgents.module.css'
// import { useParams } from 'react-router-dom'

interface Props {
  onCloneClick: (id: number) => void;
  currentUserId: number;
  onCloseClick: () => void;
  agentsByCategory: AgentCategoryResponse[];
  setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;
}

const PreconfigAgents = ({ setRefreshChatPageOnAgentCreation, onCloneClick, currentUserId, onCloseClick, agentsByCategory }: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [agentData, setAgentData] = useState<AgentCardResponse2[]>([]);
  const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [activeDescription, setActiveDescription] = useState<string | null>(null);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const activeProject = localStorage.getItem("Active-Project");

    if (userId) {
      setLoggedInUserId(userId);
      getAllAgentRatings();
    }

  }, [localStorage.getItem("Active-Project")]);

  const uniqueCategoryNames = [...new Set(agentsByCategory.map((data) => data.category.name))];
  const handleModalCloseClick = () => {
    onCloseClick();
  }

  const groupAgentsByCategory = () => {
    const groupedAgents: { [key: string]: AgentCardResponse2[] } = {};
    agentsByCategory.forEach((categoryData) => {
      const categoryName = categoryData.category.name;
      if (!groupedAgents[categoryName]) {
        groupedAgents[categoryName] = [];
      }
      groupedAgents[categoryName].push(categoryData.agent);
    });

    return groupedAgents;
  };
  const groupedAgents = groupAgentsByCategory();

  const agentResponses = agentsByCategory.map((item) => item.agent);

  const handleCategoryClick = (categoryName: string | null) => {
    setActiveCategory(categoryName);
    const selectedCategory = agentsByCategory.find((categoryData) => categoryData.category.name === categoryName);
    setActiveDescription(selectedCategory?.category.description || null);
  };
  let textColor = activeCategory === null ? 'rgb(32, 128, 141)' : 'black';
  if (activeTheme === 'dark') {
    textColor = 'white';
  }

  const renderAgents = () => {
    if (searchTerm !== '') {
      return (
        <div className={`row g-3 g-xl-9 ${styles.container}`}>
          <div className='row g-3 g-xl-9' style={{ justifyContent: "flex-start" }}>
            {agentResponses
              .filter((agent) => agent.agent_name.toLowerCase().includes(searchTerm.toLowerCase()))
              .map((item: AgentCardResponse2, index: number) => (
                <div className={styles.innerCard}>
                  <Card2
                    setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                    agentUpdateAfterDelete={false}
                    setAgentData={setAgentData}
                    data={item}
                    key={index}
                    viewMode='template'
                    currentUserId={loggedInUserId!}
                    allRatings={allRatings}
                    setRatingsData={setRatingsData}
                    onCloneClick={onCloneClick}
                  /> </div>
              ))}
          </div>

        </div>

      );
    }
    else if (activeCategory === null) {
      // Display all templates
      return (
        <div>
          {Object.keys(groupedAgents).map((categoryName) => (
            <div key={categoryName}>
              <div className={styles.categoryContainer}>
                <h3 onClick={() => handleCategoryClick(null)} className={styles.categoryTitle}>{categoryName}</h3>
                {groupedAgents[categoryName].length > 3 && (
                  <p onClick={() => handleCategoryClick(categoryName)} className={styles.seeAll}>
                    See All
                  </p>
                )}
              </div>
              <div className={`row g-3 g-xl-9 ${styles.container}`}>
                <div className='row g-3 g-xl-9' style={{ justifyContent: "flex-start" }}>

                  {groupedAgents[categoryName].slice(0, 3).map((item: AgentCardResponse2, index: number) => (
                    <div key={index} className={styles.innerCard}>
                      <Card2
                        setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                        agentUpdateAfterDelete={false}
                        setAgentData={setAgentData}
                        key={index}
                        data={item}
                        currentUserId={loggedInUserId!}
                        viewMode='template'
                        onCloneClick={onCloneClick}
                        allRatings={allRatings}
                        setRatingsData={setRatingsData}
                      />
                    </div>
                  ))}
                </div>

              </div>
            </div>
          ))}
        </div>
      );
    } else {
      // Display agents of the selected category
      return (
        <div>
          <h3>{activeCategory}</h3>
          <p>{activeDescription}</p>

          <div className={`row g-3 g-xl-9 ${styles.container}`}>
            <div className='row g-3 g-xl-9' style={{ justifyContent: "flex-start" }}>
              {groupedAgents[activeCategory]?.map((item: AgentCardResponse2, index: number) => (
                <div key={index} className={styles.innerCard}>
                  <Card2
                    setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                    agentUpdateAfterDelete={false}
                    setAgentData={setAgentData}
                    key={index}
                    data={item}
                    currentUserId={loggedInUserId!}
                    viewMode='template'
                    onCloneClick={onCloneClick}
                    allRatings={allRatings}
                    setRatingsData={setRatingsData}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

      );
    }
  };
  const getAllAgentRatings = async () => {
    try {
      const response = await getAllRatings();
      setRatingsData(response);
    } catch (error) {
      console.error(error);

    }
  };
  return (
    <div>
      <Modal size='xl' dialogClassName='modal-dialog modal-dialog-centered' show={true} onHide={handleModalCloseClick} keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Preconfigured Agents</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          <Grid container>
            {/* Left side for Categories */}
            <Grid item xs={2}>
              <div style={{ marginLeft: '20px' }}>
                <div style={{ paddingBottom: '1px', marginTop: '20px' }}>
                  <h3 onClick={() => handleCategoryClick(null)}
                    style={{
                      cursor: 'pointer', color: activeCategory === null
                        ? 'rgb(32, 128, 141)'
                        : activeCategory !== null && activeTheme === 'dark'
                          ? 'white'
                          : 'black',
                    }}>
                    All Templates</h3>
                </div>
                <div
                  className='h-4px w-50 bg-light mb-5'>
                  <hr className={styles.hrLine}></hr>
                </div>
                <h3>Categories</h3>
                {uniqueCategoryNames.map((categoryName, index) => (
                  <ListItem
                    key={index}
                    onClick={() => handleCategoryClick(categoryName)}
                    style={{
                      cursor: 'pointer',
                      color: activeCategory === categoryName
                        ? 'rgb(32, 128, 141)'
                        : activeCategory !== categoryName && activeTheme === 'dark'
                          ? 'white'
                          : 'black',
                      fontSize: '14px',
                    }}
                  >
                    {categoryName}
                  </ListItem>
                ))}
              </div>
            </Grid>
            {/* Vertical Divider */}
            <Divider orientation="vertical" flexItem sx={{ mb: 1 }} />
            {/* Right side for Agents */}
            <Grid item xs>
              <div className='d-flex align-items-center position-relative my-1' style={{ paddingBottom: '20px', marginLeft: '20px' }}>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-solid w-250px ps-14'
                  placeholder='Search agents'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ marginRight: '0.8rem', background: 'white', fontWeight: 300 }}
                />
              </div>
              <div className={styles.agentDiv}>
                {renderAgents()}
              </div>
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalCloseClick}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { PreconfigAgents };
