// import {Link} from 'react-router-dom'
import clsx from 'clsx'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core'
import { Dispatch, SetStateAction } from 'react'

interface props {
  setToggle: Dispatch<SetStateAction<boolean>>;
  toggle: boolean;
}
const SidebarLogo = ({ setToggle, toggle }: props) => {
  const { config } = useLayout()


  return (
    <div className='app-sidebar-logo px-6' id=''>
      {/* <Link to='/agent/'> */}
      {
        !toggle && <>
          {
            config.layoutType === 'dark-sidebar' ? (
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/dsd_main_logo.png')}
                className='h-40px app-sidebar-logo-default'
              />
            ) : (
              <>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/dsd_main_logo.png')}
                  className='h-10px app-sidebar-logo-default theme-light-show'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/dsd_main_logo.png')}
                  className='h-40px app-sidebar-logo-default theme-dark-show'
                />
              </>
            )
          }
        </>
      }



      {
        toggle && <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/dsd_icon.png')}
          className='h-40px app-sidebar-logo-minimize'
        />
      }

      {/* </Link> */}


    </div>
  )
}

export { SidebarLogo }

