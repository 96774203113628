import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { useState } from 'react'
import { ConfigureHeader } from './configureHeader';
import { UsersListWrapper } from '../apps/user-management/users-list/UsersList';
import { ChatLog } from './chatlog/chatUsers'
import { Analytics } from './analytics/Analytics'
import { ModelSetting } from './model/modelSetting';
import { AgentAlias } from './agentalias/agentAlias';

interface props {
    isStaff: boolean;
    isSuper: string | null;
}
const Configure = ({ isStaff, isSuper }: props) => {
    const [newProjectId, setNewProjectId] = useState<number>();
    const handleProjectId = async (id: number) => {
        setNewProjectId(id);
    };

    return (
        <Routes>
            <Route
                element={
                    <>
                        <ConfigureHeader onProjectIDArrival={handleProjectId} />
                        <Outlet />
                    </>
                }
            >
                {isSuper !== null &&
                    <>
                        {
                            (isSuper === 'yes') || isStaff ?
                                <Route
                                    path='user-settings'
                                    element={
                                        <>
                                            <UsersListWrapper />
                                        </>
                                    }
                                /> :
                                <Route path='user-settings' element={<Navigate to='/recentagents/' />} />
                        }
                    </>

                }
                {isSuper !== null &&
                    <>
                        {
                            (isSuper === 'yes') ? <Route
                                path='chatlogs'
                                element={
                                    <>
                                        <ChatLog />
                                    </>
                                }
                            /> : <Route path='chatlogs' element={<Navigate to='/recentagents/' />} />
                        }
                    </>

                }
                {isSuper !== null &&
                    <>
                        {
                            (isSuper === 'yes') || isStaff ?
                                <Route
                                    path='analytics'
                                    element={
                                        <>
                                            <Analytics />
                                        </>
                                    }
                                /> : <Route path='analytics' element={<Navigate to='/recentagents/' />} />
                        }
                    </>

                }
                {isSuper !== null &&
                    <>
                        {
                            (isSuper === 'yes') || isStaff ?
                                <Route
                                    path='agentalias'
                                    element={
                                        <>
                                            <AgentAlias />
                                        </>
                                    }
                                /> : <Route path='agentalias' element={<Navigate to='/recentagents/' />} />
                        }
                    </>
                }
                <Route
                    path='model-settings'
                    element={
                        <>
                            <ModelSetting />
                        </>
                    }
                />
                <Route index element={<Navigate to='/admin/user-settings' />} />
            </Route>
        </Routes>
    )
}
export default Configure


