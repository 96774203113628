/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { KTSVG, useDebounce } from '../../../../../../../_metronic/helpers'
import { Dispatch, SetStateAction } from 'react'

interface Props {
  setFilteredUsers: Dispatch<SetStateAction<any[]>>;
  allUsers: any[];
}

const UsersListSearchComponent = ({ allUsers, setFilteredUsers }: Props) => {

  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, 150)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        const filteredUsers = allUsers.filter((user) => {
          return user.name.toLowerCase()?.includes(searchTerm.toLocaleLowerCase()) 
      });
          setFilteredUsers(filteredUsers)
      }
    },
    [debouncedSearchTerm]
  )

  return (
    <div className='card-title'>

      <div className='d-flex align-items-center position-relative my-1'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-1 position-absolute ms-6'
        />
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder='Search user'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{fontWeight:300}}
        />
      </div>

    </div>
  )
}
export { UsersListSearchComponent }
