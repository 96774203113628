import './NotificationCounter.css';
import { useState, useEffect } from 'react'
import { getUserNotifications, fetchLoginCookie, decryptData } from '../../../../api'
import { Bell } from "@phosphor-icons/react";
interface NotificationCounterProps {
  markAllAsRead: boolean;
  openNotif: boolean;

}

const NotificationCounter = ({ markAllAsRead, openNotif }: NotificationCounterProps) => {

  const [count, setCount] = useState<number>(0);
  const [isopen, setIsOpen] = useState<boolean>(false);
  const btnIconClass = 'svg-icon-1'

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo)
    const userId = parseInt(decryptedRes?.id)

    // Fetch notifications and update the count initially
    updateCount(userId);
    // Set up a timer to fetch and update the count every 2 minutes
    const intervalId = setInterval(() => updateCount(userId), 0.25 * 60 * 1000) // 2 minutes in milliseconds
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    if (markAllAsRead) {
      setCount(0);
    }
  }, [markAllAsRead]);


  const updateCount = async (id: any) => {
    const response = await getUserNotifications(id);
    const unreadCount = response.filter((response) => !response.is_read).length;
    setCount(unreadCount);
  }

  useEffect(() => {
    if ((count > 0 && openNotif == true)) {
      const element = document.getElementsByClassName('notifModal')[0] as HTMLElement;

      if (element) {
        const marking = document.getElementsByClassName('markread')[0] as HTMLElement;
        if (marking) {
          marking.click();
        }
      } else {
        console.warn('Element not found');
      }
    }

  }, [openNotif]);

  return (
    <div className="notification-counter" style={count ? { marginBottom: "10px" } : {}}>
      {count > 0 && <span className="notification-badge">{count}</span>}
      <Bell size={20} weight="light" className={`${btnIconClass} iconStylesLightTheme`} />
    </div>
  );
};

export default NotificationCounter;
