import { initializeIcons } from "@fluentui/react";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ProjectRequest, createProject, createRecentProject, decryptData, fetchLoginCookie, getRecentProject } from "../../../../../api";
import { useNavigate } from "react-router-dom";
import styles from './sidemenuStyles.module.scss'
import { FilePlus } from "@phosphor-icons/react";

initializeIcons();

type Props = {
    userId: number;
    orgId: number;
    userEmail: string;
    team_id: number;
    onTeamProjectEditSuccess: () => void;
    title: string
    setreloadProject: Dispatch<SetStateAction<boolean>>
    reloadProject: boolean
    createProjectCalled: boolean;
    setCreateProjectCalled: Dispatch<SetStateAction<boolean>>;
    isOpen: boolean
    setisopen: Dispatch<SetStateAction<boolean>>;
    isTeamModalOpen: boolean
    setIsTeamModalOpen: Dispatch<SetStateAction<boolean>>;
    recentProjects;
    setRecentProjects;
    setCurrentActiveProject;

};

export const AddButtonProject = ({setCurrentActiveProject, setreloadProject, userId, orgId, userEmail, team_id, onTeamProjectEditSuccess, title, reloadProject, createProjectCalled, setCreateProjectCalled, isOpen, setisopen, isTeamModalOpen, setIsTeamModalOpen, recentProjects, setRecentProjects
}: Props) => {
    // const [show, setShow] = useState(false);
    const [ProjectName, setProjectName] = useState('');
    const [isProjectNameRequired, setIsProjectNameRequired] = useState(false);
    const [loading, setLoading] = useState(false)
    const [isExceedingLength, setIsExceedingLengt] = useState<boolean>(false);
    const addButtonRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const activeTheme = localStorage.getItem("kt_theme_mode_value");


    const handleClose = () => setisopen(false);
    useEffect(() => {
        const handleKeyUp = (event: { key: string; }) => {
            if (event.key === 'Enter' && addButtonRef.current) {
                addButtonRef.current.click();
                setCreateProjectCalled(true);
            }
        };

        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);



    const handleShowToast = () => {
        toast.success('Project Added Successfully', {
            style: {
                fontSize: '14px',

            },
        });
    };

    const handleProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (newValue.length <= 30) {
            setProjectName(newValue);
            setIsExceedingLengt(false);
        } else {
            setIsExceedingLengt(true);
        }

    };

    const handleSaveChanges = async () => {
        if (!ProjectName) {
            setIsProjectNameRequired(true);
            return;
        }
        await createNewProject();
        setIsTeamModalOpen(false)

        navigate('/project/agents/agents')

    };

    const createNewProject = async () => {
        setCreateProjectCalled(true);
        setLoading(true)
        try {
            const request: ProjectRequest = {
                team_id: (team_id)!,
                user_id: userId,
                project_name: ProjectName,
                created_by: userEmail
            };
            const response = await createProject(request);
            if (response.id) {
                onTeamProjectEditSuccess();
                setreloadProject(!reloadProject)
                handleClose();
                handleShowToast();

                setProjectName('');
                localStorage.setItem("Active-Project", `${response.id}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`)
                try {
                    const response2 = await createRecentProject(userId!, response.id);

                    if (response2.id) {
                        const recentProjectData = await getRecentProject(userId!);
                        setRecentProjects(recentProjectData)
                        setCurrentActiveProject(recentProjectData[0].project.id)
                    }
                } catch (error) {
                    // Handle any errors that occur during the process
                    console.error('Error handling active team:', error);
                }

            }

        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }


    const handleShow = () => {
        setisopen(true)
        setIsProjectNameRequired(false);
        if (ProjectName == '') {
            setIsExceedingLengt(false)
        }

    }
    return (
        <>
            <span
                onClick={handleShow}
                style={{ cursor: "pointer", fontSize: "15px", fontWeight: "300", lineHeight: "10px" }}
            ><span className="d-flex align-items-center"><FilePlus className={`${activeTheme === 'dark' ? 'iconStylesDarkTheme' : 'iconStylesLightTheme'}`} size={20} weight="light" /><span>Create Project</span></span> </span>

            <Modal className={`${styles.modalon}`} show={isOpen} onHide={handleClose} keyboard={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: '300' }}>Add a Project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="ProjectName">
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Project name"
                                value={ProjectName}
                                onChange={handleProjectNameChange}
                                autoComplete="on"
                                style={{ fontWeight: 300 }}
                            />
                            {isProjectNameRequired && (
                                <span className="text-danger">Project name is required</span>
                            )}
                            {isExceedingLength && (
                                <div className='fv-plugins-message-container'>
                                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                                        Maximum Limit Reached
                                    </div>
                                </div>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button variant="primary" onClick={handleSaveChanges}>
                        Add
                    </Button> */}

                    {loading && (
                        <button
                            type='button'
                            className={`btn me-3 ${styles.modalButton}`}

                            data-kt-stepper-action='previous'

                        >
                            <span className='indicator-progress d-block'>
                                Please wait
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span></button>
                    )}
                    {!loading && (<>
                        <button
                            type='button'
                            className={`btn me-3 ${styles.modalButton}`}
                            data-kt-stepper-action='previous'
                            onClick={handleSaveChanges}
                            ref={addButtonRef}
                        >
                            Add
                        </button>
                    </>)}
                </Modal.Footer>
            </Modal>
        </>
    );
};
