/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Stack } from "@mui/material";
import { Dispatch, SetStateAction } from 'react'
import { KTSVG } from "../../../../helpers";
import Chip from '@mui/material/Chip';
import styles from './Step.module.scss'
// import styles from './Step.module.scss';
interface Props3 {
  hasError: boolean
  instruction: string
  setInstruction: Dispatch<SetStateAction<string>>
  handleAddInstruction: () => void
  instructions: string[]
  handleDeleteInstruction: (instructionToDelete: string) => void
  prevStep: () => void
  nextStep: () => void
  isLoading: boolean
  creatingAgent: boolean

}
const Step3 = ({ creatingAgent, isLoading, nextStep, prevStep, instructions, handleAddInstruction, hasError, instruction, setInstruction, handleDeleteInstruction }: Props3) => {
  return (
    <>
      {/*begin::Step 3 */}
      <div className='pb-5' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}

          <div className='fv-row mb-10'>
            <label className={`${styles.heading} required fs-5 fw-semibold mb-2 `}  >Add Instructions</label>

            <textarea
              rows={10}
              className='form-control form-control-lg form-control-solid'
              name='instructions'
              value={instruction}
              onChange={(e) => setInstruction(e.target.value)}
            />
            <p className={styles.muted}>Write task instructions for the agent E.g: Provide summary of document and comparisons wherever applicable</p>
            {!(instructions.length > 0) && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Instructions are required
                </div>
              </div>
            )}
            <Button className={`btn ${styles.pointer} ${styles.Btn}`}
              onClick={handleAddInstruction} >
              Add
            </Button>
            <Stack direction="row" alignItems="center" sx={{ display: "flex", flexWrap: "wrap", marginBottom: "10px", mt: 1 }}>
              {instructions.map((instruction, index) => (
                <Chip sx={{
                  height: 'auto',
                  '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                    padding: '10px',
                    // backgroundColor: '#fff'
                  },
                }}
                  onDelete={() => handleDeleteInstruction(instruction)}
                  color="primary" variant="outlined"
                  key={index} label={instruction} className={styles.m3}
                />
              ))}
            </Stack>
            <div className="d-flex justify-content-between">

              {!creatingAgent &&
                <button
                  type='button'
                  className={`btn btn-lg ${styles.Btn} ${styles.pointer}`}

                  data-kt-stepper-action='prev'
                  onClick={prevStep}
                >
                  <>
                    <span>Previous</span>

                  </>


                </button>}
              {
                !isLoading && <button
                  type='button'
                  className={`btn btn-lg ${styles.pointer} ${styles.Btn}`}
                  data-kt-stepper-action='next'
                  onClick={nextStep}
                >
                  <span>Create Agent</span>
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr064.svg'
                    className='svg-icon-3 ms-1 me-0'
                  />
                </button>
              }
              {
                isLoading && <button
                  type='button'
                  className={`btn btn-lg ${styles.pointer} ${styles.Btn}`}
                  data-kt-stepper-action='next'

                >
                  <span className='indicator-progress d-block' >
                    Please wait
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                </button>
              }



            </div>
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}

          {/*end::Form Group */}
        </div>

      </div>
      {/*end::Step 3 */}

    </>
  )
}

export { Step3 }
