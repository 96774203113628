import { Description } from "./Description"
import { HomeNav } from "./HomeNav"
import { USPs } from "./USPs"
import { Footer } from "./Footer"
import styles from './homepageStyles.module.scss'
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Suspense, useEffect, useState } from "react"
import { getEnv } from "../../../api"
import { useNavigate } from "react-router-dom"
import { LayoutSplashScreen } from "../../../_metronic/layout/core"


const Homepage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        // Fetch the URL from the backend
        const fetchHomeUrl = async () => {
            try {
                const response = await getEnv("homeurl");
                if (response) {
                    window.location.href = response;
                }
                else {
                    navigate('/auth')
                }
            } catch (error) {
                console.error('Error fetching redirect URL:', error);
            }
        };
        fetchHomeUrl();
    }, []);


    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            {/* <HomeNav ></HomeNav>
            <div className={styles.heroParent}>

                <h1>
                    Ejento AI | Talk to your documents
                </h1>
                <p>Build an LLM agent for any task in less than 5 minutes. Summarize documents, generate new content or ask questions about content in text documents, videos, web pages and more. </p>
                <div className={styles.buttonParent}>

                    <AnchorLink href='#footer'><button className={styles.services}>  Join Wait List</button></AnchorLink>
                    <AnchorLink href='#usp'><button className={styles.demo}>Learn More</button></AnchorLink>

                </div>
            </div>
            <Description></Description>
           <USPs id='usp'></USPs>

          <Footer id='footer'></Footer> */}
        </Suspense>
    )
}

export { Homepage }