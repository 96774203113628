import React from 'react';
import PersonIcon from '@mui/icons-material/Person';

interface FeedbackProps {
  comment: {
    id: number
    created_on: string; // Assuming created_on is a string representing the creation time
    created_by: string;
    comment: string;
  };
}

export const Feedback: React.FC<FeedbackProps> = ({ comment }) => {
  const createdOn = new Date(comment.created_on);
  const now = new Date();
  const timeDifference = now.getTime() - createdOn.getTime();

  const formatTimeDifference = () => {
    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (days > 0) {
      return `${days} day${days === 1 ? '' : 's'} ago`
    } else if (hours > 0) {
      return `${hours} hr${hours === 1 ? '' : 's'} ago`
    } else if (minutes > 0) {
      return `${minutes} min${minutes === 1 ? '' : 's'} ago`
    } else {
      return `${seconds} sec${seconds === 1 ? '' : 's'} ago`
    }
  }


  return (
    <div className="comment-container">
      <div className="comment-details">
        {/* <div className="user-details">
          <PersonIcon color="primary" />
          <p style={{ marginLeft: '7px', fontSize: '11px', color: 'grey' }}>{comment.created_by}</p>
        </div> */}
        <p className="custom-h4 text-black">{comment.comment}</p>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: '60px', alignItems: 'center' }}>
        <p className="time-difference">{formatTimeDifference()}</p>
      </div>
    </div>
  );
};
