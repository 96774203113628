/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import project from '../../../media_dsd/project.jpg'
import { IconButton } from '@fluentui/react'
import { EditProjectRequest, UserTeamResponseByTeam, decryptData, deleteProject, editProject, fetchLoginCookie, getAgentByProject, getProject, getProjectsByUser, getUsersByTeam } from '../../../api'
import { TextField } from '@mui/material'
import Swal from 'sweetalert2'
import styles from './ProfileStyle.module.css'
import PrecisionManufacturingSharpIcon from '@mui/icons-material/PrecisionManufacturingSharp';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { TrashSimple } from '@phosphor-icons/react'

type Props = {
  onProjectIDArrival: (id: number) => void;
  setProjectChange: Dispatch<SetStateAction<boolean>>
}


const ProfileHeader: React.FC<Props> = ({ onProjectIDArrival, setProjectChange }) => {

  const location = useLocation();
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");
  const [projectCreatorEmail, setProjectCreatorEmail] = useState<string>("");
  const [editProjectId, setEditProjectId] = useState<number | null>(null);
  const [teamUsersData, setTeamUsersData] = useState<UserTeamResponseByTeam[]>([]);
  const [totalAgents, setTotalAgents] = useState<number>(0);
  const [isProjectNameRequired, setIsProjectNameRequired] = useState<string>('');
  const [isEditing, setIsEditing] = useState(false);
  const [oldProjectName, setOldProjectName] = useState<string>('')
  const activeTheme = localStorage.getItem("kt_theme_mode_value");

  const userInfo = fetchLoginCookie();
  const decryptedRes = decryptData(userInfo);
  const userId = parseInt(decryptedRes?.id);
  const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  setProjectChange(false);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const userEmail = decryptedRes?.user;
    const activeProject = localStorage.getItem("Active-Project");

    if (activeProject) {
      const parts = activeProject.split('-');
      const projectId = parseInt(parts[0]);

      if (!isNaN(projectId) && projectId > -1) {

        onProjectIDArrival(projectId);
        getAgentsOfProject(projectId);
        getOneProject(projectId);
        setLoggedInUserId(userId);
        setLoggedInUserEmail(userEmail);
      }
    }
  }, [localStorage.getItem("Active-Project")]);

  const getOneProject = async (project_id: number) => {
    try {
      const response = await getProject(project_id);

      if (response.id) {
        setProjectName(response.project_name);
        setOldProjectName(response.project_name)
        setProjectCreatorEmail(response.created_by);
        setEditProjectId(response.id);
        getUsersOfTeam(response.team);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUsersOfTeam = async (team_id: number) => {
    try {
      const response = await getUsersByTeam(team_id);
      if (response.length) {
        setTeamUsersData(response);
      }
    } catch (error) {
      console.error(error);
    }
  }


  const getAgentsOfProject = async (project_id: number) => {
    try {
      const response = await getAgentByProject(project_id);
      if (response.length > 0) {
        const nonSystemAgents = response.filter((item) => item.created_by !== "System")
        setTotalAgents(nonSystemAgents.length);
      }
      else {
        setTotalAgents(response.length);
      }

    } catch (error) {
      console.error(error);
    }
  }


  const editMyProject = async () => {

    setIsEditing(false);
    // if (!projectName) {
    //   setIsProjectNameRequired(true);
    //   return;
    // }

    if (projectName.trim() === '') {
      setIsProjectNameRequired('Please enter a project name');
      setIsEditing(true);
      return;
    }
    else {
      setIsProjectNameRequired(' ')

      if (oldProjectName !== projectName) {

        try {
          const request: EditProjectRequest = {
            project_id: editProjectId!,
            project_name: projectName,
            modified_by: loggedInUserEmail
          };
          const response = await editProject(request);
          if (response.id) {
            setProjectChange(true)
            setOldProjectName(response.project_name)


          }
        } catch (error) {
          console.error(error);
        }

      }

    }
  }





  const removeMyProject = async () => {
    handlePopoverClose();


    if (totalAgents > 0) {
      Swal.fire({
        title: 'Alert',
        text: "First delete all agents",
        icon: 'warning',

        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

      });
    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: "This will delete the project.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await deleteProject(editProjectId!);
            if (response.id) {
              const remainingProjects = await setNextProject(userId)

              window.location.reload();
            }
          } catch (error) {
            console.error(error);
          }
        }
      });
    }
  };

  const setNextProject = async (user_id: number) => {
    try {
      const response = await getProjectsByUser(user_id);

      if (response.length > 1) {
        localStorage.setItem("Active-Project", `${response[response.length - 1].project.id}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`)
        getAgentsOfProject(response[0].project.id);
      }
      else {
        localStorage.removeItem("Active-Project")
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
    setIsProjectNameRequired('');

  };


  const handleProjectNameChanged = () => {
    setIsEditing(true);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      editMyProject(); // Save on Enter key press
    }

  };

  const handlePopoverOpen = (event) => {
    setPopoverAnchor(event.currentTarget);
    const activeProject = localStorage.getItem("Active-Project");
    if (activeProject) {
      const parts = activeProject.split('-');
      const projectId = parseInt(parts[0]);
      if (!isNaN(projectId)) {

        getAgentsOfProject(projectId);

      }
    }


  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  return (
    <div className='card mb-5 mb-xl-10' style={{ height: '105px', maxWidth: '100%', }} >

      <div className='card-body pb-0 d-flex flex-column align-items-between' style={{ height: '105px' }}>

        <div className='d-flex flex-wrap flex-sm-nowrap '>
          <div className='me-4 mb-4'>
            <div className='symbol symbol-10px symbol-lg-80px symbol-fixed position-relative'>
              <img src={project} alt='project' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1' >
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <>
                    {isEditing ? (

                      <>
                        <div>
                          <TextField
                            required
                            hiddenLabel
                            disabled={projectCreatorEmail !== loggedInUserEmail && !isSuperUser}
                            id="standard-required"
                            value={projectName}
                            onChange={handleChange}
                            onBlur={editMyProject}
                            // onKeyDown={handleKeyDown}
                            InputProps={{
                              style: {
                                fontSize: 20,
                                color: activeTheme === 'dark' ? 'white' : 'black',
                              },
                            }}
                            autoFocus
                            variant="standard"
                            sx={{
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                              },
                              "& .MuiInputBase-root": {
                                width: 180,
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#20808d"
                              },
                              fontWeight: 300
                            }}
                          />
                          {isProjectNameRequired && <p className={styles.redText}>{isProjectNameRequired}</p>}
                        </div>
                      </>
                    ) : (
                      <h2 style={{ fontWeight: 300,minWidth:'fit-content',maxWidth:'30vw' }} onClick={handleProjectNameChanged}>{projectName}</h2>
                    )}
                  </>
                  <>
                    {(projectCreatorEmail === loggedInUserEmail || isSuperUser) ?
                      (<IconButton className={styles.editicon} type='button' onClick={handleProjectNameChanged}><EditIcon fontSize='medium' /></IconButton>) : null}
                  </>

                </div>
              </div>
              <div>
                {(projectCreatorEmail === loggedInUserEmail || isSuperUser) && (
                  <>
                    <IconButton
                      type='button'
                      // className={`btn ${styles.customButton}`}
                      onClick={handlePopoverOpen}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Popover
                      open={Boolean(popoverAnchor)}
                      anchorEl={popoverAnchor}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem className='d-flex align-items-center' onClick={removeMyProject} >
                      <TrashSimple className='mr-1' size={18} weight="light" />
                      <div>Delete</div>
                      </MenuItem>
                    </Popover>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex overflow-auto ' >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link

                className={
                  `${styles.headers} nav-link text-active-primary me-4 ` +
                  (location.pathname === '/project/agents/agents' && 'active' && styles.activeTab)
                }
                to='/project/agents/agents'
              >
                <SmartToyOutlinedIcon />Agents
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `${styles.headers} nav-link text-active-primary me-6 ` +
                  (location.pathname === '/project/agents/myagents' && 'active' && styles.activeTab)
                }
                to='/project/agents/myagents'
              >
                <PrecisionManufacturingSharpIcon />My Agents
              </Link>
            </li>
          </ul>
        </div>


      </div>
    </div>
  )
}

export { ProfileHeader }













