import { Dispatch, SetStateAction } from 'react'
import { User } from '../../../../../../../api'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'

interface UsersListHeaderProps{
  setAllUsers: Dispatch<SetStateAction<User[]>>;
  setFilteredUsers: Dispatch<SetStateAction<User[]>>;
  allUsers: User[];
}
const UsersListHeader = ({setAllUsers, allUsers, setFilteredUsers}:UsersListHeaderProps) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <UsersListSearchComponent  allUsers={allUsers} setFilteredUsers={setFilteredUsers}/>
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <UsersListGrouping setAllUsers={setAllUsers} setFilteredUsers={setFilteredUsers}/> : <UsersListToolbar setAllUsers={setAllUsers} allUsers={allUsers} setFilteredUsers={setFilteredUsers}/>}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {UsersListHeader}
