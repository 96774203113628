import { FC, MutableRefObject, useMemo } from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import { KTSVG, WithChildren } from '../../../../helpers'
import { useLayout } from '../../../core'
import styles from './sidemenuStyles.module.scss'
import { Tooltip } from '@mui/material'

type Props = {

  to?: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  onclick?: () => void;
  hasBullet?: boolean;
  project_id?: number;
  anchorRef?: MutableRefObject<HTMLAnchorElement | null> | null
  isTextColorBlack?: boolean;
  userId: number;
  hoveredProject?: any
  currentActiveProject?:number
  truncate:boolean
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  onclick,
  project_id,
  anchorRef,
  isTextColorBlack = false,
  userId,
  hoveredProject,
  currentActiveProject,
  truncate
}) => {

  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const location = useLocation()
  let isActive = useMemo(() => {
    return project_id === parseInt(localStorage.getItem("Active-Project")?.split('-')[0]!) ? true : false;
  }, [project_id, localStorage.getItem("Active-Project")]);

  const isActive1 = !(location.pathname === '/recentagents' || location.pathname === '/starredagents/' || location.pathname === '/templates/' || location.pathname === '/teamagents/' || location.pathname === '/admin/analytics')

  const { config } = useLayout()
  const { app } = config
  // 
  const maxLength = 15; // Define the maximum length

  // Truncate the domain and industry_area if they exceed maxLength
  const truncatedDomain = title.length > maxLength
    ? title.substring(0, maxLength) + '...' // Add '...' if truncated
    : title;

  return (
    <div onClick={onclick} style={{ width: "99%", cursor: 'pointer' }} className={`menu-item ${styles.menuItem}`} >
      {to ? (
        <Link to={to} ref={anchorRef} style={{ textDecoration: (currentActiveProject==project_id) ? 'underline' : '', minWidth: '12.5rem', marginLeft: "7px", color: hoveredProject == project_id ? '#20808d' : 'black', width: "99%" }} className={`${styles.menuItem}`}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}

          <Tooltip title={title}><span style={{ letterSpacing: "0.4px", minWidth: '12.5rem', display: 'inline-block' ,color: hoveredProject == project_id ? '#20808d' : 'black', textDecoration: (hoveredProject == project_id || ((currentActiveProject==project_id) && isActive1) )? 'underline' : '',}} className={`menu-title ${styles.projectNames}`}>{truncate? truncatedDomain:title}</span></Tooltip>
        </Link>) : (<div className={clsx('menu-link without-sub', { active: isActive })}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
            <i className={clsx('bi fs-3', fontIcon)}></i>
          )}
          <span >{title}</span>
        </div>
      )}
      {children}
    </div>
  )
}

export { SidebarMenuItem }


