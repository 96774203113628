import { ListViewProvider, useListView } from './core/ListViewProvider'
import { QueryRequestProvider } from './core/QueryRequestProvider'
import { QueryResponseProvider } from './core/QueryResponseProvider'
import { UsersListHeader } from './components/header/UsersListHeader'
import { UsersTable } from './table/UsersTable'
import { UserEditModal } from './user-edit-modal/UserEditModal'
import { KTCard } from '../../../../../_metronic/helpers'
import { useState, useEffect } from 'react'
import { User, fetchLoginCookie, decryptData, getAllUsers } from '../../../../../api'


const UsersList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [allUsers, setAllUsers] = useState<any>([])
  const [filteredUsers, setFilteredUsers] = useState<any[]>([])

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const orgId = parseInt(decryptedRes?.org_id);

    fetchAllUsers(orgId)
  }, [])


  const fetchAllUsers = async (orgId: number) => {

    const response = await getAllUsers(orgId)
    if (response.length > 0) {
      const processedUsers = response.map((user) => ({
        id: user.id,
        name: `${user.first_name || ""} ${user.last_name || ""}`,
        email: user.email,
        role: user.is_superuser ? "Global admin" : user.is_staff ? "Application admin" : "User",
        date_joined: user.date_joined
          ? new Date(user.date_joined).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
          : 'No Data',

      }));
      setAllUsers(processedUsers);
      setFilteredUsers(processedUsers)
    }
    setIsLoading(false)
  }

  return (
    <>
      <KTCard>
        <UsersListHeader setAllUsers={setAllUsers} allUsers={allUsers} setFilteredUsers={setFilteredUsers}/>
        <UsersTable setAllUsers={setAllUsers} isLoading={isLoading} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers}/>
      </KTCard>
      {/* {itemIdForUpdate !== undefined && <UserEditModal />} */}
    </>
  )
}

const UsersListWrapper = () => (

  <ListViewProvider>
    <UsersList />
  </ListViewProvider>

)

export { UsersListWrapper }
