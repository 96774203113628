import {useQuery} from 'react-query'
import {UserEditModalForm} from './UserEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
// import {getUserById} from '../core/_requests'
import {Dispatch,SetStateAction} from 'react'
import { User } from '../../../../../../api'

interface Props{
  close:Dispatch<SetStateAction<boolean>>;
  setAllUsers:  Dispatch<SetStateAction<User[]>>;
  setFilteredUsers:  Dispatch<SetStateAction<User[]>>;
  allUsers:User[];
}
const UserEditModalFormWrapper = ({close, setAllUsers, setFilteredUsers,allUsers}:Props) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  // const {
  //   isLoading,
  //   data: user,
  //   error,
  // } = useQuery(
  //   `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
  //   () => {
  //     return getUserById(itemIdForUpdate)
  //   },
  //   {
  //     cacheTime: 0,
  //     enabled: enabledQuery,
  //     onError: (err) => {
  //       setItemIdForUpdate(undefined)
  //       console.error(err)
  //     },
  //   }
  // )

  
    return <UserEditModalForm setAllUsers={setAllUsers} allUsers={allUsers} setFilteredUsers={setFilteredUsers} close={close}/>

}

export {UserEditModalFormWrapper}
