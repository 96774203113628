import { Stack, PrimaryButton } from "@fluentui/react";
import { ErrorCircle24Regular } from "@fluentui/react-icons";
import clsx from 'clsx';
import styles from './Answer.module.scss'

interface Props {
    error: string;
    onRetry: () => void;
    AgentName:string | undefined;
}

export const AnswerError = ({ error, onRetry, AgentName }: Props) => {
   
    return (
        <Stack verticalAlign="space-between" className={styles.m10}>
         
            <Stack.Item  className={clsx(
                'p-5 rounded',
                `bg-light-primary`,
                'text-dark ',
                `text-start`, 'mb-2', 'mw-lg-500px'
            )}>
                <ErrorCircle24Regular className="mb-1" aria-hidden="true" aria-label="Error icon" primaryFill="red" />
                <p className={styles.answerText}>Session timed out. Please retry</p>
                <PrimaryButton className={`btn ${styles.RetryButton}`}  onClick={onRetry} text="Retry" />
            </Stack.Item>


        </Stack>
    );
};
