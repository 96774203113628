import { FC, FormEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import { fetchLoginCookie, decryptData, createUser, User, getAllUsers } from '../../../../../../api';
import Swal from 'sweetalert2';
import { Dispatch, SetStateAction } from 'react'

interface Props {
  close: Dispatch<SetStateAction<boolean>>;
  setAllUsers: Dispatch<SetStateAction<User[]>>;
  setFilteredUsers: Dispatch<SetStateAction<User[]>>;
  allUsers: User[];
}
const UserEditModalForm: FC<Props> = ({ close, setAllUsers, setFilteredUsers, allUsers }: Props) => {


  const [email, setEmail] = useState<string>('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedRole, setSelectedRole] = useState('User');
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isEmptyFirstName, setIsEmptyFirstName] = useState(false);
  const [isEmptyLastName, setIsEmptyLastName] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [warnings, setWarnings] = useState<string[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [isSuper, setIsSuper] = useState<boolean>(false);
  const [orgId, setOrgId] = useState<number>(-1);
  const [DuplicateEmail, setIsDuplicateEmail] = useState<boolean>(false);


  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const org_id = parseInt(decryptedRes?.org_id);

    if (org_id) {
      setOrgId(org_id)
    }
    if (isSuperUser) {
      setIsSuper(true)
    }

  }, [])

  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    setFirstName(value);
  };

  const handleLastNameChange = (e) => {
    const { value } = e.target;
    setLastName(value);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
    event.preventDefault();

    if (email.trim() === '') {
      setIsEmptyEmail(true);
    } if (password.trim() === '') {
      setIsEmptyPassword(true);
    } if (first_name.trim() === '') {
      setIsEmptyFirstName(true);
    }
    if (last_name.trim() === '') {
      setIsEmptyLastName(true);
    }
    else {
      setIsEmptyEmail(false);
      setIsEmptyPassword(false);
      setIsEmptyLastName(false);
      setIsEmptyFirstName(false);
      setIsValidEmail(validateEmail(email));
      setIsDuplicateEmail(isDuplicateEmail(email))
      setWarnings(validatePassword(password));
    }

    if (!isEmptyEmail && !isEmptyPassword && isValidEmail && selectedRole && !(validatePassword(password).length > 0) && first_name.length > 0 && last_name.length > 0 && !isEmptyFirstName && !isEmptyLastName) {
      const response = await createUser(first_name, last_name, email, password, selectedRole)
      if (response) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'User Created successfully!',
          confirmButtonText: 'Ok'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const allusers = await getAllUsers(orgId)
            if (allusers.length > 0) {

              const processedUsers = allusers.map((user) => ({
                id: user.id,
                organization:user.organization,
                name: `${user.first_name || ""} ${user.last_name || ""}`,
                email: user.email,
                role: user.is_superuser ? "Global admin" : user.is_staff ? "Application admin" : "User",
                date_joined: user.date_joined ? new Date(user.date_joined).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
                  : 'No Data',

              }));

              setAllUsers(processedUsers);
              setFilteredUsers(processedUsers);
            }
            close(false)
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to Create a User'
        });
      };
    }
  }

  const cancel = () => {
    setEmail("")
    setFirstName("")
    setLastName("")
    setPassword("")
    setSelectedRole("User")
    close(false)
  }

  const validateEmail = (email: string) => {
    // Regular expression to check for a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };
  const isDuplicateEmail = (email: string) => {
    const emailExists = allUsers.some(user => user.email === email);
    if (emailExists) {
      return true;
    }
    else {
      return false
    }
  };

  const handleEmailChange = (e: { target: { value: any } }) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

  };
  function validatePassword(password: string) {
    const warnings: string[] = [];

    // Check for a minimum length of 8 characters
    if (password.length < 8) {
      warnings.push("Password must be at least 8 characters long.");
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      warnings.push("Password must contain at least one uppercase letter.");
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      warnings.push("Password must contain at least one lowercase letter.");
    }

    // Check for at least one digit (number)
    if (!/\d/.test(password)) {
      warnings.push("Password must contain at least one digit.");
    }


    return warnings;
  }

  const handlePasswordChange = (e: { target: { value: any } }) => {
    setPassword(e.target.value)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <div className='row'>
              <div className='col-md-6'>
                {/* Add first_name input */}
                <label className='required fw-normal fs-6 mb-2'>First Name</label>
                <input
                  placeholder='First Name'
                  onChange={handleFirstNameChange}
                  value={first_name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 fw-light',
                  )}
                  type='text'
                  name='first_name'
                  autoComplete='off'
                />
                {isEmptyFirstName && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      First Name is required
                    </div>
                  </div>
                )}
              </div>
              <div className='col-md-6'>
                {/* Add last_name input */}
                <label className='required fw-normal fs-6 mb-2'>Last Name</label>
                <input
                  placeholder='Last Name'
                  onChange={handleLastNameChange}
                  value={last_name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 fw-light',
                  )}
                  type='text'
                  name='last_name'
                  autoComplete='off'
                />
                {isEmptyLastName && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      Last Name is required
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-normal fs-6 mb-2'>Email</label>

            <input
              placeholder='Email'
              onChange={handleEmailChange}
              value={email}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0 fw-light',
              )}
              type='email'
              name='email'
              autoComplete='off'

            />
            {isEmptyEmail && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Email is required
                </div>
              </div>
            )}
            {!isValidEmail && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Invalid Email
                </div>
              </div>
            )}
            {DuplicateEmail && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  User with this email already exists
                </div>
              </div>
            )}

          </div>

          <label className='required fw-normal fs-6 mb-2'>Password</label>

          <div className='form-control form-control-solid d-flex p-0'>
            <input
              placeholder='Password'
              onChange={handlePasswordChange}
              value={password}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0 fw-light',
              )}
              type={isPasswordVisible ? 'text' : 'password'}
              name='password'
              autoComplete='off'

            />
            <button
              type='button'
              className='btn btn-sm '
              onClick={togglePasswordVisibility}
            >
              {isPasswordVisible ? <VisibilityOffSharpIcon /> : <VisibilitySharpIcon />}
            </button></div>
          {isEmptyPassword && (
            <div className='fv-plugins-message-container'>
              <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                Password is required
              </div>
            </div>
          )}
          {
            warnings.length > 0 && warnings.map((warning, index) => (
              <div key={index} className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  {warning}
                </div>
              </div>
            ))
          }

          <label className='required fw-normal fs-6 mb-2 mt-4'>Role</label>
          <div className='d-flex fv-row'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                disabled={!isSuper}
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='Global admin'
                id='kt_modal_update_role_option_0'
                onChange={() => setSelectedRole('Global admin')}
                checked={selectedRole === 'Global admin'}
              />
              <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                <div className='fw-normal text-gray-800'>Global Admin</div>
                <div className='text-gray-600'>
                  Best for business owners and company administrators
                </div>
              </label>
            </div>
          </div>
          <div className='separator separator-dashed my-5'></div>
          <div className='d-flex fv-row'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='Application admin'
                id='kt_modal_update_role_option_1'
                onChange={() => setSelectedRole('Application admin')}
                checked={selectedRole === 'Application admin'}
              />
              <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                <div className='fw-normal text-gray-800'>Application Admin</div>
                <div className='text-gray-600'>
                  Best for managing users and teams
                </div>
              </label>
            </div>
          </div>
          <div className='separator separator-dashed my-5'></div>
          <div className='d-flex fv-row'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input me-3'
                name='role'
                type='radio'
                value='User'
                id='kt_modal_update_role_option_2'
                onChange={() => setSelectedRole('User')}
                checked={selectedRole === 'User'}
              />
              <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                <div className='fw-normal text-gray-800'>User</div>
                <div className='text-gray-600'>
                  Best for interacting with app features & functionalities
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'

          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
          >
            <span className='indicator-label'>Submit</span>

          </button>
        </div>
      </form>
    </>
  )
}

export { UserEditModalForm }
