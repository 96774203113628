// import {
//   FC,
//   useState,
//   useEffect,
//   createContext,
//   useContext,
//   useRef,
//   Dispatch,
//   SetStateAction,
// } from 'react'
// import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
// import {AuthModel, UserModel} from './_models'
// import * as authHelper from './AuthHelpers'
// import {getUserByToken} from './_requests'
// import {WithChildren} from '../../../../_metronic/helpers'

// type AuthContextProps = {
//   auth: AuthModel | undefined
//   saveAuth: (auth: AuthModel | undefined) => void
//   currentUser: UserModel | undefined
//   setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
//   logout: () => void
// }

// const initAuthContextPropsState = {
//   auth: authHelper.getAuth(),
//   saveAuth: () => {},
//   currentUser: undefined,
//   setCurrentUser: () => {},
//   logout: () => {},
// }

// const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

// const useAuth = () => {
//   return useContext(AuthContext)
// }

// const AuthProvider: FC<WithChildren> = ({children}) => {
//   const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
//   const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
//   const saveAuth = (auth: AuthModel | undefined) => {
//     setAuth(auth)
//     if (auth) {
//       authHelper.setAuth(auth)
//     } else {
//       authHelper.removeAuth()
//     }
//   }

//   const logout = () => {
//     saveAuth(undefined)
//     setCurrentUser(undefined)
//   }

//   return (
//     <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
//       {children}
//     </AuthContext.Provider>
//   )
// }

// const AuthInit: FC<WithChildren> = ({children}) => {
//   const {auth, logout, setCurrentUser} = useAuth()
//   const didRequest = useRef(false)
//   const [showSplashScreen, setShowSplashScreen] = useState(true)
//   // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
//   useEffect(() => {
//     const requestUser = async (apiToken: string) => {
//       try {
//         if (!didRequest.current) {
//           const {data} = await getUserByToken(apiToken)
//           if (data) {
//             setCurrentUser(data)
//           }
//         }
//       } catch (error) {
//         console.error(error)
//         if (!didRequest.current) {
//           logout()
//         }
//       } finally {
//         setShowSplashScreen(false)
//       }

//       return () => (didRequest.current = true)
//     }

//     if (auth && auth.api_token) {
//       requestUser(auth.api_token)
//     } else {
//       logout()
//       setShowSplashScreen(false)
//     }
//     // eslint-disable-next-line
//   }, [])

//   return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
// }

// export {AuthProvider, AuthInit, useAuth}

import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
// import { AuthModel, UserModel } from './_models'
// import * as authHelper from './AuthHelpers'
// import { getUserByToken } from './_requests'
import Cookies from 'universal-cookie';
import { WithChildren } from '../../../../_metronic/helpers'
import { userLogout, decryptData, fetchLoginCookie, encryptData, getUser } from '../../../../api';
import { useNavigate } from 'react-router';



type AuthContextProps = {
  currentUser: boolean
  setCurrentUser: Dispatch<SetStateAction<boolean>>
  logout: () => void
}

const initAuthContextPropsState = {
  currentUser: false,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(false)
  const logout = async () => {
    setCurrentUser(false)
    localStorage.clear()
    let result = await userLogout();
  }

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser, currentUser } = useAuth()
  const cookies = new Cookies();
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  const [cookieExpired, setCookieExpired] = useState(false); // Initialize with false
  const navigate = useNavigate()

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = () => {


      try {
   
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const sessionid = cookies.get("sessionid");
        const userId = parseInt(decryptedRes?.id);
       
        if (sessionid && userId) {
          setCurrentUser(true);
          setShowSplashScreen(false)
        }
        else if (sessionid && !userId) {
            getSSOUserInfo(); //execute this only in case of sso logged in user 
            
        }
        else {
          setCurrentUser(false);
          setShowSplashScreen(false)
        }

      } catch (error) {

        console.error(error)
        logout()
      }
     
    }
    requestUser()

  }, [cookieExpired])

  const interval = setInterval(() => {
    const userInfo = fetchLoginCookie();
    const sessionid = cookies.get("sessionid");
    if (!userInfo || !sessionid) {
      // If cookie is expired or not existent, log the user out
      setCookieExpired(true);
      clearInterval(interval); // Stop the interval
    }
  }, 60000); // Check every minute

  
  const getSSOUserInfo = async () => {
    try {
      let result = await getUser();
      result = JSON.parse(JSON.stringify(result));
      if (result.user) {
        const encryptedRes = encryptData(result);
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // 12 hour from now
        cookies.set("__info", encryptedRes, { expires: expirationDate });
        setCurrentUser(true);
        setShowSplashScreen(false)
      }
    } catch (e) {
        cookies.remove("sessionid");
        navigate('/auth');
        window.location.reload();
    }
  };


  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>

}
export { AuthProvider, AuthInit, useAuth }


// import { FC, createContext, useContext, useState, useEffect } from 'react';
// import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
// import { WithChildren } from '../../../../_metronic/helpers';
// import Cookies from 'universal-cookie';
// import { userLogout } from '../../../../api';

// type AuthContextProps = {
//   currentUser: boolean;
//   setcurrentUser: React.Dispatch<React.SetStateAction<boolean>>;
//   logout: () => void; // Add the logout function to the type definition
// };

// const AuthContext = createContext<AuthContextProps | undefined>(undefined);

//  const useAuth = (): AuthContextProps => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

//  const AuthProvider: FC<WithChildren> = ({ children }) => {
//   const cookies = new Cookies();
//   const [currentUser, setcurrentUser] = useState(false);

//   const logout = async () => {
//     let result = await userLogout();
//         // window.location.reload();
//   };

//   useEffect(() => {
//     const userInfo = cookies.get("_info");
//     const sessionid = cookies.get("sessionid");
//     const userId = parseInt(userInfo?.id);
//     if (userId && sessionid) {
//       setcurrentUser(true);
//     } else {
//       setcurrentUser(false);
//     }
//   }, []);

//   return (
//     <AuthContext.Provider value={{ currentUser, setcurrentUser, logout }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// const AuthInit: FC<WithChildren> = ({ children }) => {
//   const { currentUser, setcurrentUser, logout } = useAuth();
//   const cookies = new Cookies();

//   useEffect(() => {
//     const userInfo = cookies.get("_info");
//     const sessionid = cookies.get("sessionid");
//     if (!userInfo || !sessionid) {
//       setcurrentUser(false);
//     }
//   }, []);

//   return currentUser ? <>{children}</> : <LayoutSplashScreen />;
// };

// export { AuthProvider, AuthInit, useAuth };
