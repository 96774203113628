/* eslint-disable jsx-a11y/anchor-is-valid */
import Cookies from "universal-cookie";
import { useEffect, useState } from 'react'
import { userLogin, LoginRequest, encryptData, VerifyTokenRequest, verifyToken, LoginResponse, getTokenRequest, generateOTP, getOAuthFlags, FeatureFlag } from "../../../../api";
import dsd_icon from '../../../../media_dsd/dsd_icon.png'
import styles from './Login.module.css';
import OtpInput from 'react-otp-input';
import { Button } from "@mui/material";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const cookies = new Cookies();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [InvalidPasswordAlert, setInvalidPasswordAlert] = useState<number>(0);
  const [userAlert, setUserAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [InvalidEmailAlert, setInvalidEmailAlert] = useState<number>(0);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [otp, setOtp] = useState('');
  const [loginData, setLoginData] = useState<LoginResponse[]>([]);
  const [loadingOTP, setLoadingOTP] = useState(false);
  const [dontAskAgain, setDontAskAgain] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showResendLink, setShowResendLink] = useState(false);
  const [showGoogleAuth, setShowGoogleAuth] = useState(false)
  const [showLinkedInAuth, setShowLinkedInAuth] = useState(false)
  const [showGitHubAuth, setShowGitHubAuth] = useState(false)
  const [showSSOAuth, setShowSSOAuth] = useState(false)

  const disable: boolean | undefined = InvalidEmailAlert === 2 || InvalidPasswordAlert === 2
  const isDeviceRecognized = cookies.get("_ddtf") !== undefined;
  useEffect(() => {
    fetchOAuthFlags()
  }, [])
  const makeLoginRequest = async () => {
    let item = { email, password };
    setUserAlert(false);
    setInvalidPasswordAlert(0);
    setInvalidEmailAlert(0)
    setLoading(true)



    try {
      const request: LoginRequest = {
        email: item.email,
        password: item.password,
        deviceId: isDeviceRecognized
      };
      let result = await userLogin(request);
      result = JSON.parse(JSON.stringify(result));

      if (result.message == "2FA not required" || result.message == "2FA disabled") {
        if (result.data) {
          setLoginData(result.data);
          const encryptedRes = encryptData(loginData);
          const expirationDate = new Date();
          expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // 12 hour from now
          cookies.set("__info", encryptedRes, { expires: expirationDate });
          setInvalidPasswordAlert(2);
          setInvalidEmailAlert(2)
          window.location.reload()
        }

      }

      else if (result.message == "2FA required") {
        if (isDeviceRecognized) {
          if (result.data) {
            setLoginData(result.data);
            const encryptedRes = encryptData(loginData);
            const expirationDate = new Date();
            expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // 12 hour from now
            cookies.set("__info", encryptedRes, { expires: expirationDate });
            setInvalidPasswordAlert(2);
            setInvalidEmailAlert(2)
            window.location.reload()
          }
        }
        else {
          try {
            const otpRequest: getTokenRequest = {
              email: item.email,
            };
            let otpResult = await generateOTP(otpRequest);
            otpResult = JSON.parse(JSON.stringify(otpResult));
            if (otpResult.message === 'MFA token generated and sent') {
              setIsLoggedIn(true);
            } else {
              setLoading(false);
              setDontAskAgain(false);
            }
          }
          catch (e) {
            console.error(e);
          }

        }
      }
      else if (result.error == "User does not exists.") {
        setUserAlert(true);
        setLoading(false)
      }
      else if (result.error == "Incorrect Password.") {
        setInvalidPasswordAlert(1);
        setLoading(false)
      }

      else if (result.error == "status code 500: Internal Server Error") {
        setInvalidEmailAlert(1);
        setLoading(false)
      }

    } catch (e) {
      console.error(e);
    }
  };
  // Handle Enter key press on email and password fields
  const handleEnterKey = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !disable) {
      makeLoginRequest();
    }
  };

  const handleGoogleAuth = () => {
    window.location.href = '/accounts/google/login/?next=/';
  };

  const handleLinkedInAuth = () => {
    window.location.href = '/auth/linkedin/login/';
  };

  const handleGitHubAuth = () => {
    window.location.href = '/accounts/github/login/?next=/';
  };

  const handleMicrosoftSSOLogin = () => {
    window.location.href = '/oauth2/login';
  };
  const handleResetPassword = () => {
    window.location.href = '/accounts/password/reset/';

  };
  const makeVerificationRequest = async () => {
    let item = { email, password };
    setLoadingOTP(true);
    setShowResendLink(false);
    setErrorMessage("");
    if (dontAskAgain) {
      const newDeviceIdentifier = generateUniqueDeviceIdentifier();
      cookies.set("_ddtf", newDeviceIdentifier, { maxAge: 30 * 24 * 60 * 60 });
    }

    try {
      const request: VerifyTokenRequest = {
        email: item.email,
        password: item.password,
        token: otp
      };
      let result = await verifyToken(request);
      result = JSON.parse(JSON.stringify(result));
      if (result.message == 'User logged in successfully') {
        if (result.data) {
          setLoginData(result.data);
          const encryptedRes = encryptData(loginData);
          const expirationDate = new Date();
          expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // 12 hour from now
          cookies.set("__info", encryptedRes, { expires: expirationDate });
          setInvalidPasswordAlert(2);
          setInvalidEmailAlert(2)
          window.location.reload()
        }
      }
      else if (result.error == 'Invalid token.') {
        setErrorMessage("Invalid OTP. Please try again.")
        setLoadingOTP(false);

      }
      else if (result.error == 'Token has expired.') {
        setErrorMessage("The OTP has expired. Please request a new OTP.")
        setLoadingOTP(false);
        setShowResendLink(true);

      }
      else {
        setLoadingOTP(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const generateUniqueDeviceIdentifier = () => {
    const deviceId = Math.random().toString(36).substring(2, 10);
    return deviceId;
  };

  const handleResendOTP = async () => {
    try {
      setErrorMessage(null);
      setShowResendLink(false);
      setOtp('');
      const otpRequest: getTokenRequest = {
        email: email,
      };
      let otpResult = await generateOTP(otpRequest);
      otpResult = JSON.parse(JSON.stringify(otpResult));
      if (otpResult.message === 'MFA token generated and sent') {
        setErrorMessage(null);
      } else {
        setErrorMessage('Error generating OTP. Please try again.');
      }
    } catch (e) {
      console.error(e);
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  const setDataFlags = (data: FeatureFlag[]) => {
    data.forEach((item: FeatureFlag) => {
      if (item.feature === "ms_sso-auth") {
        setShowSSOAuth(item.flag);
      } else if (item.feature === "google-auth") {
        setShowGoogleAuth(item.flag);
      } else if (item.feature === "linkedin-auth") {
        setShowLinkedInAuth(item.flag);
      } else if (item.feature === "github-auth") {
        setShowGitHubAuth(item.flag);
      }
    });
  };

  const fetchOAuthFlags = async () => {
    try {
      const response = await getOAuthFlags()
      if (response) {
        setDataFlags(response)
      }
    } catch (e) {
      console.error(e)
    }
  }

  if (!isLoggedIn) {
    return (
      <div
        className='form w-100'
      >

        {/* begin::Heading */}
        <div className='text-center mb-7'>
          <img className={styles.imgHeight} src={dsd_icon} alt="DSD icon" />
          <h1 className='mb-3 mt-8' style={{ color: '#4285f4' }}>Sign in to Ejento AI</h1>
        </div>
        {/* begin::Heading */}


        {
          showGoogleAuth &&
          <div className='d-grid mb-5'>
            {/* Render your email/password login form here */}
            <Button variant="outlined" sx={{ height: '40px', textTransform: 'none', fontSize: '14px', color: '#4285f4' }} onClick={handleGoogleAuth} startIcon={<img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              height='30'
              width='30'
            />}>
              Continue with Google
            </Button>
          </div>
        }
        <div className='d-flex flex-row mb-10 align-items-center'>
          {/* Render your email/password login form here */}
          {
            showLinkedInAuth && <Button variant="outlined" sx={{ height: '40px', textTransform: 'none', fontSize: '14px', width: '100%', color: '#4285f4', marginRight: (showGitHubAuth && showLinkedInAuth) ? 1 : 0 }} onClick={handleLinkedInAuth} startIcon={<img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-1.svg')}
              height='30'
              width='30'
            />}>
              LinkedIn
            </Button>
          }
          {
            showGitHubAuth && <Button variant="outlined" sx={{ height: '40px', textTransform: 'none', fontSize: '14px', width: '100%', color: '#4285f4' }} onClick={handleGitHubAuth} startIcon={<img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/github-1.svg')}
              height='30'
              width='30'
            />}>
              GitHub
            </Button>
          }

        </div>
        {/* <div className='d-grid mb-10'> */}
        {/* Render your email/password login form here */}

        {/* </div> */}
        {
          showSSOAuth && <div className='d-flex justify-content-start mb-5'>
            <Link
              component="button"
              onClick={handleMicrosoftSSOLogin}
              underline="hover"
              color={'#4285f4'}
            >
              <ArrowRightAltIcon /> You can also continue with Microsoft SSO
            </Link>    </div>
        }
        {
          (showGoogleAuth || showLinkedInAuth || showGitHubAuth || showSSOAuth) && <Divider sx={{ bgcolor: "#555555", height: 2 }} />
        }

        {/* </div> */}
        <div className='row g-3 mb-9'>
          <div className='col-md-6'>
          </div>
          <div className='col-md-6'>
          </div>
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 text-dark'>Email</label>
          <input
            placeholder='Email'
            id="standard-required"
            onChange={e => setEmail(e.target.value)}
            className={`form-control bg-transparent ${InvalidEmailAlert === 1 ? 'is-invalid' : InvalidEmailAlert === 2 ? 'is-valid' : ''}`}
            type='email'
            name='email'
            autoComplete='off'
            value={email}
            onKeyDown={handleEnterKey}
          />
          {InvalidEmailAlert === 1 && (
            <div className='fv-plugins-message-container'>
              <span className={styles.alertText} role='alert'>Invalid credential. Please enter email correctly.</span>

            </div>
          )}
          {userAlert && (
            <div className='fv-plugins-message-container'>
              <span className={styles.alertText} role='alert'>User does not exist. Please enter email correctly.</span>

            </div>

          )}
        </div>
        <div className='fv-row mb-5'>
          <label className='form-label text-dark fs-6 mb-0' >Password</label>
          <input
            required
            id="outlined-password-input"
            type="password"
            placeholder="Password"
            name="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            className={`form-control bg-transparent ${InvalidPasswordAlert === 1 ? 'is-invalid' : InvalidPasswordAlert === 2 ? 'is-valid' : ''}`}
            value={password}
            onKeyDown={handleEnterKey}
          />

          {InvalidPasswordAlert === 1 && (
            <div className='fv-plugins-message-container'>
              <span className={styles.alertText} role='alert'>Invalid credential. Please enter password correctly.</span>

            </div>
          )}

        </div>
        <div className='d-flex justify-content-start'>
          <Link
            component="button"
            onClick={handleResetPassword}
            underline="always"
            color={'#4285f4'}
          >
            Forgot password?

          </Link>
        </div>

        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
          <div />
        </div>
        <div className='d-grid mb-10' onClick={makeLoginRequest}>
          <button
            className='btn btn-primary'
            disabled={disable}
          >
            {!loading && <span className='indicator-label'>Continue with email</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>

    )
  }
  // }
  else {
    return (
      <div className={styles.container}>
        <div className='form'>
          <div className='text-center mb-7'>
            <img className={styles.formIcon} src={dsd_icon} alt="DSD icon" />
            <h1 className='text-dark fw-bolder mb-3 mt-4'>Enter OTP</h1>
            <p>We have sent you an email. Enter the code here:</p>
          </div>


          <div className='fv-row mb-8'>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span> </span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  className={styles.otpInput}
                />

              )}
            />
          </div>
          {errorMessage && (
            <div className='fv-plugins-message-container' >
              <div className={styles.errorMessage}>
                <span className={styles.alertText} role='alert'>{errorMessage}</span>
                {showResendLink && (
                  <div className={styles.errorMessage}>
                    <span onClick={handleResendOTP} className={styles.resendOtp}>
                      Resend OTP
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className='form-check mb-3'>
            <input
              type='checkbox'
              className='form-check-input'
              id='dontAskAgainCheckbox'
              checked={dontAskAgain}
              onChange={() => setDontAskAgain(!dontAskAgain)}
            />
            <label className='form-check-label' htmlFor='dontAskAgainCheckbox'>
              Don't ask again for 30 days
            </label>
          </div>

          <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
            <div />
          </div>
          <div className='d-grid mb-10' onClick={makeVerificationRequest}>
            <button
              className='btn btn-primary'
            >
              {!loadingOTP && <span className='indicator-label'>Verify</span>}
              {loadingOTP && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>

      </div>
    );
  }
}
