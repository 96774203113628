import { Table } from "./table/Table";
import { Graph } from "./graph/graph";
import { TopPanel } from "./topPanel/TopPanel";

export const Analytics = () => {
  return (
    <div className={`m-2`}>
      <TopPanel></TopPanel>
      <Graph></Graph>
      <Table></Table>
    </div>
  );
}