/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, FormEvent, Dispatch, SetStateAction } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import clsx from 'clsx'
import { decryptData, editUser, fetchLoginCookie, deleteAUser,  getAUser, getAllUsers} from '../../../../../../../api';
import "./UserActionsCell.css"
import { User } from '../../core/_models';

type Props = {
  id?: number
  setFilteredUsers: Dispatch<SetStateAction<any[]>>;
  setAllUsers: Dispatch<SetStateAction<any[]>>;
  user: User;
}

const UserActionsCell: FC<Props> = ({ id, setFilteredUsers, setAllUsers, user}) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('User');
  const [isLoading, setIsLoading] = useState(false);
  const [first_name, setFirstName] = useState<string>('');
  const [last_name, setLastName] = useState<string>('');
  const [isEmptyFirstName, setIsEmptyFirstName] = useState(false);
  const [isEmptyLastName, setIsEmptyLastName] = useState(false);
  const [isSuper, setIsSuper] = useState<boolean>(false);
  const userInfo = fetchLoginCookie();
  const decryptedRes = decryptData(userInfo);
  const orgId = parseInt(decryptedRes?.org_id);
  
  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";

    if(isSuperUser){
      setIsSuper(true)
    }
    MenuComponent.reinitialization()
  }, [])

  const handleClose = () => setShow(false);
  
  const handleShow = () => {
    setShow(true);
    setInitialData()
   }

  const setInitialData = async () => {
    if (id){
      setIsLoading(true)
      try{

        const response = await getAUser(id);
      if (response){
        setFirstName(response[0].first_name || "none")
        setLastName(response[0].last_name || "none")
        setEmail(response[0].email)
        setSelectedRole(response[0].is_superuser ? "Global admin" : response[0].is_staff ? "Application admin" :  "User")
      }
      setIsLoading(false)
      }catch{
        setIsLoading(false)
        console.error("User not found!")
      }
    }
  }

  const showDeleteConfirmation = async (id: number | undefined) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete User!',
    });

    if (result.isConfirmed) {
      deleteUser(id);
    }
  };

  const deleteUser =  async (id: number | undefined) => {
    if(id){
      const response = await deleteAUser(id)
      if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'User Deleted successfully!',
        confirmButtonText: 'Ok'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const allUsers = await getAllUsers(orgId)
          if (allUsers.length > 0) {
            const processedUsers = allUsers.map((user) => ({
              id: user.id,
              name: `${user.first_name || ""} ${user.last_name || ""}`,
              email: user.email,
              role: user.is_superuser ? "Global admin" : user.is_staff ? "Application admin" : "User",
              date_joined: user.date_joined
                ? new Date(user.date_joined).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
                : 'No Data',
      
            }));
            setAllUsers(processedUsers);
            setFilteredUsers(processedUsers)
          }
          handleClose()
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to Create a User'
      });
    };
  }
}


  async function handleSubmit(): Promise<void> {

    if (first_name.trim() === '') {
      setIsEmptyFirstName(true);
    }
    if (last_name.trim() === '') {
      setIsEmptyLastName(true);
    }
    else {
      setIsEmptyLastName(false);
      setIsEmptyFirstName(false);
    }

    if (selectedRole && first_name.length>0 && last_name.length>0 && !isEmptyFirstName && !isEmptyLastName && id) {

     const response = await editUser(id,first_name,last_name,email,selectedRole)
     if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'User Edited successfully!',
        confirmButtonText: 'Ok'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const allUsers = await getAllUsers(orgId)
          if (allUsers.length > 0) {
            const processedUsers = allUsers.map((user) => ({
              id: user.id,
              name: `${user.first_name || ""} ${user.last_name || ""}`,
              email: user.email,
              role: user.is_superuser ? "Global admin" : user.is_staff ? "Application admin" : "User",
              date_joined: user.date_joined
                ? new Date(user.date_joined).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
                : 'No Data',
      
            }));
            setAllUsers(processedUsers);
            setFilteredUsers(processedUsers)
          }
          handleClose()
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to Create a User'
      });
    };
    }
  }



  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    setFirstName(value);
  };

  const handleLastNameChange = (e) => {
    const { value } = e.target;
    setLastName(value);
  };



  const cancel = () => {
    setFirstName("")
    setLastName("")
    setSelectedRole("User")
    handleClose()
  }
  return (
    <>
      <a
        href='#'
        className={!(user.role === "Global admin") ? 'btn btn-light btn-active-light-primary btn-sm': "btn btn-light disabled btn-sm"}
        data-kt-menu-trigger={!(user.role === "Global admin") ?'click': ""}
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-light fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={handleShow}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={() => showDeleteConfirmation(id)}
          >
            Delete
          </a>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      <Modal

        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Edit User</Modal.Title>
        </Modal.Header>

        {isLoading?<div className='loader'><CircularProgress/></div>:<Modal.Body >
          <form id='kt_modal_add_user_form' className='form' noValidate>
            {/* begin::Scroll */}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_modal_add_user_header'
              data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
              data-kt-scroll-offset='300px'
            >
            
            <div className='fv-row mb-7'>
            <div className='row'>
              <div className='col-md-6'>
                {/* Add first_name input */}
                <label className='required fw-normal fs-6 mb-2'>First Name</label>
                <input
                  placeholder='First Name'
                  onChange={handleFirstNameChange}
                  value={first_name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 fw-light',
                  )}
                  type='text'
                  name='first_name'
                  autoComplete='off'
                />
                {isEmptyFirstName && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  First Name is required
                </div>
              </div>
            )}
              </div>
              <div className='col-md-6'>
                {/* Add last_name input */}
                <label className='required fw-normal fs-6 mb-2'>Last Name</label>
                <input
                  placeholder='Last Name'
                  onChange={handleLastNameChange}
                  value={last_name}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 fw-light',
                  )}
                  type='text'
                  name='last_name'
                  autoComplete='off'
                />
                {isEmptyLastName && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Last Name is required
                </div>
              </div>
            )}
              </div>
            </div>
          </div>

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-normal fs-6 mb-2'>Email</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Email'
                  disabled
                  value={email}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0 text-gray-400 fw-light',
                  )}
                  type='email'
                  name='email'
                  autoComplete='off'
                />
                {/* end::Input */}

              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              {/* begin::Input group */}
              <label className='required fw-normal fs-6 mb-2 mt-4'>Role</label>
            <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  disabled={!isSuper}
                  className='form-check-input me-3'
                  name='role'
                  type='radio'
                  value='Global admin'
                  id='kt_modal_update_role_option_0'
                  onChange={() => setSelectedRole('Global admin')} 
                  checked={selectedRole === 'Global admin'} 
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                  <div className='fw-normal text-gray-800'>Global Admin</div>
                  <div className='text-gray-600'>
                    Best for business owners and company administrators
                  </div>
                </label>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  name='role'
                  type='radio'
                  value='Application admin'
                  id='kt_modal_update_role_option_1'
                  onChange={() => setSelectedRole('Application admin')}
                  checked={selectedRole === 'Application admin'}
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_1'>
                  <div className='fw-normal text-gray-800'>Application Admin</div>
                  <div className='text-gray-600'>
                  Best for managing users and teams
                  </div>
                </label>
              </div>
            </div>
            <div className='separator separator-dashed my-5'></div>
            <div className='d-flex fv-row'>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  className='form-check-input me-3'
                  name='role'
                  type='radio'
                  value='User'
                  id='kt_modal_update_role_option_2'
                  onChange={() => setSelectedRole('User')} 
                  checked={selectedRole === 'User'}
                />
                <label className='form-check-label' htmlFor='kt_modal_update_role_option_2'>
                  <div className='fw-normal text-gray-800'>User</div>
                  <div className='text-gray-600'>
                  Best for interacting with app features & functionalities
                  </div>
                </label>
              </div>
            </div>
          </div>
          </form>
        </Modal.Body>}
        <Modal.Footer>
          <div className='text-center'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3'
              data-kt-users-modal-action='cancel'

            >
              Discard
            </button>

            <button
              className='btn btn-primary'
              onClick={handleSubmit}
            >
              <span className='indicator-label'>Submit</span>

            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { UserActionsCell }
