import styles from './PrivacyPolicy.module.scss'
import privacyImg from '../../../media_dsd/vecteezy_documents-icon-stack-of-paper-sheets-confirmed-or-approved_6713491-removebg-preview.png'
export const PrivacyPolicyHeader = () => {
    return (
        <div className={styles.header}>
            <div className={styles.parent}>
                <div className={styles.left}>
                    <h1><span>Privacy Policy</span></h1>
                    <p>This privacy policy (“Privacy Policy”) is incorporated by reference into <span>Ejento.ai</span> (our “Application”) Terms of Service.</p>
                    {/* <a href='#id'>   <button className={styles.demo}>Learn More</button></a> */}
                </div>
                <div className={styles.right}>
                    <img src={privacyImg}/>
                </div>
            </div>



        </div>
    );
}