// import { QuestionIcon } from './QuestionIcon';
import clsx from 'clsx';
import { Stack } from "@fluentui/react";
// import { Diversity28Regular } from '@fluentui/react-icons';
import styles from './Answer.module.scss'
import { AskResponse, decryptData } from '../../../api'
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
interface Props {
    message: string;
    answer?: AskResponse;
}

export const UserChatMessage = ({ message }: Props) => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')))
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
        return (
        <div className='d-flex justify-content-start'>
            <Stack >
                {/* Aligns the Stack to the right */}
                <Stack.Item>
                    <div style={{ marginBottom: shareQueryParam !== "Allow-Embed-Only" ? '0.1rem' : '-0.5rem'}} className={clsx(
                        ' rounded',
                        'text-dark  ',
                        'text-start',
                        'mt-3'
                    )}>
                        <Stack.Item grow >
                         
                            <div  id='answer'>

                                <div style={{ fontSize: shareQueryParam !== "Allow-Embed-Only" ? windowWidth<700 ? '15px':'15px' : '18px',
                                fontWeight: 350, fontFamily:"Lexend Deca",whiteSpace:'pre-line'
                            }}
                                dangerouslySetInnerHTML={{ __html: message }}></div>
                            </div>
                        </Stack.Item>
                        

                    </div>
                </Stack.Item>
            </Stack>
        </div>
    );
};
