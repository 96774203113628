import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styles from './style.module.css';
import React, { useState, useEffect } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import MenuItem from '@mui/material/MenuItem';
import { BarChart } from '@mui/x-charts';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDailyEngagements, getDailyTokens, getEngagementByAgents, getEngagementByTeams, getEngagementByUser, getMonthlyEngagements, getMonthlyTokens, getTokensByUser, getTokensOfAgents, getTokensOfTeams, getWeeklyEngagements, getWeeklyTokens } from '../../../../../api';
import {
    blueberryTwilightPalette,
    mangoFusionPalette,
    cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

const categories = {
    blueberryTwilight: blueberryTwilightPalette,
    mangoFusion: mangoFusionPalette,
    cheerfulFiesta: cheerfulFiestaPalette,
} as const;

export const Graph = () => {

    const [category, setCategory] = React.useState('10');
    const [level, setLevel] = React.useState('10');
    const [time, setTime] = React.useState('10');
    const [Ylabel, setYlabel] = React.useState('Chats');
    const [Xlabel, setXlabel] = React.useState('Users');
    const [YData, setYData] = useState<number[]>([]);
    const [XData, setXData] = useState<string[]>([]);
    const [title, setTitle] = React.useState('Chats');
    const [smallScreenCategory, setSmallScreenCategory] = useState('Chat Engagement');
    const [smallScreenLevel, setSmallScreenLevel] = useState('Agent Level');
    const [smallScreenTime, setSmallScreenTime] = useState('Daily');
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const storedTheme = localStorage.getItem("kt_theme_mode_value");
    const [activeTheme, setActiveTheme] = React.useState<'dark' | 'light'>('light');

    useEffect(() => {
        const storedTheme = localStorage.getItem("kt_theme_mode_value");
        setActiveTheme(storedTheme === 'dark' ? 'dark' : 'light');
    }, []);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedTheme = localStorage.getItem("kt_theme_mode_value");
            setActiveTheme(storedTheme === 'dark' ? 'dark' : 'light');
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const baseTheme = createTheme(); // use the default theme as a base
    const updatedTheme = createTheme({
        ...baseTheme,
        palette: {
            mode: activeTheme,
            // Add any additional palette properties if needed
        },
    });

    useEffect(() => {
        getUserEngagement()
    }, [])
    useEffect(() => {
        getData()
        // filterNaNValues();
    }, [category, level, time])

    const getAgentTokens = async () => {
        try {
            const response = await getTokensOfAgents()
            if (response) {
                const tokens = response.agent_token_data.map(agent => agent.total_tokens !== null ? agent.total_tokens : 0);
                setYData(tokens);
                const names = response.agent_token_data.map(agent => agent.agent_name);
                setXData(names);
            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getTeamTokens = async () => {
        try {
            const response = await getTokensOfTeams()
            if (response.team_token_data?.length) {
                const tokens = response.team_token_data.map(team => team.total_tokens_count !== null ? team.total_tokens_count : 0);
                setYData(tokens);
                const teamIds = response.team_token_data.map(team => team.team_name);
                setXData(teamIds);

            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getDailyToken = async () => {
        try {
            const response = await getDailyTokens()
            if (response.daily_tokens?.length) {
                const tokens = response.daily_tokens.map(token => token.totalTokens !== null ? token.totalTokens : 0);
                setYData(tokens);
                const dates = response.daily_tokens.map(date => date.date.toString());
                setXData(dates);
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }
            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getWeeklyToken = async () => {
        try {
            const response = await getWeeklyTokens()
            if (response.weekly_tokens?.length) {
                const tokens = response.weekly_tokens.map(token => token.totalTokens !== null ? token.totalTokens : 0);
                setYData(tokens);
                const dates = response.weekly_tokens.map(date => date.weekStartDate.toString());
                setXData(dates);
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }
            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getMonthlyToken = async () => {
        try {
            const response = await getMonthlyTokens()
            if (response.monthly_tokens?.length) {
                const tokens = response.monthly_tokens.map(token => token.totalTokens !== null ? token.totalTokens : 0);
                setYData(tokens);
                const monthNames = response.monthly_tokens.map(engagement => {
                    const date = new Date(engagement.monthStartDate);
                    const monthIndex = date.getMonth() + 1;

                    // Get the month name
                    const monthName = months[monthIndex];

                    return monthName;
                });
                setXData(monthNames);
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }

            }
            else {
                setXData([])
                setYData([])
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const getUserToken = async () => {
        try {
            const response = await getTokensByUser()
            if (response.user_token?.length) {
                const filteredEngagement = response.user_token.filter(email => email.user_email !== null);
                const tokens = filteredEngagement.map(token => token.total_tokens !== null ? token.total_tokens : 0);
                setYData(tokens);
                const emails = filteredEngagement.map(email => email.user_email.toString());
                setXData(emails);
            }
            else {
                setXData([])
                setYData([])
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const getUserEngagement = async () => {
        try {
            const response = await getEngagementByUser();
            if (response.user_engagement?.length) {
                const filteredEngagement = response.user_engagement.filter(email => email.user_email !== null);

                const tokens = filteredEngagement.map(engagement => engagement.interaction_count !== null ? engagement.interaction_count : 0);
                setYData(tokens);

                const emails = filteredEngagement.map(email => email.user_email.toString());
                setXData(emails);

            } else {
                setXData([]);
                setYData([]);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAgentEngagement = async () => {
        try {
            let response = await getEngagementByAgents()
            // response.agent_engagement=[]
            if (response.agent_engagement?.length) {
                const tokens = response.agent_engagement.map(engagement => engagement.interaction_count !== null ? engagement.interaction_count : 0);
                setYData(tokens);
                const dates = response.agent_engagement.map(name => name.agent_name.toString());
                setXData(dates);
            }
            else {
                setXData([])
                setYData([])
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const getTeamEngagement = async () => {
        try {
            const response = await getEngagementByTeams()
            if (response.team_engagement?.length) {
                const tokens = response.team_engagement.map(engagement => engagement.total_interaction_count !== null ? engagement.total_interaction_count : 0);
                setYData(tokens);
                const ids = response.team_engagement.map(name => name.team_name);
                setXData(ids);
            }
            else {
                setXData([])
                setYData([])
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const getDailyEngagement = async () => {
        try {
            const response = await getDailyEngagements()
            if (response.daily_engagement?.length) {
                const tokens = response.daily_engagement.map(engagement => engagement.interaction_count !== null ? engagement.interaction_count : 0);
                setYData(tokens);
                const date = response.daily_engagement.map(date => date.date.toString());
                setXData(date);
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }

            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getWeeklyEngagement = async () => {
        try {
            const response = await getWeeklyEngagements()
            if (response.weekly_engagement?.length) {
                const tokens = response.weekly_engagement.map(engagement => engagement.interaction_count !== null ? engagement.interaction_count : 0);
                setYData(tokens);
                const date = response.weekly_engagement.map(date => date.week_start_date.toString());
                setXData(date);
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }
            }
            else {
                setXData([])
                setYData([])
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const getMonthlyEngagement = async () => {
        try {
            const response = await getMonthlyEngagements()
            if (response.monthly_engagement?.length) {
                const tokens = response.monthly_engagement.map(engagement => engagement.interaction_count !== null ? engagement.interaction_count : 0);
                setYData(tokens);
                const monthNames = response.monthly_engagement.map(engagement => {
                    const date = new Date(engagement.month_start_date);
                    const monthIndex = date.getMonth() + 1;

                    // Get the month name
                    const monthName = months[monthIndex];

                    return monthName;
                });
                setXData(monthNames)
                const horizontalScrollDiv = document.getElementById('horizontalScrollDiv')
                if (horizontalScrollDiv) {
                    setTimeout(function () {
                        horizontalScrollDiv.scrollLeft = horizontalScrollDiv.scrollWidth;
                    }, 1000);
                }
            }
            else {
                setXData([])
                setYData([])
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
        if (event.target.value == '10') {

            setYlabel('Chats')
            setTitle('Chats')

        }
        else {
            setYlabel('Tokens')
            setTitle('Tokens')

        }

    };
    const getData = () => {
        if (category == "20" && level == "20") {
            getAgentTokens()

        }
        else if (category == "20" && level == "10") {
            getUserToken()

        }
        else if (category == "20" && level == "30") {
            getTeamTokens()

        }
        else if (category == "20" && level == "40" && time == "10") {
            getDailyToken()

        }
        else if (category == "20" && level == "40" && time == "20") {
            getWeeklyToken()

        }
        else if (category == "20" && level == "40" && time == "30") {
            getMonthlyToken()

        }
        else if (category == "10" && level == "10") {
            getUserEngagement()

        }
        else if (category == "10" && level == "20") {
            getAgentEngagement()

        }
        else if (category == "10" && level == "30") {
            getTeamEngagement()

        }
        else if (category == "10" && level == "40" && time == "10") {
            getDailyEngagement()

        }
        else if (category == "10" && level == "40" && time == "20") {
            getWeeklyEngagement()

        }
        else if (category == "10" && level == "40" && time == "30") {
            getMonthlyEngagement()

        }

    }
    const handleLevelChange = (event) => {
        setLevel(event.target.value);
        if (event.target.value == '10') {
            setXlabel('Users')

        }
        else if (event.target.value == '20') {
            setXlabel('Agents')
        }
        else if (event.target.value == '30') {
            setXlabel('Team')
        }
        else {
            setXlabel('Dates')
        }

    };
    const handleTimeChange = (event) => {
        setTime(event.target.value);
        if (event.target.value == '10') {
            setXlabel('Dates')
        }
        else if (event.target.value == '20') {
            setXlabel('Weeks')
        }
        else {
            setXlabel('Months')
        }

    };
    const HandleSmallScreenCategory = (event) => {
        setSmallScreenCategory(event.target.value);

        if (event.target.value == 'Chat Engagement') {

            setYlabel('Chats')
            setTitle('Chats')

        }
        else {
            setYlabel('Tokens')
            setTitle('Tokens')

        }
    }
    const HandleSmallScreenLevel = (event) => {
        setSmallScreenLevel(event.target.value);
        if (event.target.value == 'User Level') {
            setXlabel('Users')
        }
        else if (event.target.value == 'Agent Level') {
            setXlabel('Agents')
        }
        else if (event.target.value == 'Team Level') {
            setXlabel('Team')
        }
        else {
            setXlabel('Dates')
        }
    }
    const HandleSmallScreenTime = (event) => {
        setSmallScreenTime(event.target.value);
    }
    return (

        <div className={`mt-9 `} style={{ minWidth: "100%" }} >
            <div className={`mb-4  mw-40 justify-content-between  ${styles.view}`} style={{ maxWidth: 'fit-content' }}>
                <Box className={`${styles.dropdown} me-3`}>
                    <FormControl fullWidth>
                        <InputLabel id="Categorylabel" style={{ color: storedTheme === 'dark' ? 'white' : 'black' }}>Category</InputLabel>
                        <Select
                            labelId="Categorylabel"
                            id="Category"
                            value={category}
                            label="Category"
                            onChange={handleCategoryChange}
                            style={{ color: storedTheme === 'dark' ? 'white' : 'black',}}
                        >
                            <MenuItem value={10} >Chat Engagement</MenuItem>
                            <MenuItem value={20} >Token Usage</MenuItem>

                        </Select>
                    </FormControl>
                </Box>

                <Box className={`${styles.dropdown} me-3`}>
                    <FormControl fullWidth>
                        <InputLabel id="Levellabel" style={{ color: storedTheme === 'dark' ? 'white' : 'black'}}>Level</InputLabel>
                        <Select
                            labelId="Levellabel"
                            id="Level"
                            value={level}
                            label="Level"
                            onChange={handleLevelChange}
                            style={{ color: storedTheme === 'dark' ? 'white' : 'black', }}
                        >
                            <MenuItem value={10} >User Level</MenuItem>
                            <MenuItem value={20} >Agent Level</MenuItem>
                            <MenuItem value={30} >Team Level</MenuItem>
                            <MenuItem value={40} >Chronological Level</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                {
                    level == '40' &&
                    <Box className={`${styles.dropdown}`}>
                        <FormControl fullWidth>
                            <InputLabel id="Timelabel" style={{ color: storedTheme === 'dark' ? 'white' : 'black', }}>Time</InputLabel>
                            <Select
                                labelId="Timelabel"
                                id="Time"
                                value={time}
                                label="Time"
                                onChange={handleTimeChange}
                                style={{ color: storedTheme === 'dark' ? 'white' : 'black',  }}
                            >
                                <MenuItem value={10} >Daily</MenuItem>
                                <MenuItem value={20} >Weekly</MenuItem>
                                <MenuItem value={30} >Monthly</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                }

            </div>
            <div className='d-flex justify-content-center align-items-center'>
                {/* <div className={styles.rotatedText}>{Ylabel}</div> */}
                <div id="horizontalScrollDiv" style={{ width: '100%', overflow: "scroll", margin: 'auto', marginLeft: '2rem' }}>
                    {
                        XData.length > 0 && YData.length > 0 && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className={styles.rotatedText}>{Ylabel}</div>
                                <div className={styles.chart}>
                                    <ThemeProvider theme={updatedTheme}>
                                      
                                        <BarChart
                                            width={XData.length > 10 ? XData.length * 60 : 1000}
                                            height={500}
                                            yAxis={[{ label: '' }]}
                                            series={[{ data: YData, label: title, type: 'bar' ,color: '#4285f4',}]}
                                            xAxis={[{ scaleType: 'band', data: XData }]}
                                            className=''
                                            sx={{

                                                '& .MuiDataGrid-root .MuiDataGrid-columnsContainer': {
                                                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)', // Change grid color here

                                                },

                                                "& .MuiChartsAxis-label": {

                                                    marginRight: "2rem",
                                                    padding: "30rem !important"
                                                },

                                                "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
                                                    strokeWidth: "0.5",
                                                    fill: storedTheme === 'dark' ? '#151521' : '#f5f8fa',

                                                    userSelect: 'none'
                                                },



                                            }}
                                        />
                                    </ThemeProvider>
                                </div>
                            </div>
                        )
                    }




                </div></div>
        </div>
    );
}

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Listbox = styled('ul')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    padding: 6px;
    margin: 12px 60px;
    min-width: 100px;
    border-radius: 12px;
    overflow: auto;
    outline: 0px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    z-index: 1;
    `,
);
