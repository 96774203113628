import { Dispatch, SetStateAction, useState } from 'react'
import { KTSVG } from '../../../../../../../_metronic/helpers'
import { UserEditModal } from '../../user-edit-modal/UserEditModal'
import { User } from '../../../../../../../api';


interface Props {
  setAllUsers: Dispatch<SetStateAction<User[]>>;
  setFilteredUsers: Dispatch<SetStateAction<User[]>>;
  allUsers: User[];
}

const UsersListToolbar = ({ setAllUsers, setFilteredUsers, allUsers }: Props) => {
  const [showModal, setShowModal] = useState(false)

  const openAddUserModal = () => {
    setShowModal(true)

  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button>
      {showModal && <UserEditModal setAllUsers={setAllUsers} allUsers={allUsers} setFilteredUsers={setFilteredUsers} closed={(() => setShowModal(false))}></UserEditModal>}
      {/* end::Add user */}
    </div>
  )
}

export { UsersListToolbar }
