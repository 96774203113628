/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react'
import { AskResponse, AgentCardResponse, getOneAgent, RatingsResponse, getAllRatings, fetchLoginCookie, decryptData, ChatTurn, ChatRequest, Approaches, chatApi, getChat, deleteChat, ChatUpvoteRequest, upvoteChat, ChatLogResponse, ChatDownvoteRequest, downvoteChat, ChatCommentResponse, getChatlogsByChatThread, getAllConfigs, getActiveConfig, ActiveConfigRequest, setActiveConfig, getAChatThread, getOneAgentForMagicLink, createRecentAgent, getOneAgentUser, AgentCardResponse2, getAgentUserForMagicLink, getAllData } from "../../../api";
import { QuestionInputFill } from "./questionInputFill";
import styles from "./Chat.module.css";
import { AnalysisPanel } from "./AnalysisPanel";
import { AnalysisPanelTabs } from './AnalysisPanelTabs';
import { AnswerError } from './AnswerError';
import { Answer } from './Answer';
import { UserChatMessage } from './UserChatMessage';
import { AnswerLoading } from './AnswerLoading';
import { ExampleList } from './ExampleList';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, CircularProgress } from "@mui/material";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import QuestionStyle from './QuestionInput.module.scss'
import { useLocation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import { QuestionInputEmpty } from './questionInputEmpty';
import { UserGear } from '@phosphor-icons/react';
import defaultBotImg from '../../../media_dsd/defaultBotImg.png';


interface props {
  agentPromptUpdate: boolean;
  setAgentPromptUpdate: Dispatch<SetStateAction<boolean>>;
  setUpdateThreadList: Dispatch<SetStateAction<boolean>>;
  updatechatlog: boolean;
  updateThreadList: boolean
  setUpdateChatlog: Dispatch<SetStateAction<boolean>>;
  setEnableEdit: Dispatch<SetStateAction<boolean>>;
  setIsLoadingChat: Dispatch<SetStateAction<boolean>>;
  isLoadingChat: boolean
  currentTab: string
  setInputBar: Dispatch<SetStateAction<boolean>>;
  inputBar: boolean;
  setEmpty: Dispatch<SetStateAction<boolean>>;
  empty: boolean
  setAnswers: any
  answers: any
  question: string
  setQuestion: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean
  lastQuestionRef: any
  setError: any
  error: any
  setUpdateThreadName: Dispatch<SetStateAction<boolean>>;
  updateThreadName: boolean;
  linkClicked: boolean
  setLinkClicked: Dispatch<SetStateAction<boolean>>
  setRefreshChatPage: Dispatch<SetStateAction<boolean>>
  refreshChatPage: boolean;
  setLastQuestionRef: Dispatch<SetStateAction<string>>
}

export const ChatInner = ({ setLastQuestionRef, refreshChatPage, setRefreshChatPage, updateThreadName, setUpdateThreadName, setError, error, lastQuestionRef, setIsLoading, isLoading, question, setQuestion, answers, setAnswers, empty, setEmpty, inputBar, setInputBar, currentTab, setIsLoadingChat, isLoadingChat, setUpdateChatlog, setEnableEdit, updateThreadList, updatechatlog, agentPromptUpdate, setAgentPromptUpdate, setUpdateThreadList, linkClicked, setLinkClicked }: props) => {

  const { agentId } = useParams()
  const [openAnalysis, setOpenAnalysis] = useState(false);
  const [scrollAnalysis, setScrollAnalysis] = useState<DialogProps['scroll']>('paper');
  const descriptionElementRef = useRef<HTMLElement>(null);
  const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
  const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [agentActiveConfig, setAgentActiveConfig] = useState<number>();
  const [agentUpdateConfigData, setAgentUpdateConfigData] = useState<AgentCardResponse>();
  const [isAgentConfigLoading, setIsAgentConfigLoading] = useState<boolean>(true);
  const [agentConfigData, setAgentConfigData] = useState<AgentCardResponse2>();
  const [agentConfigName, setAgentConfigName] = useState("");
  const [promptTemplate, setPromptTemplate] = useState<string>("");
  const [retrieveCount, setRetrieveCount] = useState<number>(3);
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
  const [activeCitation, setActiveCitation] = useState<string>();
  const [loggedInUser, setLoggedInUser] = useState<boolean>(false);
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [ratingsData, setRatingsData] = useState<RatingsResponse[]>([]);
  const [responseIndex, setResponseIndex] = useState(0);
  const [excludeCategory, setExcludeCategory] = useState<string>("");
  const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
  const elementRef = useRef<HTMLDivElement>(null);
  const [useSources, setUseSources] = useState<boolean>(false);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [agentAlias, setAgentAlias] = useState("");
  const [selectedOption, setSelectedOption] = useState('');
  const [agentID, setAgentId] = useState<number>();
  const [userQuestions, setUserQuestions] = useState<any[]>([]);
  const [selectedAgentType, setSelectedAgentType] = useState('');
  const [showHintParagraph, setShowHintParagraph] = useState(true);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));
  const [agentImage, setAgentImage] = useState<string>("");
  const [agentName, setAgentName] = useState<string>("");
  const [agentDesc, setAgentDesc] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

      const fetchAllData = async (id: number, query?: string) => {
        try {
          const response = await getAllData(id, 1, query);
          if (response.data.length > 0) {
             setUseSources(true)
          }
         
        } 
        catch (e) {
            console.error(e);
          } 
    }
  

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    if (userId) {
      setLoggedInUser(true);
      setLoggedInUserId(userId);
    }
    setAgentImage(defaultBotImg)
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const regex = /tab=(chat|configure|knowledgeBase|evaluation)/;
    const match = url.match(regex);  
    if (match && match[1] && loggedInUserId != -1) {
      const extractedId = decodeURIComponent(match[1]);
      addRecentAgent(extractedId);
    }
  }, [loggedInUserId, agentId]);

  const addRecentAgent = async (id) => {
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);
    try {
      if (loggedInUserId) {
        const response = await createRecentAgent(loggedInUserId, parsedAgentID)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (answers.length === 1) {
      setUpdateThreadName(true)
    }
  }, [answers])

  useEffect(() => {
    if (updateThreadList) {
      setAnswers([])
      setIsLoading(false)
      setError()
      setLastQuestionRef('')
      setEmpty(true)
      setUpdateThreadList(false)
    }
  }, [updateThreadList])

  useEffect(() => {
    if (inputBar) {
      setLastQuestionRef('')
      setAnswers([]);
      setInputBar(false)
    }
  }, [inputBar])

  useEffect(() => {
    if (!isLoading && answers.length == 1) {
      enablingEdit()
    }
  }, [isLoading, answers])

  const handleAnalysisClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpenAnalysis(true);
    setScrollAnalysis(scrollType);
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  };

  const handleAnalysisClose = () => {
    setOpenAnalysis(false);
  };

  useEffect(() => {

    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    if (userId) {
      fetchUserActiveAgentConfig(userId);
      setLoggedInUser(true);
      setLoggedInUserId(userId);
    }
    if (agentId) {
      const agentID = decodeURIComponent(decryptData(agentId));
      const parsedAgentID = parseInt(agentID, 10);
      setAgentId(parsedAgentID)
    }

  }, [agentId, agentPromptUpdate]);
  useEffect(() => {
    if (updatechatlog) {
      const active_thread = localStorage.getItem('activeThread')
      if (active_thread) {
        getUserAgentChat(parseInt(active_thread))
        setUpdateChatlog(false)
      }
    }

  }, [updatechatlog])

  useEffect(() => {
    const extractQuestions: string[] = answers.map(([question]) => question);
    setUserQuestions(extractQuestions);
  }, [answers])

  const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
    setActiveAnalysisPanelTab(tab);
    handleAnalysisClickOpen('paper')();
    setSelectedAnswer(index);
  };

  const getAllAgentRatings = async () => {
    try {
      const response = await getAllRatings();
      setRatingsData(response);
    } catch (error) {
      console.error(error);

    }
  };

  const fetchUserActiveAgentConfig = async (id: any) => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        setAgentActiveConfig(parsedAgentID)
        setIsAgentConfigLoading(false);
        handleAgentConfigName(parsedAgentID);
        getAllAgentRatings();
      } else {
        setIsAgentConfigLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsAgentConfigLoading(false);

    }
  };


  const makeApiRequest = async (question?: string, cache_skip?: boolean, mode_override?: string) => {
    setShowHintParagraph(false)
    setEmpty(false)
    setLastQuestionRef(question!)
    // lastQuestionRef.current = question;
    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setActiveAnalysisPanelTab(undefined);
    try {
      let activeAgentPrimaryId;
      if (agentId) {
        activeAgentPrimaryId = decodeURIComponent(decryptData(agentId))
      } else {
        activeAgentPrimaryId = localStorage.getItem("Active-Agent-Id")!;
      }
      const userInfo = fetchLoginCookie();
      const decryptedRes = decryptData(userInfo);
      const userEmail = decryptedRes?.user;
      const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
      const request: ChatRequest = {
        history: [...history, { user: question, bot: undefined }],
        approach: Approaches.ReadRetrieveRead,
        category: activeAgentPrimaryId,
        user_id: loggedInUserId!,
        created_by: userEmail,
        chat_thread_id: parseInt(threadId!),
        overrides: {
          promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
          excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
          top: retrieveCount,
          semanticRanker: true,
          semanticCaptions: useSemanticCaptions,
          suggestFollowupQuestions: true,
          sources: useSources,
          cache_skip: cache_skip,
          mode_override: mode_override,
        },
        model: selectedOption
      };
      const result = await chatApi(request);
      const activeThread = localStorage.getItem('activeThread')

      if (activeThread && parseInt(activeThread) == request.chat_thread_id) {
        setAnswers([...answers, [question, result, result.chatlog_id, false, false, []]]);
        setShowHintParagraph(true)
      }
      setResponseIndex(responseIndex + 1);
      setShowHintParagraph(true)
    } catch (e) {
      const activeThread = localStorage.getItem('activeThread')
      if (activeThread && threadId && parseInt(activeThread) == parseInt(threadId)) {
        setError(e);
      }
    } finally {
      setIsLoading(false);

    }
  };

  const clearChat = () => {

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, clear chat!'
    }).then((result) => {
      if (result.isConfirmed) {
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        deleteUserAgentChat(loggedInUserId, agentActiveConfig);
        setResponseIndex(0);
        setEmpty(true)
        setLastQuestionRef('')
      }
    })
  };
  useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading, answers[1]]);

  const onExampleClicked = (example: string) => {

    makeApiRequest(example, false, "");
  };
  const onShowCitation = (citation: string, index: number) => {
    if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
      setActiveAnalysisPanelTab(undefined);
    } else {
      setActiveCitation(citation);
      setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
    }

    setSelectedAnswer(index);
  };

  const handleAgentConfigName = async (id: number) => {
    try {
      let response;
      if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
        response = await getAgentUserForMagicLink(id);
      } 
      else {
        // response = await getOneAgent(id);
        response = await getOneAgentUser(id);
      }

      if (response) {
        setAgentImage(response.agent_image !== null ? response.agent_image : defaultBotImg);

        setAgentName(response.agent_name)
        setAgentDesc(response.domain)
        setUserName(response.user?.first_name + " " + response.user?.last_name)
        setAgentConfigData(response);
        setAgentConfigName(response.agent_name);
        setSelectedAgentType(response.agent_type.name)
        setAgentAlias(response.alias);
        if (response?.created_by === 'System') {
          setUseSources(false)
        }
      }
    }
    catch (error) {
      console.error(error);

    }
  };

  const handleToggleChange = () => {
    setUseSources(!useSources);

  };

  const getUserAgentChat = async (id: number) => {
    try {
      if (refreshChatPage) {
        setError(undefined)
        setIsLoading(false)
        setLastQuestionRef('')
        setRefreshChatPage(false)
      }
      setIsLoadingChat(true)

      const response = await getChatlogsByChatThread(id);
      if (response.length > 0) {
        setEnableEdit(true)
        setEmpty(false)
        if (!isLoading) {
          setLastQuestionRef(response[response.length - 1]?.question)
        }
        // lastQuestionRef.current = response[response.length - 1]?.question;
        setAnswers(response.map(({ question, response, id, feedback, comments }) => [
          question,
          response,
          id,
          feedback[0]?.is_upvote,
          feedback[0]?.is_downvote,
          comments
        ]));
      }
      else {
        setEnableEdit(false)
        setEmpty(true)
        // setIsLoading(false)
        // setError()
        // lastQuestionRef.current = ''
        setAnswers([]);
      }

    } catch (error) {
      console.error(error);
    }
    setIsLoadingChat(false)
    setIsLoadingLogs(false)
  }

  const deleteUserAgentChat = async (userid: any, agentid: any) => {
    try {
      const response = await deleteChat(userid, agentid);
    } catch (error) {
      console.error(error);
    }
  }

  const handleChatUpvoteClick = async (id: number) => {
    try {
      const userInfo = fetchLoginCookie();
      const decryptedRes = decryptData(userInfo);
      const userEmail = decryptedRes?.user;
      const isAlreadyUpvoted = answers.some(answer => answer[2] === id && answer[3]);
      const request: ChatUpvoteRequest = {
        chat_id: id,
        is_upvote: !isAlreadyUpvoted,
        created_by: userEmail
      };
      const result = await upvoteChat(request);

      if (result) {
        setAnswers((prevAnswers) =>
          prevAnswers.map((answer) =>
            answer[2] === id ? [answer[0], answer[1], answer[2], !isAlreadyUpvoted, false, result.comments] : answer
          )
        );
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const handleChatDownvoteClick = async (id: number) => {
    try {
      const userInfo = fetchLoginCookie();
      const decryptedRes = decryptData(userInfo);
      const userEmail = decryptedRes?.user;
      const isAlreadyDownvoted = answers.some(answer => answer[2] === id && answer[4]);
      const request: ChatDownvoteRequest = {
        chat_id: id,
        is_downvote: !isAlreadyDownvoted,
        created_by: userEmail
      };
      const result = await downvoteChat(request);
      if (result) {
        setAnswers((prevAnswers) =>
          prevAnswers.map((answer) =>
            answer[2] === id ? [answer[0], answer[1], answer[2], false, !isAlreadyDownvoted, result.comments] : answer
          )
        );
      }
    }
    catch (error) {
      console.error(error);
    }
  }

  const screenWidth = window.innerWidth

  const enablingEdit = () => {
    setEnableEdit(true)

  }

  const handleParagraphClick = () => {
    makeApiRequest(userQuestions.length > 0 ? userQuestions[0] : " ", false, "QnA");
    setLinkClicked(true)
  };

  let threadId = localStorage.getItem('activeThread')

  useEffect(() => {
    setLinkClicked(false)
    const agentID = decodeURIComponent(decryptData(agentId));
    const parsedAgentID = parseInt(agentID, 10);
    fetchAllData((Number(parsedAgentID)));
  }, [threadId]
  )

  return (
    <>

      {!agentId ? <p className={styles.chatParent}>Create Or Activate An Agent To Get Started</p> :
        <div ref={scrollContainerRef} className={styles.chat} style={{ paddingLeft: '1rem', minHeight: '85vh', minWidth: '100%', margin: 'auto', display: 'flex', flexDirection: "row-reverse" }} >

          <div style={{ minWidth: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>

            <div>
              {(!empty || lastQuestionRef !== '') && (agentConfigData?.llm_config !== undefined) &&
                <div style={{
                  display: "flex", zIndex: '1',
                  position: 'fixed', bottom: '2.9rem', justifySelf: 'center', justifyContent: 'center', background: activeTheme === 'dark' ? "#151521" : '#ffffff',
                }}>
                  <QuestionInputFill
                    setSelectedOption={setSelectedOption}
                    selectedModel={selectedOption}
                    setUpdateChatlog={setUpdateChatlog}
                    updatechatlog={updatechatlog}
                    agentId={agentId}
                    style={QuestionStyle.question}
                    sendStyles={QuestionStyle.sendButtonAfter}
                    clearOnSend
                    placeholder={"Ask follow-up..."}
                    disabled={isLoading}
                    onSend={question => makeApiRequest(question, false, "")}
                    useSources={useSources}
                    handleToggleChange={handleToggleChange}
                    question={question}
                    setQuestion={setQuestion}
                    linkClicked={linkClicked}
                    setLinkClicked={setLinkClicked}
                    llmConfigId={agentConfigData?.llm_config}
                  ></QuestionInputFill>
                </div>
              }
            </div>

            <div
              className={`card-body ${styles.cardBodyHeight}`}
            >
              {
                isLoadingChat || isLoadingLogs ?
                  <div className={styles.loader}>
                    <CircularProgress color='primary' /></div> :
                  <div >
                    {agentUpdateConfigData ? <div className={styles.agentUpdateConfigData}></div> :
                      (<>
                        <div >
                          <div>
                            {answers.length === 0 && !isLoadingChat && !isLoading && !error ? (
                              <div style={{ minWidth: "100%"}} className={`d-flex align-items-center flex-column d-flex align-self-center ${styles.sizing}`}>
                                {/* <div  className='d-flex flex-column justify-content-center' style={{ minWidth: "100%",border:"2px solid pink"}}> */}
                                  {/* <p style={{ marginTop: screenWidth < 670 ? '0rem' : ' ' }} className={styles.header}>{"Where Data Meets Conversation"}</p> */}
                                  {isAgentConfigLoading ? <></> : agentActiveConfig ? <></> : <h4 className={`text-center ${styles.heading}`} >Please activate an agent from project</h4>}
                                  <div className={styles.content}>

                                  <div className='d-flex align-items-center'><img className={styles.content} style={{ borderRadius: "50%", textAlign: "center", margin:"auto" }} src={agentImage} width={60} height={60}></img></div>
                                  <p className={styles.agentName} >{agentName}</p>
                                  <p className={styles.desc} >By {userName} <UserGear size={18} color="#a1a1a1" weight="regular" /> </p>
                                  <p className={styles.desc} >{agentDesc}</p>

                                  {/* <div style={{minHeight:"1rem", border:"2px solid red"}}>{isAgentConfigLoading ? <></> : agentActiveConfig && !isLoadingChat ? <ExampleList setAgentPromptUpdate={setAgentPromptUpdate} agentPromptUpdate={agentPromptUpdate} onExampleClicked={onExampleClicked} agentid={agentActiveConfig!} /> : null}</div> */}
                                  <div style={{ minHeight: "9.1rem", display: 'flex', alignItems: 'center' }}>
                                    {isAgentConfigLoading ? (
                                      <></>
                                    ) : (
                                      agentActiveConfig && !isLoadingChat ? (
                                        <ExampleList
                                          setAgentPromptUpdate={setAgentPromptUpdate}
                                          agentPromptUpdate={agentPromptUpdate}
                                          onExampleClicked={onExampleClicked}
                                          agentid={agentActiveConfig!}
                                        />
                                      ) : null
                                    )}
                                  </div>
                                  </div>

                                  <div className={`${styles.inputBar} d-flex `}  >
                                    {empty && agentConfigData?.llm_config !== undefined &&
                                      <QuestionInputEmpty
                                        setSelectedOption={setSelectedOption}
                                        selectedModel={selectedOption}
                                        agentId={agentId}
                                        style={QuestionStyle.noQuestion}
                                        sendStyles={QuestionStyle.sendButtonBefore}
                                        clearOnSend
                                        placeholder={"Ask anything..."}
                                        disabled={isLoading}
                                        onSend={question => makeApiRequest(question, false, "")}
                                        useSources={useSources}
                                        handleToggleChange={handleToggleChange}
                                        question={question}
                                        setQuestion={setQuestion}
                                        llmConfigId={agentConfigData?.llm_config}
                                      ></QuestionInputEmpty>}
                                  </div>
                                  {/* {isAgentConfigLoading ? <></> : agentActiveConfig && !isLoadingChat ? <ExampleList setAgentPromptUpdate={setAgentPromptUpdate} agentPromptUpdate={agentPromptUpdate} onExampleClicked={onExampleClicked} agentid={agentActiveConfig!} /> : null} */}
                                {/* </div> */}
                              </div>
                            ) : (
                              <div style={{ marginBottom: '200px', }}>

                                {answers.map((answer, index) => (
                                  <div key={index}>
                                    <div ref={elementRef}>
                                      <UserChatMessage message={answer[0]} answer={answer[1]} />
                                    </div>

                                    <Answer
                                      showRegenerate={index === answers.length - 1 ? true : false}
                                      onRegenerateClicked={() => makeApiRequest(lastQuestionRef, true, linkClicked ? "QnA" : "")}
                                      last={true}
                                      setOpenModal={setOpenModal}
                                      AgentName={agentConfigName}
                                      key={index}
                                      answer={answer[1]}
                                      isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                      onCitationClicked={c => onShowCitation(c, index)}
                                      onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                      onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                      onFollowupQuestionClicked={q => makeApiRequest(q, false, "")}
                                      showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                      onUpvoteResponseClicked={() => handleChatUpvoteClick(answer[2]!)}
                                      onDownvoteResponseClicked={() => handleChatDownvoteClick(answer[2]!)}
                                      is_downvote={answer[4]}
                                      is_upvote={answer[3]}
                                      feedbackComments={answer[5]}
                                      chat_id={answer[2]}
                                      linkClicked={linkClicked}
                                      setLinkClicked={setLinkClicked}
                                    />

                                    {index === answers.length - 1 && selectedAgentType === "Tutor" && showHintParagraph && linkClicked == false && (
                                      <p>
                                        I am trying to help you get to the answers by providing hints. If you would rather see an answer right way <a onClick={handleParagraphClick} className={styles.directAnsLink}> Click here..</a>
                                      </p>
                                    )}
                                  </div>
                                ))}
                                {isLoading && (
                                  <>
                                    <UserChatMessage message={lastQuestionRef} />
                                    <div >
                                      <AnswerLoading />
                                    </div>
                                  </>
                                )}
                                {error ? (
                                  <>
                                    <UserChatMessage message={lastQuestionRef} />
                                    <div>
                                      <AnswerError
                                        AgentName={agentConfigName}
                                        error={(error as Error).toString()} // Explicitly cast 'error' to 'Error' type
                                        onRetry={() => makeApiRequest(lastQuestionRef, false, "")}
                                      />
                                    </div>
                                  </>
                                ) : null}

                                <div ref={chatMessageStreamEnd} />
                              </div>
                            )}

                          </div>
                          {answers.length > 0 && activeAnalysisPanelTab && (

                            <Dialog
                              open={openAnalysis}
                              onClose={handleAnalysisClose}
                              scroll={scrollAnalysis}
                              aria-labelledby="scroll-dialog-title"
                              aria-describedby="scroll-dialog-description"
                            >
                              <DialogTitle id="scroll-dialog-title">

                                {activeAnalysisPanelTab == 'thoughtProcess' ?
                                  (<>
                                    <div style={{ color: 'grey', display: 'flex', alignItems: 'center' }}><TipsAndUpdatesIcon color="warning" sx={{ mr: 1 }} /><span>Thought Process</span></div>
                                  </>)
                                  :
                                  (<>
                                    <div style={{ color: 'grey', display: 'flex', alignItems: 'center' }}><ContentPasteSearchIcon color="primary" sx={{ mr: 1 }} /><span>Supporting Content</span></div>
                                  </>)
                                }
                              </DialogTitle>
                              <DialogContent dividers={scrollAnalysis === 'paper'}>
                                <DialogContentText
                                  id="scroll-dialog-description"
                                  ref={descriptionElementRef}
                                  tabIndex={-1}
                                >
                                  <AnalysisPanel
                                    className={styles.chatAnalysisPanel}
                                    activeCitation={activeCitation}
                                    onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                    citationHeight="810px"
                                    answer={answers[selectedAnswer][1]}
                                    activeTab={activeAnalysisPanelTab!}
                                  />
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleAnalysisClose}>Cancel</Button>
                              </DialogActions>
                            </Dialog>
                          )}

                        </div>
                      </>)}
                  </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  )
}


