import clsx from 'clsx'
import { HeaderUserMenu, NotificationTab, ShareButton } from '../../../partials'
import ava1 from '../../../../media_dsd/user.jpg'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import NotificationCounter from './NotificationCounter '
import UserDark from '../../../../media_dsd/user_white.png'
import { Link, useLocation, useParams } from 'react-router-dom'
// import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../helpers'
import { Autocomplete, Checkbox, Divider, IconButton, Radio, TextField, Tooltip } from '@mui/material'
import { SupportRequest, decryptData, fetchLoginCookie, hubSpotFormSubmit, getOneAgent, getProject, AgentCardResponse, encryptData, createRecentAgent, getAllAgentsFromUserTeams, getAllAgents, AllOrgs, getOrgByUser, switchUserOrg, getUser, ActiveAgentAccessResponse, getAgentAccess, getOneAgentForMagicLink, allAgentWithUser, AgentCardResponse2, AgentCardResponseWithTeam, UserTeamResponseByUser, getAllTeams, getTeamsByUser, TeamResponse, ProjectResponse, getProjectsByTeam, } from '../../../../api'
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import styles from './Header.module.scss'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { CustomerSupport } from './CustomerSupport'
import FilterListSharpIcon from '@mui/icons-material/FilterListSharp';
import Highlighter from "react-highlight-words";
import Nav from 'react-bootstrap/Nav';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Navbar } from 'react-bootstrap';
import { useAuth } from '../../../../app/modules/auth';
import { ShareNetwork, Question, Plus } from "@phosphor-icons/react";
import defaultBotImg from '../../../../media_dsd/defaultBotImg.png';
import Cookies from "universal-cookie";
const itemClass = 'ms-2'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-30px h-35px w-md-35px h-md-35px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

interface HeaderWrapperProps {
  isEmbedding: boolean;
  agentUpdateAfterEdit: boolean;
  setAgentUpdate: Dispatch<SetStateAction<boolean>>;
  agentUpdateAfterDelete: boolean
  setCurrentTab?: Dispatch<SetStateAction<string>>;
  agentAccess: string;
}

const MetronicNavbar = ({ isEmbedding, agentUpdateAfterEdit, setAgentUpdate, agentUpdateAfterDelete, setCurrentTab, agentAccess }: HeaderWrapperProps) => {
  const { agentId } = useParams();
  const location = useLocation();
  const currentUrl = window.location.href;
  const [isClicked, setIsClicked] = useState(false);
  const [markAllAsRead, setMarkAllAsRead] = useState<boolean>(false);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false);
  const [showCustomerSupportModal, setShowCustomerSupportModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [ispromptDataLoading, setispromptDataLoading] = useState(true);
  const [agents, setAgents] = useState<AgentCardResponseWithTeam[]>([]);
  const [searchby, setSearchBy] = useState<string>('title');
  const [filter, setFilter] = useState<string>('title');
  const [activeAgent, setActiveAgent] = useState<string>('');
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [currentAgent, setCurrentAgent] = useState<number | null>(null);
  const [isLoadingSupportSubmit, setIsLoadingSupportSubmit] = useState<boolean>(false);
  const [formSubmitionStatus, setFormSubmitionStatus] = useState<boolean>(false);
  const [descRequired, setDescRequired] = useState<boolean>(false);
  const [submitFail, setSubmitFail] = useState<boolean>(false);
  const [agentName, setAgentName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [chatPage, setChatPage] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userId, setUserId] = useState(0)
  const navigate = useNavigate()
  const [showMenuDropdown, setShowMenuDropdown] = useState(false)
  const GlobalSearcModalBodyRef = useRef<HTMLDivElement>(null);
  const [openNotif, setopenNotif] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState(false);
  const [orgs, setOrgs] = useState<AllOrgs[]>([]);
  const [activeOrgName, setActiveOrgName] = useState<AllOrgs>()
  const [userOrgId, setUserOrgId] = useState(0)
  const [access, setAccess] = useState<string>('');
  const { currentUser, logout } = useAuth()
  const [getActiveOrg, setActiveOrg] = useState(false)
  const cookies = new Cookies();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));
  const [userTeamsData, setUserTeamsData] = useState<UserTeamResponseByUser[]>([]);
  const [projects, setProjects] = useState<ProjectResponse[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<TeamResponse | null>(null);
  const [selectedProject, setSelectedProject] = useState<ProjectResponse | null>(null);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [projectInputValue, setProjectInputValue] = useState<string>('');

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const org = decryptedRes?.org_id
    setUserOrgId(org)
    allOrg(userId, org)
    setUserId(userId)
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    if (agentId) {
      const agentID = decodeURIComponent(decryptData(agentId))
      const parsedAgentID = parseInt(agentID, 10);
      setActiveAgent(parsedAgentID.toString());
    }

    if (userId) {
      setLoggedInUserId(userId)
      if (isSuperUser) {
        getAllAgentsForSU();
      }
      else {
        getAgentsFromMyTeams(userId);
      }
    }
  }, [agentId, agentUpdateAfterEdit, agentUpdateAfterDelete])

  useEffect(() => {

    const handleClickOutside = (event: MouseEvent) => {
      if (

        GlobalSearcModalBodyRef.current &&
        !GlobalSearcModalBodyRef.current.contains(event.target as Node) &&
        event.target !== document.getElementById('search-input') // Check if the click target is not the input element
      ) {
        setIsOpen(false)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userEmail = decryptedRes?.user;
    const isAgentUrl = currentUrl.includes('/agent/');
    if (isAgentUrl) {
      setChatPage(true);
    }
    else {
      setChatPage(false);
    }
    if (userEmail) {
      setLoggedInUserEmail(userEmail);
    }
    if (agentId) {
      const agentID = decodeURIComponent(decryptData(agentId));
      const parsedAgentID = parseInt(agentID, 10);
      setCurrentAgent(parsedAgentID);
      getAgentAccessValue(parsedAgentID);

    }
    else {
      setCurrentAgent(null);
    }
  }, [showSupportModal]);

  const handleActiveOrg = (data: AllOrgs[] = orgs, orgid: number = userOrgId) => {
    if (data.length > 0) {
      const activeOrg = data.filter((org) => org.organization.id == orgid)
      setActiveOrgName(activeOrg[0])
    }
  }
  const switchOrg = async (orgId: number) => {
    try {
      const requestBody = {
        user_id: userId,
        organization_id: orgId
      }
      const response = await switchUserOrg(requestBody)
      if (response) {
        getUserInfo()
        handleActiveOrg(orgs, orgId)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const getUserInfo = async () => {
    try {
      let result = await getUser();
      if (result.org_id) {
        setUserOrgId(parseInt(result.org_id))
      }
      result = JSON.parse(JSON.stringify(result));
      if (result.user) {
        const encryptedRes = encryptData(result);
        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + 12 * 60 * 60 * 1000); // 12 hour from now
        cookies.set("__info", encryptedRes, { expires: expirationDate });
        localStorage.clear()
        navigate('/admin/analytics')
        window.location.reload()

      }
    } catch (e) {
      console.error(e)
    }
  };
  const handleMarkAllAsReadClick = () => {
    setMarkAllAsRead(true);
    setTimeout(() => {
      setMarkAllAsRead(false);
    }, 2000); // Adjust the delay as needed
  }
  const toggleClick = () => {
    setIsClicked(!isClicked);
    setopenNotif(!openNotif);
  }

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight
    }
  }, [])

  const handleCloseSupportModal = () => {
    setShowSupportModal(false);
    setFormSubmitionStatus(false);
    setAgentName('')
    setProjectName('')
  }

  const handleCloseCustomerSupportModal = () => {
    setShowCustomerSupportModal(false);

  }

  const handleOpenSupportModal = () => {
    setShowSupportModal(true);
    fetchUserActiveAgentConfig()
  }
  const handleSupportSubmit = async () => {
    if (!description) {
      setDescRequired(true);
      return;
    }
    else {
      setSubmitFail(false);
      setDescRequired(false);
      setIsLoadingSupportSubmit(true);
      try {
        const request: SupportRequest = {
          agent_id: currentAgent,
          description_ejento: description,
        }

        let response = await hubSpotFormSubmit(request);
        response = JSON.parse(JSON.stringify(response));
        if (response.success) {
          setIsLoadingSupportSubmit(false);
          setDescription('');
          setFormSubmitionStatus(true);

        }
        else {
          setIsLoadingSupportSubmit(false);
          setSubmitFail(true);
        }
      }
      catch (error) {
        console.error(error);
      }
    }
  };

  const fetchUserActiveAgentConfig = async () => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId))
        const parsedAgentID = parseInt(agentID, 10);
        let activeAgent;
        if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
          activeAgent = await getOneAgentForMagicLink(parsedAgentID);
        }
        else {
          activeAgent = await getOneAgent(parsedAgentID);
        }
        if (activeAgent) {
          const project = await getProject(activeAgent.project)
          setAgentName(activeAgent.agent_name)
          setProjectName(project.project_name)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleDropdown = (event: any) => {
    setDropdownOpen(!dropdownOpen);
    event.preventDefault();
  };

  const getAllAgentsForSU = async () => {
    try {
      const response = await allAgentWithUser();
      setAgents(response);
      setispromptDataLoading(false)
    } catch (error) {
      console.error(error);
    }
  };
  const getAgentsFromMyTeams = async (id: any) => {
    try {
      const response = await getAllAgentsFromUserTeams(id);
      setAgents(response);
      setispromptDataLoading(false)
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchBy = (by: string) => {
    setSearchBy(by)
    if (by == 'title') {
      setFilter('industry_area')
    }
    else if (by == 'description') {
      setFilter('domain')
    }
    else {
      setFilter('agent_name')
    }
  }
  const handleAgentActivateClick = async (id: number) => {
    setIsOpen(false)
    try {
      const agentID = encodeURIComponent(encryptData(id));
      navigate(`/agent/${agentID}?tab=chat`)
      const eventData = { agentId: id };
      const customEvent = new CustomEvent("activeAgent", { detail: eventData });
      window.dispatchEvent(customEvent);
      // setCurrentTab && setCurrentTab('chat')
    }
    catch (error) {
      console.error(error);
    }
  };
  const addRecentAgent = async (id: number) => {

    try {

      if (loggedInUserId) {
        const response = await createRecentAgent(loggedInUserId, id)

      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleFocus = () => {
    setIsOpen(true)
    setShowMenuDropdown(false)
  }
  const handleSearchClick = () => {
    setShowSearch(!showSearch)
  }
  const allOrg = async (userId: number, orgId: number) => {

    try {
      const response: AllOrgs[] = await getOrgByUser(userId)
      if (response.length > 0) {
        setOrgs(response)
        handleActiveOrg(response, orgId)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const getAgentAccessValue = async (id: any) => {
    try {
      const response = await getAgentAccess(id);
      setAccess(response.access);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location])






  return (
    <div className='app-navbar flex-shrink-0'>

      {/*global search begin */}

            <div className={` align-items-center mx-auto form-control form-control-solid my-auto ps-0 ${styles.largeScreenSearch}`} style={{ maxHeight: "3rem", width: '25rem', }}>

              <input
                id='search-input'
                type='text'
                data-kt-user-table-filter='search'
                className={`form-control form-control-solid`}
                placeholder={`Search agent by ${searchby}`}
                value={searchTerm}
                onChange={handleChange}
                onFocus={handleFocus}
                style={{ fontWeight: 300 }}
              />
              <div className={clsx('app-navbar-item', itemClass)}>
                {/* begin::Menu toggle */}
                <a

                  className={clsx('btn btn-icon ')}
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'

                >
                  <FilterListSharpIcon />

                </a>
                {/* end::Menu toggle */}

                {/* begin::Menu */}
                <div
                  className='ps-5  menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary py-4 fs-base w-200px'
                  data-kt-menu='true'
                  style={{ fontWeight: 300 }}
                >
                  Search By:
                  <Divider />
                  {/* begin::Menu item */}
                  <div className='menu-item px-3 my-0 mt-3' style={{ backgroundColor: searchby === 'title' ? activeTheme == 'dark' ? '#343541' : 'rgb(245 249 250)' : '', width: '13rem', borderRadius: '0.5rem' }}>
                    <a
                      className={clsx('menu-link px-3 py-2')}
                      onClick={() => handleSearchBy('title')}
                    >
                      <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG path='/media/icons/duotune/text/txt006.svg' className='svg-icon-3' />
                      </span>
                      <span className='menu-title'>Title</span>
                    </a>
                  </div>
                  {/* end::Menu item */}

                  {/* begin::Menu item */}
                  <div className='menu-item px-3 my-0' style={{ backgroundColor: (searchby === 'description') ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', width: '13rem', borderRadius: '0.5rem' }}>
                    <a
                      className={clsx('menu-link px-3 py-2')}
                      onClick={() => handleSearchBy('description')}
                    >
                      <span className='menu-icon' data-kt-element='icon'>
                        <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-3' />
                      </span>
                      <span className='menu-title'>Description</span>
                    </a>
                  </div>
                  {/* end::Menu item */}

                </div>
                {/* end::Menu */}
              </div>

            </div>

            <div className={` align-items-center mx-auto my-auto ps-0 ${styles.smallScreenSearch}`}>
              <div className={clsx('app-navbar-item', itemClass)}>
                {/* begin::Menu toggle */}
                <Tooltip title='Search'><a
                  onClick={handleSearchClick}
                  className={clsx('btn btn-icon ')}
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'

                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className={`${btnIconClass} svg-icon-gray-800`} />

                </a></Tooltip></div>
              {
                showSearch &&
                <div className={`d-flex align-items-center mx-auto form-control form-control-solid my-auto ps-0 ${styles.searchIcon}`} style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", border: '1px solid black', width: "25rem", }}>
                  <input
                    id='search-input'
                    type='text'
                    data-kt-user-table-filter='search'
                    className={`form-control form-control-solid`}
                    placeholder={`Search agent by ${searchby}`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onFocus={handleFocus}
                    style={{ fontWeight: 300 }}
                  />
                  <div className={clsx('app-navbar-item', itemClass)}>
                    {/* begin::Menu toggle */}
                    <a
                      className={clsx('btn btn-icon ')}
                      data-kt-menu-trigger="{default: 'click'}"
                      data-kt-menu-attach='parent'
                      data-kt-menu-placement='bottom-end'
                      onClick={() => {
                        setShowMenuDropdown(!showMenuDropdown);
                        // setIsOpen(false);
                      }}
                    >
                      <FilterListSharpIcon />

                    </a>
                    {/* end::Menu toggle */}

                    {/* begin::Menu */}
                    {
                      showMenuDropdown && <div
                        className='ps-5  menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-200px'
                        style={{ fontWeight: 300, display: showMenuDropdown ? 'inline' : 'none', top: '4rem', position: 'absolute', right: '0rem' }}

                      >
                        Search By:
                        <Divider />
                        {/* begin::Menu item */}
                        <div className='menu-item px-3 my-0 mt-3' style={{ backgroundColor: searchby === 'title' ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', width: '13rem', borderRadius: '0.5rem' }}>
                          <a
                            className={clsx('menu-link px-3 py-2')}
                            onClick={() => handleSearchBy('title')}
                          >
                            <span className='menu-icon' data-kt-element='icon'>
                              <KTSVG path='/media/icons/duotune/text/txt006.svg' className='svg-icon-3' />
                            </span>
                            <span className='menu-title'>Title</span>
                          </a>
                        </div>
                        {/* end::Menu item */}

                        {/* begin::Menu item */}
                        <div className='menu-item px-3 my-0' style={{ backgroundColor: searchby === 'description' ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', width: '13rem', borderRadius: '0.5rem' }}>
                          <a

                            className={clsx('menu-link px-3 py-2')}
                            onClick={() => handleSearchBy('description')}
                          >
                            <span className='menu-icon' data-kt-element='icon'>
                              <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-3' />
                            </span>
                            <span className='menu-title'>Description</span>
                          </a>
                        </div>
                        {/* end::Menu item */}

                      </div>
                    }

                    {/* end::Menu */}
                  </div>

                </div>
              }

            </div>

            {
              searchTerm.length > 0 && isOpen &&
              <div ref={GlobalSearcModalBodyRef} className={styles.modalGlobalSearch} style={{ width: "25rem", backgroundColor: activeTheme == 'dark' ? '#1e1e2d' : 'white' }} >
                {
                  agents.length > 0 ?
                    agents
                      .filter((agent) => searchby == 'title' ? agent.agent_name.toLowerCase().includes(searchTerm.toLowerCase()) : searchby == 'description' ? agent.domain.toLowerCase().includes(searchTerm.toLowerCase()) : agent.industry_area.toLowerCase().includes(searchTerm.toLowerCase()))
                      .map((agent, index) => (
                        <div className={styles.dropDownItemDiv} key={index} style={{ display: "flex", width: "24rem", backgroundColor: agent.id == parseInt(activeAgent) ? activeTheme == 'dark' ? '#343541' : 'rgb(224 243 245)' : '', borderBottom: "1px solid lightgray", marginBottom: "2%" }}>
                          <div style={{ paddingRight: "2%", paddingLeft: "1%" }}>
                            <img src={(agent.agent_image !== null && agent.agent_image !== "") ? agent.agent_image : defaultBotImg} width={46} height={46} alt="" style={{ borderRadius: "50%", marginTop: "12%", }} />
                          </div>
                          <div
                            style={{ width: "19.5rem", height: '4.9rem', borderRadius: "0.5rem", padding: '0.4rem' }}
                            key={index}
                            className={styles.customDropdownItem}
                          >
                            <div style={{ width: '19rem', lineHeight: "1.2", color: "black" }} onClick={() => handleAgentActivateClick(agent.id)}>
                              {
                                searchby === "title" ? <Highlighter
                                  highlightClassName={styles.highlightClass}
                                  searchWords={[searchTerm]}
                                  autoEscape={true}
                                  textToHighlight={agent.agent_name.length > 32 ? agent.agent_name.slice(0, 31) + '...' : agent.agent_name}
                                  caseSensitive={false}
                                /> : <div>{agent.agent_name.length > 32 ? agent.agent_name.slice(0, 31) + '...' : agent.agent_name}</div>
                              }
                              {
                                searchby === "description" &&
                                <div>

                                  <Highlighter
                                    highlightClassName={styles.highlightClass}
                                    searchWords={[searchTerm]}
                                    autoEscape={true}
                                    textToHighlight={`${agent.domain.length > 72 ? agent.domain.slice(0, 72) + '...' : agent.domain}`}
                                    caseSensitive={false}
                                    style={{ color: 'gray', fontSize: '0.8rem', lineHeight: '1.2' }} // Small font size and gray color
                                  />
                                  <p style={{ fontSize: "0.8rem", fontWeight: "300", color: "black" }}> By <span style={{ color: "#1bb3c7", fontWeight: "300" }}>{agent.user?.first_name + " " + agent.user?.last_name}</span> in <span style={{ color: "#1bb3c7", fontWeight: "300" }}>{agent.project?.team?.team_name}</span></p>
                                </div>

                              }
                              {
                                searchby === "job" ?
                                  <Highlighter
                                    highlightClassName={styles.highlightClass}
                                    searchWords={[searchTerm]}
                                    autoEscape={true}

                                    textToHighlight={`${agent.industry_area.length > 22 ? agent.industry_area.slice(0, 21) + '...' : agent.industry_area} | ${agent.domain.length > 22 ? agent.domain.slice(0, 21) + '...' : agent.domain}`}
                                    caseSensitive={false}
                                  />
                                  :
                                  searchby !== "description" &&
                                  (
                                    <div style={{ fontSize: "0.8rem" }} className={styles.greyText}>
                                      {agent.domain.length > 72 ? agent.domain.slice(0, 72) + '...' : agent.domain}
                                      <p style={{ fontSize: "0.8rem", fontWeight: "300", color: "black" }}> By <span style={{ color: "#1bb3c7", fontWeight: "300" }}>{agent.user?.first_name + " " + agent.user?.last_name}</span> in <span style={{ color: "#1bb3c7", fontWeight: "300" }}>{agent.project?.team?.team_name}</span></p>
                                    </div>)
                              }
                            </div>

                          </div>
                        </div>
                      ))
                    : null
                }
                <Divider style={{ backgroundColor: 'grey' }} />

              </div>
            }
            {/*global search end */}

            {/*share button begin */}
            {location.pathname.startsWith('/agent/') && isEmbedding && <div className={clsx('app-navbar-item', itemClass)}>
              <div
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
                className={clsx('position-relative', btnClass)}
              >
                <Tooltip title='Share'><ShareNetwork size={20} weight="light" className='iconStylesLightTheme' /></Tooltip>
              </div>
              <ShareButton agentAccess={agentAccess} />
            </div>}
            {/*share button end */}

            {/*notification button begin */}
            {isEmbedding && <div className={clsx('app-navbar-item', itemClass)}>
              <Tooltip title='Notifications'>
                <div
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                  className={clsx('notifModal position-relative iconStylesLightTheme', btnClass)}
                  onClick={toggleClick}
                >
                  <NotificationCounter
                    markAllAsRead={markAllAsRead}
                    openNotif={openNotif}

                  />
                </div></Tooltip>
              <NotificationTab
                isClicked={isClicked} //for calling the all notifications for user API
                handleMarkAllAsReadClick={handleMarkAllAsReadClick}
                openNotif={openNotif}
                setopenNotif={setopenNotif}
              />
            </div>}
            {/*notification button end */}

            {/*support button begin */}
            <div className={clsx('app-navbar-item', itemClass, styles.supportBtn)}>
              {/* begin::Menu toggle */}
              <Tooltip title='Support'><a

                className={clsx('btn btn-icon ', btnClass)}
                data-kt-menu-trigger="{default: 'click'}"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
              >
                <Question size={21} weight="light" className={`${btnIconClass} iconStylesLightTheme`} />
              </a></Tooltip>
              {/* end::Menu toggle */}

              {/* begin::Menu */}
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary  py-4 fs-base w-200px'
                data-kt-menu='true'
              >
                {/* begin::Menu item */}
                <div className='menu-item px-3 my-0'>
                  <a

                    className={clsx('menu-link px-3 py-2')}
                    onClick={handleOpenSupportModal}
                    id='support'

                  >
                    <span className='menu-icon' data-kt-element='icon'>
                      <KTSVG path='/media/icons/duotune/general/gen048.svg' className='svg-icon-3' />
                    </span>
                    <span className='menu-title'>Feedback</span>
                  </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3 my-0'>
                  <a

                    className={clsx('menu-link px-3 py-2')}
                    target="_blank"
                    href='https://docs.ejento.ai/faq'
                  >
                    <span className='menu-icon' data-kt-element='icon'>
                      <KTSVG path='/media/icons/duotune/general/gen052.svg' className='svg-icon-3' />
                    </span>
                    <span className='menu-title'>FAQs</span>
                  </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3 my-0'>
                  <a
                    className={clsx('menu-link px-3 py-2')}
                    href='https://docs.ejento.ai/docs/category/tutorials'
                    target="_blank"
                  >
                    <span className='menu-icon' data-kt-element='icon'>
                      <KTSVG path='/media/icons/duotune/general/gen062.svg' className='svg-icon-3' />

                    </span>
                    <span className='menu-title'>Tutorials</span>
                  </a>
                </div>
                {/* end::Menu item */}

              </div>
              {/* end::Menu */}
            </div>
            {/*support button end */}

            {/*theme button begin */}
            {/* <Tooltip title='Theme'><div className={clsx('app-navbar-item', itemClass, styles.themeBtn)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
      </Tooltip> */}
            {/*theme button end */}

            {/*user button begin */}
            {isEmbedding &&
              <div className={clsx('app-navbar-item', itemClass, styles.userBtn)}>
                <div
                  className={clsx('cursor-pointer symbol', userAvatarClass)}
                  data-kt-menu-trigger="{default: 'click'}"
                  data-kt-menu-attach='parent'
                  data-kt-menu-placement='bottom-end'
                >
                  {activeTheme === 'dark' ? (
                    <div className={clsx('cursor-pointer symbol', userAvatarClass)}>
                      <img alt='Dark Pic' src={UserDark} />
                      <span className={`me-4 ${styles.activeOrgName}`} id='activeOrgName'>{activeOrgName?.organization.org_name && activeOrgName?.organization.org_name.length > 30 ? activeOrgName?.organization.org_name.slice(0, 29) + '...' : activeOrgName?.organization.org_name}</span>
                    </div>

                  ) : (
                    <div className={clsx('cursor-pointer symbol', userAvatarClass)}>
                      <img alt='Pic' src={ava1} />
                      <Tooltip title={activeOrgName?.organization.org_name ? activeOrgName?.organization.org_name : ''}><span className={`me-4 ${styles.activeOrgName}`} id='activeOrgName'>{activeOrgName?.organization.org_name && activeOrgName?.organization.org_name.length > 30 ? activeOrgName?.organization.org_name.slice(0, 29) + '...' : activeOrgName?.organization.org_name}</span></Tooltip>
                    </div>
                  )}
                </div>
                <HeaderUserMenu userOrgId={userOrgId} activeOrgName={activeOrgName} switchOrg={switchOrg} setActiveOrg={setActiveOrg} orgs={orgs} />
              </div>}
            {/*user button end */}
            <div className={clsx('app-navbar-item', itemClass, styles.smallScreenMenu)}>
              <IconButton></IconButton>

              <Navbar collapseOnSelect >

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto">

                    <NavDropdownMenu title='...' style={{ fontWeight: 'bolder', }} id="collasible-nav-dropdown" alignRight>

                      <DropdownSubmenu title="User">
                        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                      </DropdownSubmenu>
                      <DropdownSubmenu title="Support">
                        <NavDropdown.Item onClick={handleOpenSupportModal}>Feedback</NavDropdown.Item>
                        <NavDropdown.Item href='https://docs.ejento.ai/faq' target="_blank">FAQs</NavDropdown.Item>
                        <NavDropdown.Item href='https://docs.ejento.ai/docs/category/tutorials' target="_blank">Tutorials</NavDropdown.Item>
                      </DropdownSubmenu>
                    </NavDropdownMenu>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {/* <Nav >
          <NavDropdown
            id="nav-dropdown-dark-example"
            title={<span style={{ padding: '0', marginBottom: '0.5rem' }}><WidgetsIcon></WidgetsIcon> </span>}
            menuVariant="dark"
 
          >
            <NavDropdown.Item onClick={() => handleActiveMenu('knowledgeBase')}>
             
            </NavDropdown.Item>
 
          </NavDropdown>
        </Nav> */}
            </div>

            <Modal
              show={showSupportModal}
              onHide={handleCloseSupportModal}
              backdrop='static'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              keyboard={true}
              className={styles.supportModal}

            >
              <Modal.Header closeButton closeVariant='white' style={{ backgroundColor: "rgb(48,70,172)" }}>
                <Modal.Title id='contained-modal-title-vcenter'> <span style={{ color: "white" }}>Feedback</span></Modal.Title>
              </Modal.Header>

              <Modal.Body ref={modalBodyRef} className={styles.modalBody}>
                <div className='w-100'>

                  {formSubmitionStatus ? (
                    <div className='text-center'>
                      <h2><span><CheckCircleTwoToneIcon fontSize='medium' /></span> Thank you!</h2>
                      <br></br>
                      <h5>We have received your Feedback, and our team will promptly attend to your inquiry. Thank you for reaching out.</h5>
                      <br></br>
                    </div>
                  ) : (
                    <>
                      {/* begin::Form Group  */}
                      <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-normal mb-2'>
                          <span className='required'>Email</span>
                        </label>
                        <input
                          id='email'
                          type='text'
                          className='form-control form-control-lg form-control-solid fw-light'
                          name='email'
                          placeholder='Enter your email here'
                          style={{ color: 'black !important' }}
                          value={loggedInUserEmail}
                          disabled

                        />
                      </div>
                      {/*end::Form Group */}
                      {
                        chatPage &&
                        <>

                          {/*begin::Form Group */}
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-normal mb-2'>
                              <span className='required'>Agent Name</span>
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid fw-light'
                              name='name'
                              style={{ color: 'black !important' }}
                              value={agentName}
                              disabled
                            />
                          </div>
                          {/*end::Form Group */}

                          {/*begin::Form Group */}
                          <div className='fv-row mb-10'>
                            <label className='d-flex align-items-center fs-5 fw-normal mb-2'>
                              <span className='required'>Project Name</span>
                            </label>
                            <input
                              type='text'
                              className='form-control form-control-lg form-control-solid fw-light'
                              name='name'
                              style={{ color: 'black !important' }}
                              value={projectName}
                              disabled
                            />
                          </div>
                          {/*end::Form Group */}
                        </>
                      }
                      <div className='fv-row mb-10'>
                        <label className='d-flex align-items-center fs-5 fw-normal mb-2'>
                          <span className='required'>Description</span>
                        </label>
                        <textarea
                          placeholder='Describe your issue'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className='form-control form-control-lg form-control-solid fw-light'
                          rows={6}
                        />
                        {descRequired && (
                          <div className='mb-5'>
                            <span className='text-danger'>Description is required</span>
                          </div>
                        )}
                      </div>
                      {/*end::Form Group */}

                      <div className='fv-row mb-10'>
                        <p>By submitting this form, you consent to allow us to store and process the information provided.</p>
                      </div>

                      {submitFail &&
                        <div className='mb-5 mt-5'>
                          <p><span className='text-danger'>Failed to submit form. Please try again.</span></p>
                        </div>}

                      <div className='fv-row mb-10'>
                        {isLoadingSupportSubmit && (
                          <button
                            type='button'
                            className={`btn btn-lg ${styles.formBtn}`}

                          >
                            <span className='indicator-progress d-block' >
                              Please wait
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span></button>
                        )}
                        {!isLoadingSupportSubmit && (<button className={`btn ${styles.formBtn}`} type='button' onClick={handleSupportSubmit}>Submit</button>)}
                      </div>
                    </>)}
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={showCustomerSupportModal}
              onHide={handleCloseCustomerSupportModal}
              centered
              keyboard={true}
              aria-labelledby="example-custom-modal-styling-title"
              size='xl'

            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title" className={styles.title}>
                  Learning Center
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <CustomerSupport setShowCustomerSupportModal={setShowCustomerSupportModal}></CustomerSupport>

              </Modal.Body>
            </Modal>

          </div>
          )
}

          export {MetronicNavbar}

