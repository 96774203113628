
import React, { Dispatch, SetStateAction, ChangeEvent, useRef, useEffect } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert library
import { useState } from 'react';
import styles from './Step.module.scss'
import { KTSVG } from '../../../../helpers';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import JSZip from 'jszip';

interface Props {
  setSelectedFile: Dispatch<SetStateAction<File[]>>;
  selectedFiles: File[];
  disabled: boolean;
  index: () => void;
  isLoadingIndex: boolean;
  setUploadError: Dispatch<SetStateAction<string>> | undefined
}

export const FileInput: React.FC<Props> = ({ setUploadError, isLoadingIndex, index, setSelectedFile, selectedFiles, disabled }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const activeTheme = localStorage.getItem("kt_theme_mode_value");

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const maxSize = 100 * 1024 * 1024; // 100MB limit
      const validExtensions = ['.pdf', '.xlsx', '.xls', '.xlsm', '.docx', '.doc', '.docm', '.ppt', '.pptx', '.pptm', '.txt', '.csv', '.json', '.zip', '.html', '.eml', '.epub', '.gz', '.xml', '.odt', '.ods', '.odp', '.rtf', '.kml'];
  
      let validNonZipFiles: File[] = [];
      let oversizedFiles: File[] = [];
      let unsupportedExtensionFiles: File[] = [];
  
      files.forEach(file => {
        const isZipFile = file.name.endsWith('.zip');
        const isValidExtension = validExtensions.includes(`.${file.name.split('.').pop()?.toLowerCase()}`);
  
        if (file.size > maxSize) {
          oversizedFiles.push(file);
        } else if (!isZipFile && !isValidExtension) {
          unsupportedExtensionFiles.push(file);
        } else if (!isZipFile) { // Only add non-zip files that are not oversized or have unsupported extensions
          validNonZipFiles.push(file);
        }
      });
  
      if (oversizedFiles.length > 0) {
        const oversizedFileNames = oversizedFiles.map(file => file.name).join(', ');
        Swal.fire({
          icon: 'error',
          title: 'Limit Reached',
          text: `File(s) ${oversizedFileNames} exceed the 100MB limit.`,
        });
      }
  
      if (unsupportedExtensionFiles.length > 0) {
        const unsupportedFileNames = unsupportedExtensionFiles.map(file => file.name).join(', ');
        Swal.fire({
          icon: 'error',
          title: 'File not supported',
          text: `File(s) ${unsupportedFileNames} have unsupported extensions.`,
        });
      }
  
      // Reset the input field
      if (inputRef.current) inputRef.current.value = '';
  
      // Process zip files to extract their contents
      const zipFiles = files.filter(file => file.name.endsWith('.zip') && file.size <= maxSize);
      if (zipFiles.length > 0) {
        try {
          const extractedFiles = await extractZipContents(zipFiles);
          // Filter extracted files based on valid extensions and size
          const validExtractedFiles = extractedFiles.filter(file => validExtensions.includes(`.${file.name.split('.').pop()?.toLowerCase()}`));
          setSelectedFile(prevSelectedFiles => [...prevSelectedFiles, ...validNonZipFiles, ...validExtractedFiles]);
        } catch (error) {
          console.error('Error extracting zip contents:', error);
        }
      } else {
        // Directly handle non-zip files if no zip files are present
        setSelectedFile(prevSelectedFiles => [...prevSelectedFiles, ...validNonZipFiles]);
      }
    }
  };
  
  const extractZipContents = async (zipFiles: File[]): Promise<File[]> => {
    let allExtractedFiles: File[] = [];
    const validExtensions = ['.pdf', '.xlsx', '.xls', '.xlsm', '.docx', '.doc', '.docm', '.ppt', '.pptx', '.pptm', '.txt', '.csv', '.json', '.zip', '.html', '.eml', '.epub', '.gz', '.xml', '.odt', '.ods', '.odp', '.rtf', '.kml'];
    const maxSize = 100 * 1024 * 1024; // 100MB limit
    let oversizedFilesNames: string[] = [];
  
    for (const zipFile of zipFiles) {
      const zip = new JSZip();
      await zip.loadAsync(zipFile).then(async (zipContent) => {
        const filePromises = Object.values(zipContent.files)
          .filter((zipEntry) => !zipEntry.dir)
          .map((zipEntry) =>
            zip.file(zipEntry.name)?.async('blob').then((blob) => {
              if (blob.size <= maxSize) {
                const newFile = new File([blob], zipEntry.name, { type: blob.type });
                const fileExtension = newFile.name.split('.').pop()?.toLowerCase();
                if (fileExtension && validExtensions.includes(`.${fileExtension}`)) {
                  allExtractedFiles.push(newFile);
                }
              } else {
                oversizedFilesNames.push(zipEntry.name);
              }
            })
          );
        await Promise.all(filePromises);
      });
    }
    if (oversizedFilesNames.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Limit Reached',
        text: `File(s) ${oversizedFilesNames.join(', ')} exceed the 100MB limit.`,
      });
    }
    return allExtractedFiles;
  };
  const handleRemoveFiles = () => {
    setSelectedFile([]);
    setErrorMessage('');
    if (setUploadError) {
      setUploadError("")
    }
  };
  const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement>, indexToRemove: number) => {
    e.preventDefault();
    setSelectedFile(prevSelectedFiles =>
      prevSelectedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const formatFileSize = (sizeInBytes: number) => {
    if (sizeInBytes < 1024) {
      return sizeInBytes + ' B';
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + ' KB';
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  };


  return (
    <div className={styles.fileInputParent}>



      <div className='d-flex flex-column align-items-center' style={{minHeight:"170px",}} >
        <input
          type="file"
          id="fileInput"
          accept=".pdf,.xlsx,.xls,.xlsm,.ppt,.pptx,.pptm,.txt,.docx,.docx,.docx,.csv,.json,.zip,.html,.eml,.epub,.gz,.xml,.odt,.ods,.odp,.rtf,.kml"
          onChange={handleFileChange}
          multiple
          ref={inputRef}
          className={styles.file}
        />
        <label htmlFor="fileInput" className={styles.pointer} style={{ maxWidth: '94%', minWidth: "100%" }}>
          <div className={`form-control form-control-lg form-control-solid fw-normal ${styles.fileInput}`}>
            Choose files
            {
              selectedFiles.length > 0 ? '' : <p className={styles.noFiles}>No file(s) selected. Click to choose file(s).</p>
            }
          </div>
        </label>

        {selectedFiles.length > 0 ? (
          <TableContainer className={styles.selectedFilesTable} component={Paper}>
            <Table className={styles.selectedFilesTable} sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={styles.selectedFilesCell} >File name</TableCell>
                  <TableCell className={styles.selectedFilesCell}>File size</TableCell>
                  <TableCell className={styles.selectedFilesCell}>Action</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFiles.map((row, index) => (
                  <TableRow
                    className={styles.selectedFilesRow}
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >

                    <TableCell className={styles.selectedFilesCell} style={{ maxWidth: "10rem", wordBreak: "break-all" }}>{row.name}</TableCell>
                    <TableCell className={styles.selectedFilesCell}>{formatFileSize(row.size)}</TableCell>
                    <TableCell >
                      <IconButton style={{ color: activeTheme === 'dark' ? 'white' : '#f1416c' }} onClick={(e) => handleRemoveFile(e, index)} size="small">
                        <ClearIcon fontSize='small' />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )}
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            Make sure files are not password protected
          </div>
        </div>
        {errorMessage && <p className={styles.textDanger}>{errorMessage}</p>}

        {selectedFiles.length > 0 && (
          <div className='d-flex justify-content-between'>
            {(
              <>
                <button
                  type='button'
                  className={`btn btn-lg  ${styles.pointer} ${styles.Btn} ${styles.mt10} me-2 w-40`}
                  data-kt-stepper-action='previous'
                  onClick={() => {
                    index();
                  }}
                  disabled={disabled}
                >
                  <KTSVG
                    path='/media/icons/duotune/files/fil001.svg'
                    className='svg-icon-3 me-1'
                  />
                  Upload
                </button>
              </>
            )}
          </div>
        )}


      </div>
    </div>
  );
};