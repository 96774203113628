import { useEffect, useState } from 'react';
import { Menu, List, ListItem, Divider, } from "@mui/material";
import {  fetchLoginCookie, decryptData, getOrgOfUser, OrganizationResponse, getConfigOfOrg, EditModelConfigurationRequest, editModelConfig } from '../../../../api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckIcon from '@mui/icons-material/Check';
import styles from './../chatlog/chatlogStyles.module.scss'


export const ModelSetting = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [orgName, setOrgName] = useState('');
    const [userOrgData, setUserOrgData] = useState<OrganizationResponse>();
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [activeModelDeployment, setActiveModelDeployment] = useState<string[]>([]);
    const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>("");
    const [loggedInUserId, setLoggedInUserId] = useState<number>();


    useEffect(() => {

        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        const userEmail = decryptedRes?.user;
        if (userId) {
            setLoggedInUserId(userId);
            setLoggedInUserEmail(userEmail);
        }
        getOrganizationName();

    }, []);

    const getOrganizationName = async () => {
        try {
            const userInfo = fetchLoginCookie();
            const decryptedRes = decryptData(userInfo);
            const userId = parseInt(decryptedRes?.id);
            const response = await getOrgOfUser(userId);

            if (response) {
                setUserOrgData(response);
                setOrgName(response[0].org_name);
                getConfigModel();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getConfigModel = async () => {
        try {
            const orgId = parseInt(decryptedRes?.org_id);
            const response = await getConfigOfOrg(orgId);
            if (response) {
                setActiveModelDeployment(response.model_deployment);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const handleUpdateModel = async (selectedModel) => {
        try {
            const orgId = parseInt(decryptedRes?.org_id);
            const request: EditModelConfigurationRequest = {
                org_id: orgId!,
                model_deployment: [selectedModel],
                modified_by: loggedInUserEmail
            };
            const response = await editModelConfig(request);
            if (response) {
                setActiveModelDeployment(response.model_deployment);
            }
        } catch (error) {
            console.error(error);
        }
        handleModelUpdateClose();
    };
    const handleModelUpdateClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <div style={{ marginLeft: "8%" }}>
                <div className={`mb-6 ${styles.inline}`}>
                    <div style={{ marginBottom: '30px', color: 'gray', fontSize: '14px', fontStyle: 'normal' }}>
                        To enhance your chat experience, please select the preferred model for your organization.
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '100px', fontSize: '14px', color: activeTheme === 'dark' ? 'white' : 'black' }}>
                            {orgName}
                        </div>

                           <div
                            style={{
                                color: "grey",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                fontSize: "11px",
                                marginRight: '20px',
                            }}
                            onClick={(e) => setAnchorEl(e.currentTarget)}>
                            {activeModelDeployment.includes('chat') ? 'ChatGPT 3.5' : activeModelDeployment.includes('chat4') ? 'ChatGPT 4.0' : 'Select Model'}
                            <ExpandMoreIcon style={{ marginLeft: "4px" }} />
                        </div>
                        <Menu
                            anchorEl={isSuperUser ? anchorEl : null}
                            open={isSuperUser ? Boolean(anchorEl) : false}
                            onClose={handleModelUpdateClose}>
                            <List>
                                <ListItem
                                    onClick={() => handleUpdateModel('chat')}
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ width: '24px', marginRight: '8px' }}>
                                    {activeModelDeployment.includes('chat') && <CheckIcon style={{ color: 'green' }} />}
                                    </div>
                                    GPT 3.5 Turbo
                                </ListItem>
                                <Divider />
                                <ListItem
                                    onClick={() => handleUpdateModel('chat4')}
                                    style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                >
                                    <div style={{ width: '24px', marginRight: '8px' }}>
                                    {activeModelDeployment.includes('chat4') && <CheckIcon style={{ color: 'green' }} />}
                                    </div>
                                    ChatGPT 4.0
                                </ListItem>
                            </List>
                        </Menu>
                        </div>
                    </div>
                </div>
           
        </>
    );
}