import { Tooltip } from '@mui/material';
import styles from './style.module.css';
import Avatar from '@mui/material/Avatar';
import { useState, useEffect, SetStateAction } from 'react'
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MostActiveAgents, MostActiveUsers, RecentlyAddedusers } from '../../../../../api/models';
import { getActiveAgents, getActiveUsers, getRecentUsers } from '../../../../../api/api';
import { stringAvatar } from '../../../../../api';



export const Table = () => {
    const [show, setShow] = useState('10');
    const [activeAgents, setActiveAgents] = useState<MostActiveAgents[]>([])
    const [recentUsers, setRecentUsers] = useState<RecentlyAddedusers[]>([])
    const [activeUsers, setActiveUsers] = useState<MostActiveUsers>()
    useEffect(() => {
        getActiveAgent()
        getRecentlyAddedUser()
        getMostActiveUser()
    }, [])

    const getActiveAgent = async () => {
        try {
            const response = await getActiveAgents()
            if (response.length) {

                setActiveAgents(response)
            }
        }
        catch (error) {
            console.error(error);
        }

    }
    const getRecentlyAddedUser = async () => {
        try {
            const response = await getRecentUsers()
            if (response.length) {

                setRecentUsers(response)
            }
        }
        catch (error) {
            console.error(error);
        }

    }
    const handleCategoryChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setShow(event.target.value);

        if (event.target.value == '10') {
            setShow('10')

        }
        else if (event.target.value == '20') {
            setShow('20')

        }
        else {
            setShow('30')


        }
    };
    const getMostActiveUser = async () => {
        try {
            const response = await getActiveUsers(10)
            if (response.user_engagement.length > 0) {

                setActiveUsers(response)
            }
        }
        catch (error) {
            console.error(error);
        }

    }

    return (
        <>
            <p className={`${styles.header} mt-6`}>Recent Activities</p>


            <div className={`justify-content-evenly flex-wrap  ${styles.tables}`}>



                <div className={styles.table}>
                    <p className={`${styles.header}`}>Recently Added</p>
                    <span className={`${styles.prompt}`}>These are the recently added users.</span>
                    <div className={`${styles.hr}`}></div>
                    {
                        recentUsers.map((user, index) => {
                            const fullName = `${user.first_name || 'Anonymous User'} ${user.last_name || ''}`;
                            const lineHeight = fullName.length > 34 ? '15px' : '0';
                            const marginBottom = fullName.length > 34 ? '0px' : '1rem';
                            const marginTop = fullName.length > 34 ? '-9px' : '0';
                            return (
                                <div key={index}>
                                    <div className={`d-flex m-3`}>
                                        {
                                            user?.first_name && user?.last_name ? <Avatar {...stringAvatar(`${user?.first_name} ${user?.last_name}`)} />
                                                : <Avatar>AU</Avatar>}
                                        <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`}>
                                            <p style={{ lineHeight: lineHeight, marginBottom:marginBottom, marginTop:marginTop }}>{fullName}</p>
                                            <span className={styles.email}>{user.email}</span>
                                        </div>

                                    </div>
                                    <div className={`${styles.hr}`}></div>
                                </div>
                            )
                        })
                    }


                </div>
                <div className={`${styles.vr}`}></div>
                <div className={styles.table}>
                    <p className={`${styles.header}`}>Most Active Users</p>
                    <span className={`${styles.prompt}`}>These are the users that are most active.</span>
                    <div className={`${styles.hr}`}></div>
                    {
                        activeUsers?.user_engagement.map((user, index) => {
                            const fullName = `${user.user_fname || 'Anonymous User'} ${user.user_lname || ''}`;
                            const lineHeight = fullName.length > 37 ? '15px' : '0';
                            const marginBottom = fullName.length > 37 ? '0px' : '1rem';
                            const marginTop = fullName.length > 37 ? '-9px' : '0';
                            return (
                                <div key={index}>
                                    <div className={`d-flex m-3`}>
                                        {
                                            user?.user_fname && user?.user_lname ? <Avatar {...stringAvatar(`${user?.user_fname} ${user?.user_lname}`)} />
                                                : <Avatar>AU</Avatar>}
                                        <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`}>
                                            <p style={{ lineHeight: lineHeight, marginBottom:marginBottom, marginTop:marginTop }}>{fullName}</p>
                                            <span className={styles.email}>{user?.user_email}</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.hr}`}></div>
                                </div>
                            )
                        })
                    }

                </div>
                <div className={`${styles.vr}`}></div>
                <div className={styles.table}>
                    <p className={`${styles.header}`}>Recent Agents</p>
                    <span className={`${styles.prompt}`}>These are the recently created agents.</span>
                    <div className={`${styles.hr}`}></div>
                    {
                        activeAgents.slice(0, 5).map((agent, index) => {
                            const lineHeight = agent?.agent_name.length > 32 ? '15px' : '0';
                            const marginBottom = agent?.agent_name.length > 32 ? '0px' : '1rem';
                            const marginTop = agent?.agent_name.length > 32 ? '-9px' : '0';
                            return (
                                <div key={index}>
                                    <div className={`d-flex m-3`}>
                                        <Avatar >{agent?.agent_name[0]?.toUpperCase()}</Avatar>
                                        <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`}>
                                            <p style={{ lineHeight: lineHeight, marginBottom:marginBottom , marginTop:marginTop }}>{agent?.agent_name}</p>
                                            <Tooltip title={`${agent.domain}`}><span className={styles.email}>
                                                {agent.domain.length > 65 ? agent.domain.substring(0, 65) + '...' : agent.domain} 
                                            </span></Tooltip>
                                            <Tooltip title={agent.created_by}><p className={styles.created}>By {agent.created_by.length > 25 ? agent.created_by.substring(0, 25) + '...' : agent.created_by}</p></Tooltip>
                                        </div>

                                    </div>
                                    <div className={`${styles.hr}`}></div>
                                </div>
                            )
                        })
                    }


                </div>

            </div>
            <div className={`  ${styles.tablesSm} mt-8 `}>
                <div className={`justify-content-evenly flex-wrap`}>
                    <Box className={`${styles.dropdown} me-3`}>
                        <FormControl fullWidth>
                            <InputLabel id="Categorylabel">Category</InputLabel>
                            <Select
                                labelId="Categorylabel"
                                id="Category"
                                value={show}
                                label="Category"
                                onChange={handleCategoryChange}
                            >
                                <MenuItem value={10}>Recently Added</MenuItem>
                                <MenuItem value={20}>Most Active Users</MenuItem>
                                <MenuItem value={30}>Recent Agents</MenuItem>


                            </Select>
                        </FormControl>
                    </Box>
                </div>


                {
                    show == '10' && <div className={`mt-8 ${styles.table}`}>
                        <p className={`${styles.header}`}>Recently </p>
                        <span className={`${styles.prompt}`}>These are the recently added users.</span>
                        <div className={`${styles.hr}`}></div>
                        {
                            recentUsers.map((user, index) => {
                                const fullName = `${user.first_name} ${user.last_name}`;
                                const lineHeight = fullName.length > 20 ? '10px' : '0';
                                const marginBottom = fullName.length > 20 ? '0px' : '1rem';
                                const marginTop = fullName.length > 20 ? '-9px' : '0';
                                return (

                                    <div key={index} className={`d-flex `}>
                                        <Avatar {...stringAvatar(`${user?.first_name} ${user?.last_name}`)} />
                                        <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`} style={{ flexShrink: 0, flexGrow: 1 }}>
                                            <p style={{ lineHeight: lineHeight, marginBottom:marginBottom, marginTop: marginTop }}>{fullName}</p>
                                            <p className={styles.email}>{user.email}</p>
                                        </div>
                                        <div className={`${styles.hr}`}></div>
                                    </div>
                                )
                            })
                        }

                    </div>
                }

                <div className={`${styles.vr}`}></div>
                {
                    show == "20" && <div className={`mt-8 ${styles.table}`}>
                        <p className={`${styles.header}`}>Most Active Users</p>
                        <span className={`${styles.prompt}`}>These are the users that are most active.</span>
                        <div className={`${styles.hr}`}></div>
                        {
                            activeUsers?.user_engagement.map((user, index) => {
                                const fullName = `${user.user_fname  || 'User'} ${user.user_lname || ''}`;
                                const lineHeight = fullName.length > 20 ? '10px' : '0';
                                const marginBottom = fullName.length > 20 ? '0px' : '1rem';
                                const marginTop = fullName.length > 20 ? '-9px' : '0';
                                return(

                                <div key={index} className={`d-flex `}>
                                    <Avatar {...stringAvatar(`${user?.user_fname} ${user?.user_lname}`)} />

                                    <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`} style={{ flexShrink: 0, flexGrow: 1 }}>
                                        <p style={{ lineHeight: lineHeight, marginBottom:marginBottom, marginTop: marginTop }}>{fullName}</p>
                                        <span className={styles.email}>{user.user_email}</span>
                                    </div>
                                    <div className={`${styles.hr}`}></div>
                                </div>
                                )
})
                        }

                    </div>
                }

                <div className={`${styles.vr}`}></div>
                {
                    show == "30" && <div className={`mt-8 ${styles.table}`}>
                        <p className={`${styles.header}`}>Recent Agents</p>
                        <span className={`${styles.prompt}`}>These are the agents that are most active.</span>
                        <div className={`${styles.hr}`}></div>
                        {
                            activeAgents.slice(0, 5).map((agent, index) => {
                                const lineHeight = agent?.agent_name.length > 32 ? '15px' : '0';
                                const marginBottom = agent?.agent_name.length > 32 ? '0px' : '1rem';
                                const marginTop = agent?.agent_name.length > 32 ? '-9px' : '0';
                                return(
                                <div key={index}>
                                    <div className={`d-flex m-3`}>
                                        <Avatar >{agent?.agent_name[0]?.toUpperCase()}</Avatar>
                                        <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`}>
                                            <p style={{ lineHeight: lineHeight, marginBottom:marginBottom, marginTop: marginTop }}>{agent?.agent_name}</p>
                                            {/* <span className={styles.email}>job Function</span> */}
                                            <Tooltip title={`${agent?.domain}`}><span className={styles.email}>
                                                {agent.domain.length > 43 ? agent.domain.substring(0, 43) + '...' : agent.domain} 
                                            </span></Tooltip>
                                        </div>

                                    </div>
                                    <div className={`${styles.hr}`}></div>
                                </div>
                                )
                            }
                            )
                        }


                    </div>
                }


            </div>
        </>
    )
}