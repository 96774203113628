import { initializeIcons, IconButton } from "@fluentui/react";
import React, { useEffect, useRef,  useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal,  Form } from 'react-bootstrap';
import { TeamRequest, createRecentTeam, createTeam, getRecentTeam } from "../../../../../api";
import { useNavigate } from "react-router-dom";
import styles from './sidemenuStyles.module.scss'
import AddIcon from '@mui/icons-material/Add';
import Tooltip from "@mui/material/Tooltip";
import { Plus } from "@phosphor-icons/react";
initializeIcons();

type Props = {
    userId: number;
    orgId: number;
    userEmail: string;
    staffUser: boolean;
    superUser: boolean;
    onTeamProjectEditSuccess: () => void;
    recentTeams
    setRecentTeams
};

export const AddButtonTeam = ({ userId, orgId, userEmail, onTeamProjectEditSuccess, staffUser, superUser, recentTeams, setRecentTeams }: Props) => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [TeamName, setTeamName] = useState('');
    const [isTeamNameRequired, setIsTeamNameRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const addButtonRef = useRef<HTMLButtonElement | null>(null);
    const handleClose = () => setShow(false);
    useEffect(() => {
        
        const handleKeyUp = (event: { key: string; }) => {
            if (event.key === 'Enter' && addButtonRef.current) {
                addButtonRef.current.click();
            }
        };

        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const handleShowToast = () => {
        toast.success('Team Added Successfully',{style: {
            fontSize: '14px',
          
          },
        });
    };

    const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        setTeamName(event.target.value);

    };

    const handleSaveChanges =  () => {
        if (!TeamName) {
            setIsTeamNameRequired(true);
            return;
        }
        createNewTeam();
    };

    const createNewTeam = async () => {
      
        setLoading(true)
        try {
            const request: TeamRequest = {
                org_id: orgId,
                user_id: userId,
                team_name: TeamName,
                created_by: userEmail
            };
            const response = await createTeam(request);
            if (response.id) {
                onTeamProjectEditSuccess();
                handleClose();
                handleShowToast();
                setTeamName('');
                localStorage.setItem("Active-Team", response.id.toString())
                navigate("/templates/")
                window.dispatchEvent(new Event("teams"));
            }
             try {
            const response2 = await createRecentTeam(userId!,response.id);
    
            if (response2.id) {
                const recentTeamData = await getRecentTeam(userId!);
                setRecentTeams(recentTeamData)
                
            }
        } catch (error) {
            console.error('Error handling active team:', error);
        }
        } catch (error) {
            console.error(error);
        }
        setLoading(false)
    }
    const handleShow = () => {
        setShow(true)
        setIsTeamNameRequired(false)
    }
    return (
        <>
            {staffUser || superUser?           
            (<Tooltip title='Add Team'><Plus onClick={handleShow} size={16} weight="light" /></Tooltip>):null}             
            <Modal show={show} onHide={handleClose} keyboard={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="TeamName">
                            <Form.Label>Team Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Team name"
                                value={TeamName}
                                onChange={handleTeamNameChange}
                                autoComplete="on"
                            />
                            {isTeamNameRequired && (
                                <span className="text-danger">Team name is required</span>
                            )}
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    {loading && (
                        <button
                            type='button'
                            className={`btn me-3 ${styles.modalButton}`}
                        >
                            <span className='indicator-progress d-block' >
                                Please wait
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span></button>
                    )}
                    {!loading && (<>
                        <button
                            type='button'
                            className={`btn me-3 ${styles.modalButton}`}
                            data-kt-stepper-action='previous'
                            onClick={handleSaveChanges}
                            ref={addButtonRef}
                        >
                            Add</button>
                    </>)}
                </Modal.Footer>
            </Modal>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </>
    );
};
