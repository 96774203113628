import { SetStateAction, Dispatch, useEffect, useState, useRef } from 'react';
import { Tutorials, getOneTutorial, getTutorials } from '../../../../api';
import { KTSVG } from '../../../helpers';
import styles from './CustomerSupport.module.css'
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import { useNavigate } from 'react-router';


interface props {
    setShowCustomerSupportModal: Dispatch<SetStateAction<boolean>>;
}

export const CustomerSupport = ({ setShowCustomerSupportModal }: props) => {
    const [tutorials, setTutorials] = useState<Tutorials[]>([])
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const [url, setUrl] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [copyBtnText, setCopyBtnText] = useState<string>('Copy Link');
    const [stepCount, setStepCount] = useState<number>(0) //change it to zero later
    const navigate = useNavigate()

    useEffect(() => {
        getAllTutorials()
    
    }, [])
    useEffect(() => {
        const currentURL = window.location.href;
        setUrl(currentURL.split('#')[0] + '#/');
    }, []);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleButton1Click = (title: string, id: number) => {

        navigate(`/customerSupport/${title}_/${id}`)
        // navigate(`/faq/${name}_/${id}`);
        setShowCustomerSupportModal(false)

    };

    const getTutorial = async (tutorialId: string) => {
        try {
            const response = await getOneTutorial(parseInt(tutorialId))
            if (response) {
                setStepCount(response.length)
            }
        }
        catch (error) {
            console.error(error)
        }

    }
    const getAllTutorials = async () => {
        try {
            const response = await getTutorials()
            if (response) {
                setTutorials(response)
            }
        }
        catch (error) {
            console.error(error)
        }
    }

    const copyToClipboard = () => {
        if (textAreaRef.current) {
            textAreaRef.current.select();
            document.execCommand('copy');
            setCopyBtnText(' Copied')
            setTimeout(() => {
                setCopyBtnText(` Copy Link`)
            }, 2000);
        }
    };

    return (
        <>
            <div className={styles.top}>
                <div className={styles.left}>
                    <p className={styles.head}>Getting Started</p>
                    <p className={styles.text}>Let's begin with some basics</p>

                </div>
                <div className={styles.right}>
                    <div className={`d-flex align-items-center mx-auto ${styles.searchParent} ${styles.searchParentSizing}`}  >
                        <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-1 position-absolute ms-6'
                        />
                        <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className={`form-control form-control-solid fw-light ps-14 ${styles.search} ${styles.searchInput}`}
                            placeholder='Search for tutorials'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}

                        />
                    </div></div>
            </div>
            <div className={styles.tutorialsParent}>
                {tutorials.length > 0 && tutorials
                    .filter((item) => {
                        return item.title.toLowerCase().includes(searchTerm.toLowerCase())
                    })
                    .map((tutorial, index) => (
                        <div className={`${styles.oneTutorial} ${hoveredIndex === index ? styles.hovered : ''}`} key={index} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave()}>
                            <>
                                <div className={styles.box}>
                                    <div className={styles.imageContainer} style={{ backgroundImage: `url(${tutorial.thumbnail_url})` }}>
                                        <div className={styles.hoverScreen}>

                                            {hoveredIndex === index && (
                                                <div className={`${styles.buttons} ${styles.withTransition}`}>
                                                    <div className={styles.cover}>
                                                        <button className={styles.tut} onClick={() => handleButton1Click(tutorial.title, tutorial.id)}> Watch Tutorial <LaunchSharpIcon /></button>
                                                        <button className={styles.link} onClick={() => copyToClipboard()}>{copyBtnText == " Copied" ? <CheckSharpIcon /> : <AttachmentOutlinedIcon />} {copyBtnText}</button>
                                                    </div>
                                                    <textarea
                                                        ref={textAreaRef}
                                                        defaultValue={`${url}customerSupport/${tutorial.title.replace(/ /g, "%20")}_/${tutorial.id}`}
                                                        style={{ position: 'absolute', left: '-9999px' }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={styles.smHead}>Tutorial - {stepCount} STEPS</div> */}
                                <div className={styles.title}>{tutorial.title}</div>
                            </>
                        </div>
                    ))}



            </div>
        </>
    );
}