import { useParams } from 'react-router-dom';
import { FAQCategories, Questions, getFAQCategories, getFAQquestions } from '../../../api';
import { useEffect, useState } from 'react';
import styles from './faqs.module.scss'
import { useNavigate } from 'react-router-dom';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

export const CategorizedQuestion = () => {
    const { categoryName, categoryId } = useParams();
    const [questions, setQuestions] = useState<Questions[]>([]);
    const [faqCategories, setFaqCategories] = useState<FAQCategories[]>([])
    const navigate = useNavigate();

    useEffect(() => {

        getFAQCategory()
    }, [])
    useEffect(() => {
        getFAQs()
    }, [categoryId])

    function applyStylingToImages(text: string) {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = text;

        // Query for image tags within the temporary element
        const images = tempElement.querySelectorAll('img');

        // Apply styling to each image tag
        images.forEach((image) => {
            image.style.maxWidth = '100%';
            image.style.height = 'auto';
            image.style.margin = '1rem 0 1rem 0';
            // Add more styling as needed
        });

        return tempElement.innerHTML;
    }
    const getFAQs = async () => {
        try {
            if (categoryId) {
                const response = await getFAQquestions(parseInt(categoryId))
                if (response) {
                    setQuestions(response)
                  

                }
               
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    const handleCategoryClick = (id: number, name: string) => {
        // Navigate to a dynamic route based on the category id
        navigate(`/faq/${name}_/${id}`);

    };
    const handleFAQClick = () => {
        navigate(`/faq`);

    }

    const getFAQCategory = async () => {
        try {
            const response = await getFAQCategories()
            if (response) {
                const sortedResponse = response.sort((a, b) => a.faq_category_name.localeCompare(b.faq_category_name));
                setFaqCategories(sortedResponse);
            }

        }
        catch (error) {
            console.error(error);
        }
    }
    return (
        <div className={styles.container}>
            {
                categoryName && <div className={styles.address}><span className={styles.FAQ} onClick={handleFAQClick}>FAQ</span> | {categoryName?.slice(0, -1)}</div>
            }

            <div className={styles.QuestionsParent}>

                <div className={styles.left}>
                    <div className={styles.CategorizedQuestion}>{categoryName?.slice(0, -1)}</div>
                    {
                        questions?.map((question, index) => (
                            <div className={styles.parent} key={question.id}>
                                <div className={styles.questionHead}>{question.question}</div>
                                <div className={styles.answer} id="answers-container" dangerouslySetInnerHTML={{ __html: applyStylingToImages(question.answer) }}></div>
                            </div>

                        ))
                    }
                </div>
                <div className={styles.right}>
                    <div className={styles.list}>
                        {faqCategories?.map((category, index) => (
                            categoryId && <div key={index} className={`${styles.question1} ${parseInt(categoryId) == category.id ? styles.active : ''} `} onClick={() => handleCategoryClick(category.id, category.faq_category_name)}>
                                {
                                    parseInt(categoryId) == category.id ? <ArrowForwardSharpIcon /> : <></>
                                }
                                {category.faq_category_name}</div>
                        ))} </div>

                </div>

            </div>
        </div>
    );

}