import { useEffect, useState } from 'react';
import styles from './faqs.module.scss'
import { FAQCategories, getFAQCategories } from '../../../api';
import { useNavigate } from 'react-router-dom';

export const Faq = () => {
    const navigate = useNavigate()
    const [faqCategories, setFaqCategories] = useState<FAQCategories[]>([])

    useEffect(() => {
        getFAQCategory()
    }, [])
    const getFAQCategory = async () => {
        try {
            const response = await getFAQCategories()
            if (response) {
                const sortedResponse = response.sort((a, b) => a.faq_category_name.localeCompare(b.faq_category_name));
                setFaqCategories(sortedResponse);
            }

        }
        catch (error) {
            console.error(error);
        }
    }
    const handleCategoryClick = (id: number, name: string) => {
        // Navigate to a dynamic route based on the category id

        navigate(`/faq/${name}_/${id}`);

    };

    return (
        <div className={styles.faqParent}>
            <div className={styles.mainHead}>Getting Started With Ejento</div>
            <h2 className={styles.faqHead}>FAQs</h2>
            <div className={styles.faqList}>
                <div className={styles.leftFaq}>
                    {/* Render the first half of FAQs */}
                    {faqCategories?.slice(0, Math.ceil(faqCategories.length / 2)).map((category, key) => (
                        <div key={key} className={styles.question} onClick={() => handleCategoryClick(category.id, category.faq_category_name)}>
                            {category.faq_category_name}
                        </div>
                    ))}
                </div>

                <div className={styles.rightFaq}>
                    {/* Render the second half of FAQs */}
                    {faqCategories?.slice(Math.ceil(faqCategories.length / 2)).map((category, index) => (
                        <div key={index} className={`${styles.question} ${index == 0 ? styles.noMargin : ''}`} onClick={() => handleCategoryClick(category.id, category.faq_category_name)}>
                            {category.faq_category_name}
                        </div>
                    ))}
                </div>

            </div>



        </div>

    );

}