import { useNavigate, useParams } from 'react-router';
import styles from './CustomerSupport.module.css'
import { useEffect, useState } from 'react';
import { OneTutorial, Tutorials, getOneTutorial, getTutorials } from '../../../../api';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';

export const Tutorial = () => {
    const { title, tutorialId } = useParams();
    const [oneTutorial, setOnetutorial] = useState<OneTutorial[]>([])
    const [step, setStep] = useState<number>(0)
    const [stepCount, setStepCount] = useState<number>(0) //change it to zero later
    const [buttonText, setButtonText] = useState<string>('Next')
    const [tutorials, setTutorials] = useState<Tutorials[]>([])
    const [showNext, setShowNext] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        getAllTutorials()
    }, [])
    useEffect(() => {
        if (tutorialId) {
            getTutorial(tutorialId)
        }
    }, [tutorialId])

    const nextBtnClick = () => {

        const count = step + 1;

        if (count + 1 == stepCount) {
            setButtonText('Go To Step 1')
            setStep(count)
        }
        else if (count + 1 > stepCount) {
            setStep(0)
            setButtonText('Next')}
        else {
            setStep(count)
            setButtonText('Next')

        }

    }
    const previousBtnClick = () => {
        setStep(step - 1)
        setButtonText('Next')
    }
    const getTutorial = async (tutorialId: string) => {
        try {

            const response = await getOneTutorial(parseInt(tutorialId))
            if (response) {
                setOnetutorial(response);
                setStepCount(response.length)

            }
            if (response.length == 1) {
                setShowNext(false)
            }
            else{
                setShowNext(true)
            }

        }
        catch (error) {
            console.error(error)
        }

    }
    const getAllTutorials = async () => {
        try {
            const response = await getTutorials()
            if (response) {

                setTutorials(response)
            }
        }
        catch (error) {
            console.error(error)
        }
    }
    const handleRoundClick = (index: number) => {
        if (index + 1 == stepCount) {
            setButtonText('Go To Step 1')
            setStep(index)
        }
        else {
            setStep(index)
            setButtonText('Next')
        }

    }
    const video = (id: number, title: string) => {
        setStep(0)
        navigate(`/customerSupport/${title}_/${id}`)
    };
    return (

        <div className={`${styles.conatiner}`}>
            <div className={styles.left}>
                {
                    oneTutorial.length > 0 &&
                    <> <div className={styles.topPanel}>
                        <div className={styles.tutTitle}>{oneTutorial[step]?.title}</div>
                        <div className={styles.description}>{oneTutorial[step]?.description}</div>
                    </div>
                        <div className={styles.parent}>

                            <div className={styles.left}>
                                <div className={styles.main}>
                                    <div className={styles.tutBox}>

                                        <div className={styles.imageParent}>
                                            <img src={oneTutorial[step]?.url} alt='no image' className={styles.step}></img>
                                        </div>
                                    </div>


                                    <div className={styles.controls}>
                                        <div className={styles.dots}>
                                            {
                                                oneTutorial.length >1 && oneTutorial.map((tutorial, index) => {
                                                    return (
                                                        <div onClick={() => handleRoundClick(index)} key={index} className={`${styles.round} ${step == index ? styles.active : ''}`} ></div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className={`mt-2 ${styles.btn}`}>
                                            <div>   {
                                                step != 0 && <button className={`${styles.link} me-2 `} onClick={() => previousBtnClick()}>Previous</button>
                                            }
                                            </div>

                                           {showNext && <button className={`${styles.tut} ${styles.next}`} onClick={() => nextBtnClick()}>{buttonText} <ArrowForwardOutlinedIcon /></button>}
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>
                    </>
                }
            </div>
            <div className={styles.right}>
                <div className={styles.list}>
                    <h3 className={styles.more}>More Tutorials</h3>
                    {tutorials?.map((category, index) => (
                        tutorialId && <div key={index} className={`${styles.question1} ${parseInt(tutorialId) == category.id ? styles.activeVideo : ''} `} onClick={() => video(category.id, category.title)}>
                            {
                                parseInt(tutorialId) == category.id ? <ArrowForwardSharpIcon /> : <></>
                            }
                            {category.title}</div>
                    ))}
                </div>

            </div>

        </div>


    );
}