import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { FC, useEffect, useState } from 'react'
import {
  decryptData,
  fetchLoginCookie,
  getOneAgent,
  getProject,
  getOneAgentForMagicLink,
} from '../../../../api'

const Error403: FC = () => {
  const navigate = useNavigate()
  const { agentId } = useParams()
  const [agentActiveConfig, setAgentActiveConfig] = useState<string>()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));

  useEffect(() => {
    const userInfo = fetchLoginCookie()
    const decryptedRes = decryptData(userInfo)
    const userId = parseInt(decryptedRes?.id)
    if (userId) {
      fetchUserActiveAgentConfig()
    }
  }, [])

  const fetchUserActiveAgentConfig = async () => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId))
        const parsedAgentID = parseInt(agentID, 10);
        let activeAgent;
        if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
           activeAgent = await getOneAgentForMagicLink(parsedAgentID);
        } else {
           activeAgent = await getOneAgent(parsedAgentID);
        }
        if(activeAgent){
          const project = await getProject(activeAgent.project)
          setAgentActiveConfig(project.project_name)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = () => {
    navigate(-1);
  }

  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
        You should be a part of the {agentActiveConfig} project to access this.
      </div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <img
          src={toAbsoluteUrl('/media/auth/404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
        <img
          src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
          className='mw-100 mh-300px theme-dark-show'
          alt=''
        />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <div onClick={handleClick} className='btn btn-sm btn-primary'>
          Return Home
        </div>
      </div>
      {/* end::Link */}
    </>
  )
}

export { Error403 }
