import styles from './TermsOfService.module.css'
import privacyImg from '../../../media_dsd/vecteezy_documents-icon-stack-of-paper-sheets-confirmed-or-approved_6713491-removebg-preview.png'

export const Header = () => {
    return (
        <>
          <div className={styles.header}>
            <div className={styles.parent}>
                <div className={styles.left}>
                    <h1><span>Terms of Use</span></h1>
                    <p>This is a legal and binding agreement between you, the user and Data Science Dojo, stating terms of use that govern your use of <span>Ejento.ai</span>.</p>
                    {/* <a href='#id'>   <button className={styles.demo}>Learn More</button></a> */}
                </div>
                <div className={styles.right}>
                    <img src={privacyImg}/>
                </div>
            </div>



        </div>
        </>
    );
}