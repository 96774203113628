import { parseSupportingContentItem } from "./SupportingContentParser";
import styles from "./SupportingContent.module.css";



interface Props {

    supportingContent: string[];

}



export const SupportingContent = ({ supportingContent}: Props) => {

    return (
        <ul className={styles.supportingContentNavList}>
            {supportingContent.map((x, i) => {
                const parsed = parseSupportingContentItem(x);

                const boxStyles = {
                    backgroundColor: "rgba(255, 255, 255, .5)",
                    backdropFilter: "blur(5px)",

                };

                return (
                    <li className={styles.supportingContentItem} style={boxStyles} key={i}>
                        <div className={styles.supportingContentItemHeader}>{parsed.title}</div>
                        <p>{parsed.content}</p>
                    </li>
                );
            })}
        </ul>
    );

};

