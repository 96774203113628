

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, SetStateAction, ChangeEvent, useState, useEffect, } from 'react'
import { TextField, Select, MenuItem, FormControl, SelectChangeEvent, Checkbox } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { AgentCardResponse, getAgentByProject } from '../../../../../api';

interface Props1 {
  setAgent: Dispatch<SetStateAction<string>>
  naming: string
  valAgent: string
  hasError: boolean
  setOrg: Dispatch<SetStateAction<string>>
  Org: string
  setJob: (event: SelectChangeEvent<string[]>) => Promise<void>
  Job: string[]
  setDomain: Dispatch<SetStateAction<string>>
  domain: string
  industryOptions: string[]
  ioLoading: boolean
  handleCustomIndustryArea: (event: ChangeEvent<HTMLInputElement>) => void
  isCustomIndustryAreaValid: boolean
  customIndustryArea: string[]
  setHasNameError: Dispatch<SetStateAction<boolean>>
  isNameExists: boolean
  setIsNameExists: Dispatch<SetStateAction<boolean>>
  needAlias: boolean;
  setNeedAlias: Dispatch<SetStateAction<boolean>>;

}
const Step1 = ({ isNameExists, setHasNameError, setIsNameExists, customIndustryArea, isCustomIndustryAreaValid, handleCustomIndustryArea, industryOptions, ioLoading, setAgent, naming, valAgent, hasError, setOrg, Org, setJob, Job, setDomain, domain, needAlias, setNeedAlias }: Props1) => {
  const [isExceedingLength, setIsExceedingLengt] = useState<boolean>(false)
  const [agents, setAgents] = useState<AgentCardResponse[]>([])


  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  useEffect(() => {

    const activeProject = localStorage.getItem("Active-Project");

    if (activeProject) {
      const parts = activeProject.split('-');
      const projectId = parseInt(parts[0]);

      if (!isNaN(projectId)) {
        getAgentsOfProject(projectId);
      }
    }
  }, [])
  const handleNameChange = (e: { target: { value: SetStateAction<string> } }) => {
    const newValue = e.target.value;

    // Check if the new value exists in the list of agents
    const nameExists = agents.filter((agent) => e.target.value === agent.agent_name);

    // Update the state variables based on whether the name exists or not
    if (nameExists.length > 0) {
      setIsNameExists(true);

      setHasNameError(true); // You can choose to set the error state based on whether the name exists or not
    }
    else {
      setIsNameExists(false);

      setHasNameError(false);
    }

    if (newValue.length <= 30) {
      setAgent(newValue);
      setIsExceedingLengt(false);
    } else {
      setIsExceedingLengt(true);
    }
  }


  const handleKeyDown = (event: {
    target: any; key: string;
  }) => {
    if (event.key === 'Backspace') {
      setIsExceedingLengt(false);
      const newValue = event.target.value;
      setAgent(newValue);
    }
  };
  const getAgentsOfProject = async (project_id: number) => {
    try {

      const response = await getAgentByProject(project_id);
      if (response) {
        setAgents(response)
      }

    } catch (error) {
      console.error(error);
    }
  }
  const setFunction = (e: SelectChangeEvent<string[]>) => {
    setJob(e)

  }

  // const handleAliasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setNeedAlias(event.target.checked);
  // };

  return (
    <>
      <div className='current flex-column' data-kt-stepper-element='content'>
        <div className='w-100'>
          {/*begin::Form Group */}
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span className='required'>{naming} Name</span>
              <Tooltip title='Give a unique meaningful name to agent that adheres to its role E.g: Summarizer'>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                ></i></Tooltip>
            </label>
            <input
              id='name'
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='appname'
              placeholder=''
              value={valAgent}
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}

            />

            {!valAgent && hasError && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  {naming} name is required
                </div>
              </div>
            )}
            {isNameExists && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Agent with this name already exists
                </div>
              </div>
            )}
            {isExceedingLength && (
              <div className='fv-plugins-message-container'>
                <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                  Maximum Limit Reached
                </div>
              </div>
            )}
          </div>
          {/*end::Form Group */}

          {/*begin::Form Group */}
          <div className='fv-row'>

            {/* end::Label */}
            <div>
              <div className='fv-row mb-2'>
                <label className='d-flex align-items-center fs-5 fw-semibold'>
                  <span className='required'>Job Function</span>
                  <Tooltip title='Enter the primary role of the agent. E.g: Sales Specialist, Marketing Manager, Recruiter, Accountant or Engineer'>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                    ></i></Tooltip>
                </label>

              </div>
              <FormControl fullWidth sx={{ marginBottom: 3 }} className='form-control form-control-lg form-control-solid'>



                {ioLoading ? (

                  <Select

                    labelId="industry-label"

                    id="industry-select"

                    value=""

                    label="Job Function"
                    style={{ color: activeTheme === 'dark' ? '#92929f' : 'black' }}

                  >

                    <MenuItem disabled value="">Loading job functions...</MenuItem>

                  </Select>

                ) : (

                  <Select
                    style={{ color: activeTheme === 'dark' ? '#92929f' : 'black' }}

                    required

                    labelId="industry-label"

                    id="industry-select"

                    multiple

                    value={Job}

                    onChange={setFunction}

                    renderValue={(selected) => selected.join(', ')}

                    MenuProps={{ PaperProps: { style: { maxHeight: 300 } } }}

                  >

                    {industryOptions.map((option) => (

                      <MenuItem key={option} value={option}>

                        <Checkbox checked={Job.indexOf(option) > -1} />

                        {option}

                      </MenuItem>

                    ))}

                  </Select>

                )}

                {/* Text field for custom industry area */}

                {Job.includes('Other') && (

                  <TextField

                    required={!isCustomIndustryAreaValid} // Adding the 'required' attribute based on validation

                    error={!isCustomIndustryAreaValid} // Adding error styling based on validation

                    label="Custom Job Function"

                    value={customIndustryArea}

                    onChange={handleCustomIndustryArea}

                    fullWidth

                    margin="normal"

                    variant="standard"

                    sx={{ mb: 1.5 }}

                    helperText='Enter the primary role of the agent'
                    style={{ color: activeTheme === 'dark' ? '#92929f' : 'black' }}
                    InputProps={{
                      style: {

                        color: activeTheme === 'dark' ? 'white' : 'black',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: activeTheme === 'dark' ? 'white' : 'black',
                      },
                    }}
                    FormHelperTextProps={{
                      style: {
                        color: activeTheme === 'dark' ? 'white' : 'black', // Change this to the desired color
                      },
                    }}

                  />

                )}
                {(Job.length === 0) && hasError && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      Job Function is required
                    </div>
                  </div>
                )}

              </FormControl>


              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Domain</span>
                  <Tooltip title='Enter the focus area for the agent. E.g Healthcare, Finance, Sales, Marketing, Software. (Add more than one using commas)'>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                    ></i>
                  </Tooltip>

                </label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='appname'
                  placeholder=''
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />


                {!domain && hasError && (
                  <div className='fv-plugins-message-container'>
                    <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
                      Domain is required
                    </div>
                  </div>
                )}
              </div>
              {/* <Col sm="10" style={{display:'inline-block'}}>
                <FormControlLabel sx={{mr:0}} control={<Checkbox checked={needAlias}
                  onChange={handleAliasChange}
                  inputProps={{ 'aria-label': 'controlled' }} />} label="Need Email Alias?" />
                <Tooltip title='This will provide you an email to load data by forwarding emails to it'>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                  ></i>
                </Tooltip>
              </Col> */}
            </div>


          </div>
          {/*end::Form Group */}

        </div>

      </div>
    </>
  )
}

export { Step1 }
