import { SealQuestion } from "@phosphor-icons/react";
import styles from "./Example.module.css";

interface Props {
    text: string;
    value: string;
    icon: React.ReactNode;
    onClick: (value: string) => void;
}

export const Example = ({ text, value, icon, onClick }: Props) => {
    return (
        <div  style={{border:"1px solid #0000001a"}} className={styles.example} onClick={() => onClick(value)}>
             <span style={{paddingLeft:"2px"}}>{icon}</span>
             <p className={styles.exampleText}>{text}</p>
        </div>
    );
};
