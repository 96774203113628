import CryptoJS from "crypto-js";
import Cookies from "universal-cookie";
import axiosInstance from "axios";
import { scrapeWebsite } from "../_metronic/partials/chat/GetWebMetadata";


const cookies = new Cookies();

const secretKey = "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMkHjYZ6iW7yad4m2PHgniVQtx9xItBe";


export const encryptData = (text: any) => {
    const data = CryptoJS.AES.encrypt(
        JSON.stringify(text),
        secretKey
    ).toString().replace(/\//g, '_');

    return data;
};

export const decryptData = (text: any) => {
    if (!text) {
        return null;
    }
    const bytes = CryptoJS.AES.decrypt(text.replace(/_/g, '/'), secretKey);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return data;
};

export const fetchLoginCookie = () => {
    return cookies.get("__info");

};

export const fetchYouTubeMetadata = async (url: any) => {

    const requestUrl = `https://youtube.com/oembed?url=${url}&format=json`;
    try {
        const result = await axiosInstance.get(requestUrl);
        return result
    }
    catch {
        console.error("fetching yt metadata failed")
        return 'failed'
    }
}

export const fetchWebMetadata = async (url: any) => {
    try {
        const result = await scrapeWebsite(url);
        return result
    }
    catch {
        console.error("fetching web metadata failed")
    }
}

export function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let r = 0, g = 0, b = 0;

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        if (i === 0) r = value;
        if (i === 1) g = value;
        if (i === 2) b = value;
    }
    /* eslint-enable no-bitwise */
    const alpha = 0.5; // For example, 50% opacity
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            color: 'black'
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export function encrypt(payload){

    const secretKey = "LeujQ2pExMiy/nMZvEJ43i8hJfaAnXbA1Cbg1hOabcA=";
    
    // Convert the secret key to a format suitable for encryption
    const derived_key = CryptoJS.enc.Base64.parse(secretKey);
    
    // Initialize the initialization vector (IV) and encryption mode
    const iv = CryptoJS.enc.Utf8.parse("1327394851607723");
    const encryptionOptions = {
        iv: iv,
        mode: CryptoJS.mode.CBC
    };
    
    // Encrypt the payload using AES encryption with the derived key and encryption options
    var encryptedPayload = CryptoJS.AES.encrypt(payload, derived_key, encryptionOptions).toString();
    var urlEncodedPayload = encodeURIComponent(encryptedPayload);
    return urlEncodedPayload
}
