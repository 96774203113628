/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {Error403a} from './components/Error403a'
import {Error404} from './components/Error404'
import {Error403} from './components/Error403'
import {ErrorsLayout} from './ErrorsLayout'

const ErrorsPage = () => (
  <Routes>
    <Route element={<ErrorsLayout />}>
      <Route path='404' element={<Error404 />} />
      <Route path='403/:agentId' element={<Error403 />} />
      <Route path='unauthorized-error/403' element={<Error403a />} />
      <Route index element={<Error404 />} />
    </Route>
  </Routes>
)

export {ErrorsPage}
