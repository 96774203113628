/* eslint-disable jsx-a11y/anchor-is-valid */
import { encryptData } from '../../../../../api';
import { toAbsoluteUrl } from '../../../../helpers'
import { useNavigate } from "react-router";
import styles from './Step.module.scss';

interface Props {
  loggedInUserId: number | undefined
  selectedAgent: number
}

const Step5 = ({ loggedInUserId, selectedAgent }: Props) => {

  const navigate = useNavigate();
  // const [reload, setReload] = useState(false);


  const handleAgentActivateClick = async (id: number) => {

    try {
      const agentID = encodeURIComponent(encryptData(id));
      navigate(`/agent/${agentID}`)
      const eventData = { agentId: id };
      const customEvent = new CustomEvent("activeAgent", { detail: eventData });
      window.dispatchEvent(customEvent);
    }
    catch (error) {
      console.error(error);
    }
  };



  return (
    <>

      <div data-kt-stepper-element='content'  className={`${styles.subhead}`} >
        <div className={`d-flex flex-column ${styles.picdiv}`}  >
          <div className='w-100 text-center'>
            {/* begin::Heading */}
            <h1 className={`mb-3 ${styles.heading}`}>Release!</h1>
            {/* end::Heading */}

            {/* begin::Description */}
            <div className={`text-muted fw-semibold fs-3 ${styles.text}`} >
              Submit your agent and start chatting.
            </div>
            {/* end::Description */}

            {/* begin::Illustration */}
            <div className='text-center px-4 py-10'>
              <img
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/9.png')}
                alt=''
                className='mw-100 mh-300px'
              />
            </div>
            {/* end::Illustration */}
          </div>
          <button
            className={`btn ${styles.pointer} ${styles.Btn} `}
            type='button' onClick={() => handleAgentActivateClick(selectedAgent)}>Submit</button>
        </div> </div>

    </>
  )
}

export { Step5 }
