/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useAuth } from '../../../../app/modules/auth'
import Avatar from '@mui/material/Avatar';
import { fetchLoginCookie, decryptData,AllOrgs,} from '../../../../api'
import { BuildingOffice } from "@phosphor-icons/react";
import styles from '../../../layout/components/header/Header.module.scss'
import { Tooltip } from '@mui/material';

interface props {
  orgs: AllOrgs[];
  setActiveOrg: Dispatch<SetStateAction<boolean>>;
  switchOrg: (orgid: number) => void;
  activeOrgName: AllOrgs | undefined;
  userOrgId: number
}
const HeaderUserMenu = ({ orgs, setActiveOrg, switchOrg, activeOrgName, userOrgId }: props) => {

  const { currentUser, logout } = useAuth()
  const [firstName, setFirstName] = useState('');
  const [Email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {

    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const first_name = decryptedRes?.first_name
    const last_name = decryptedRes?.last_name
    const email = decryptedRes?.user
    const userId = parseInt(decryptedRes?.id)
    const org = decryptedRes?.org_id
    if(userId){
      setLastName(last_name)
      setFirstName(first_name)
    }
    else{
      setFirstName('Anonymous')
      setLastName('User')
    }
    if(email){
      setEmail(email)
    }
  
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-3 fs-6 px-4 me-3'
      data-kt-menu='true' style={{ wordBreak: 'break-all', maxWidth: 'fit-content', minWidth: '275px' }}
    >
      <div className='p-2 d-flex justify-content-between align-items-center'>
        {activeOrgName?.organization.org_name &&
          <Tooltip title={activeOrgName?.organization.org_name}><span className='fw-light'>{activeOrgName?.organization?.org_name?.length > 25 ? activeOrgName?.organization.org_name.slice(0, 24) + `...` : activeOrgName?.organization.org_name}</span></Tooltip>
        }
        <span className='fw-light text-hover-primary' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={logout}>Sign Out</span>
      </div>
      <div className='menu-item pt-1' >
        <div className='menu-content d-flex align-items-center px-3'>
          <div className=' me-5 d-flex flex-column'>
            <div>
            </div>
            <div className='d-flex symbol align-items-center symbol-50px'>
              <Avatar sx={{ bgcolor: '#b5b5b5' }} className='ms-1 me-2 fw-light'>{firstName ? firstName[0] : Email[0]}{lastName ? lastName[0] : ''}</Avatar>
              <div className='d-flex flex-column'>
                <span style={{ fontWeight: 400, textTransform: 'capitalize' }}>{firstName ? firstName : ''}&nbsp;{ lastName ? lastName : ''} </span>
                <Tooltip title={Email}><span className='text-muted' style={{ fontWeight: 300, fontSize: "12px" }}>{Email.length > 30 ? Email.slice(0, 29) + '...' : Email}</span></Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-1'></div>

      <div className='menu-item'>
        {
          orgs && orgs.length > 0 && orgs?.map((org, index) => {
            return (
              <div key={index} style={{ backgroundColor: userOrgId == org.organization.id ? '#eee' : '' }} className={`px-5 ${styles.orgItem}`} onClick={() => switchOrg(org.organization.id)}>
                <div className={styles.orgProfile}><BuildingOffice color='white' size={26} weight="regular" /></div>
                <div className='d-flex flex-column fw-light ps-2'>
                  <Tooltip title={org.organization.org_name}><span style={{ fontWeight: '400' }}>{org.organization.org_name.length > 35 ? org.organization.org_name.slice(0, 34) + `...` : org.organization.org_name}</span></Tooltip>
                  <Tooltip title={org.organization.domain}><span>{org.organization.domain.length > 25 ? org.organization.domain.slice(0, 29) + `...` : org.organization.domain}</span></Tooltip>
                </div>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export { HeaderUserMenu }
