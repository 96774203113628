import styles from './agentAliasStyles.module.scss'
import { useEffect, useState } from 'react';
import { FormControl,  ListItemText, MenuItem,CircularProgress, IconButton, } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as XLSX from 'xlsx';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal } from 'react-bootstrap';
import clsx from 'clsx';
import EditIcon from '@mui/icons-material/Edit';
import { AgentAliasResponse, AgentCardResponse, createAgentAlias, decryptData, deleteAgentAlias, editAgentAlias, fetchLoginCookie, getAgentsWithAlias, getAllAgents } from '../../../../api';
import Swal from 'sweetalert2';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


interface AgentAliasExcelFields {

    Agent: string | null;
    Project: string;
    Email_Alias: string;
}

export const AgentAlias = () => {

    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [agentAliasData, setAgentAliasData] = useState<AgentAliasResponse[]>([]);
    const [allAgents, setAllAgents] = useState<AgentCardResponse[]>([]);
    const [agentAliasExcelData, setAgentAliasExcelData] = useState<AgentAliasExcelFields[]>([]);
    const [showAddAliasModal, setShowAddAliasModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isEditLoading, setIsEditLoading] = useState(false);
    const [emailAlias, setEmailAlias] = useState<string>("");
    const [agentToAdd, setAgentToAdd] = useState<string>("");
    const [agentId, setAgentId] = useState<number>();
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const renderEditAndDeleteButton = (params) => {
        return (
            <><IconButton color='primary'
                type='button' onClick={() => setOnEdit(params.row.id, params.row.agent_name, params.row.alias)}><EditIcon fontSize='medium' />
            </IconButton>
                <IconButton color='error'
                    type='button' onClick={() => setOnDelete(params.row.id)}><DeleteOutlineOutlinedIcon
                        fontSize='medium' />
                </IconButton>
            </>
        )
    }


    const columns: GridColDef[] = [
        {
            field: 'agent_name',
            renderHeader: (params) => (
                <div className='text-start text-muted fs-7 text-uppercase gs-0' >
                    {'Agents '}
                </div>),
            width: 250,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'project',
            valueFormatter: ({ value }) => value.project_name,
            renderHeader: (params) => (
                <div className='text-start text-muted fs-7 text-uppercase gs-0'>
                    {'Projects '}
                </div>),
            width: 300,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1
        },
        {
            field: 'alias',
            renderHeader: (params) => (
                <div className='text-start text-muted fs-7 text-uppercase gs-0'
                >
                    {'Aliases '}
                </div>),
            width: 400,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            flex: 1

        },
        {
            editable: false,
            sortable: false,
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            field: 'action',
            renderHeader: (params) => (
                <div className='text-start text-muted fs-7 text-uppercase gs-0'>
                    {'Action '}
                </div>),
            width: 80,
            renderCell: renderEditAndDeleteButton,
        },
    ];

    useEffect(() => {
        fetchAgentsWithAliases();
    }, [])

    const fetchAgentsWithAliases = async () => {
        setIsLoading(true)

        const response = await getAgentsWithAlias()

        setAgentAliasData(response)
        const excelData = response.map(item => {
            return {
                Agent: item?.agent_name,
                Project: item?.project?.project_name,
                Email_Alias: item?.alias
            };
        });

        setAgentAliasExcelData(excelData);
        setIsLoading(false)
    }


    const fetchAllAgents = async () => {
        const response = await getAllAgents();
        if (response.length > 0 && agentAliasData.length > 0) {
            const filteredAgents = response.filter(agent =>
                !agentAliasData.some(aliasAgent => aliasAgent.id === agent.id)
            );
            setAllAgents(filteredAgents)
        }
        else {
            setAllAgents(response)
        }
    }


    const isOptionEqualToValue = (option, value) => {
        return option.endsWith(value); // Compare options based on email part
    };


    const exportToExcel = (data, filename: string) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${filename}.xlsx`);  // Use .xlsx extension instead of .xls
    };


    const openAddAliasModal = () => {
        fetchAllAgents()
        setShowAddAliasModal(true)
    }

    const closeAddAliasModal = () => {
        setShowAddAliasModal(false)

    }

    const handleEmailAliasChange = (e) => {
        setEmailAlias(e.target.value);
    };

    const cancel = () => {
        setAgentToAdd("");
        setAgentId(undefined);
        setEmailAlias("");
        setIsEdit(false);
        closeAddAliasModal();
    }

    const handleAgentChange = (event, value) => {
        if (value) {
            const parts = value.split("-");
            if (parts.length === 2) {
                setAgentId(parseInt(parts[0]))
                setAgentToAdd(parts[1]);
            }
        }
    };

    const submitAgentAlias = async () => {
        if (agentId) {
            setIsEditLoading(true)
            const response = await createAgentAlias(agentId, emailAlias)
            const result = JSON.parse(JSON.stringify(response));
            if (result.error == "Duplicate alias") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'This alias already exists'
                });
            }
            else if (result.error == "Alias creation failed") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Alias creation failed'
                });
            }
            else {
                setAgentAliasData(response)
                const excelData = response.map(item => {
                    return {
                        Agent: item?.agent_name,
                        Project: item?.project?.project_name,
                        Email_Alias: item?.alias
                    };
                });
                setAgentAliasExcelData(excelData);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Agent alias created successfully!'
                });
            }
            setIsEditLoading(false)
            cancel();
        }
    }

    const setOnEdit = async (agent_id, agent_name, alias) => {
        setAgentId(agent_id);
        setAgentToAdd(agent_name);
        setEmailAlias(alias)
        setIsEdit(true);
        openAddAliasModal();
    }


    const setOnDelete = async (agent_id: any) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                removeAgentAlias(agent_id);
            }
        })
    }


    const modifyAgentAlias = async () => {
        if (agentId) {
            setIsEditLoading(true)
            const response = await editAgentAlias(agentId, emailAlias)

            const result = JSON.parse(JSON.stringify(response));
            if (result.error == "Alias edit failed") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Alias edit failed'
                });
            }
            else {
                setAgentAliasData(response)
                const excelData = response.map(item => {
                    return {
                        Agent: item.agent_name,
                        Project: item.project.project_name,
                        Email_Alias: item.alias
                    };
                });
                setAgentAliasExcelData(excelData);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Agent edited successfully!'
                });

                setIsEditLoading(false)
                cancel();

            }
        }
    }

    const removeAgentAlias = async (agent_id: any) => {
        if (agent_id) {
            setIsLoading(true)
            const response = await deleteAgentAlias(agent_id)
            const result = JSON.parse(JSON.stringify(response));
            if (result.error == "Alias delete failed") {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Alias delete failed'
                });
            }
            else {
                setAgentAliasData(response)
                const excelData = response.map(item => {
                    return {
                        Agent: item.agent_name,
                        Project: item.project.project_name,
                        Email_Alias: item.alias
                    };
                });
                setAgentAliasExcelData(excelData);
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Alias deleted successfully!'
                });

                setIsLoading(false)

            }
        }
    }

    return (
        <div className='ms-20 me-20'>
            {/* <div className='d-flex justify-content-end mb-4' data-kt-user-table-toolbar='base'>
                <button type='button' className='btn btn-primary' onClick={openAddAliasModal}>
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                    Add Agent Alias
                </button>
            </div> */}
            <div className="d-flex flex-row-fluid justify-content-end gap-5">
                <button type="button" className="btn btn-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" onClick={() => exportToExcel(agentAliasExcelData, 'agent_aliases')}>
                <KTSVG path='/media/icons/duotune/files/fil021.svg' className='svg-icon-2' />Export Aliases
                </button>
            </div>
            <Box sx={{ height: '100%', width: '100%', backgroundColor: activeTheme === 'dark' ? '#0d282e' : 'white', mt: 1, mb: 1 }}>
                {!isLoading ? (
                    <>
                        {agentAliasData && agentAliasData.length > 0 ? (
                        <DataGrid
                            rows={agentAliasData}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            sx={{
                                fontSize: 14,
                          
                                color: activeTheme === 'dark' ? 'lightgray' : 'black',
                                fontWeight:300
                            }}
                            pageSizeOptions={[5]}
                            disableRowSelectionOnClick
                        />) :
                            (<p className={styles.muted}>No Agent Aliases Found</p>)}
                    </>) : (
                    <div className={styles.CircularProgress}>
                        <CircularProgress /></div>
                )}
            </Box>

            <Modal

                show={showAddAliasModal}
                onHide={cancel}
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{isEdit ? 'Edit Agent Alias' : 'Add Agent Alias'}</Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <form id='kt_modal_add_user_form' className='form' noValidate>
                        {/* begin::Scroll */}
                        <div
                            className='d-flex flex-column scroll-y me-n7 pe-7'
                            id='kt_modal_add_user_scroll'
                            data-kt-scroll='true'
                            data-kt-scroll-activate='{default: false, lg: true}'
                            data-kt-scroll-max-height='auto'
                            data-kt-scroll-dependencies='#kt_modal_add_user_header'
                            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                            data-kt-scroll-offset='300px'
                        >

                            <div className='fv-row mb-7'>

                                <FormControl sx={{ mt: 1, width: "100%", height: "35%" }}>
                                    <Autocomplete
                                        id="demo-multiple-checkbox"
                                        options={allAgents.map((agent) => `${agent.id}-${agent.agent_name}`)}
                                        value={agentToAdd}
                                        isOptionEqualToValue={isOptionEqualToValue}
                                        getOptionDisabled={(option) =>
                                            parseInt(option.split("-")[0]) === agentId
                                        }
                                        onChange={handleAgentChange}
                                        fullWidth
                                        disabled={isEdit}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Agents"
                                                InputLabelProps={{
                                                    style: {
                                                        color: (activeTheme === 'dark' ? 'white' : 'black')
                                                    }
                                                }}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                    style: { color: activeTheme === 'dark' ? 'grey' : 'black'}

                                                }}
                                            />
                                        )}
                                        sx={{
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: activeTheme === 'dark' ? 'grey' : 'black',
                                            }
                                        }}
                                        renderOption={(props, agent) => (
                                            <MenuItem {...props}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ListItemText primary={agent.split('-')[1]} />
                                                </div>
                                            </MenuItem>
                                        )}
                                    />
                                </FormControl>



                            </div>

                            {/* begin::Input group */}
                            <div className='fv-row mb-7'>
                                {/* begin::Label */}
                                <label className='required fs-6 mb-2'>Email Alias</label>
                                {/* end::Label */}
                                {/* begin::Input */}
                                <input
                                    placeholder='Email'
                                    onChange={handleEmailAliasChange}
                                    value={emailAlias}
                                    className={clsx(
                                        'form-control form-control-solid mb-3 mb-lg-0',
                                    )}
                                    type='email'
                                    name='email'
                                    autoComplete='off'
                                />
                                {/* end::Input */}

                            </div>
                            {/* end::Input group */}

                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='text-center'>
                        <button
                            type='reset'
                            onClick={cancel}
                            className='btn btn-light me-3'
                            data-kt-users-modal-action='cancel'

                        >
                            Discard
                        </button>
                        {isEditLoading ? (
                            <button className='btn btn-secondary' >
                                Please wait
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </button>

                        ) :
                            isEdit ? <button
                                className='btn btn-primary'
                                onClick={modifyAgentAlias}
                            >
                                <span className='indicator-label'>Edit</span>

                            </button> : <button
                                className='btn btn-primary'
                                onClick={submitAgentAlias}
                            >
                                <span className='indicator-label'>Submit</span>

                            </button>
                        }
                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    );
}