//all agents

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Card2 } from '../../../../_metronic/partials/content/cards/Card2'
import { AgentCardResponse, AgentCardResponse2, RatingsResponse, decryptData, fetchLoginCookie, getAgentsByProject, getAllRatings, getUserRole } from '../../../../api';
import { CreateAppModal } from '../../../../_metronic/partials'
import { KTSVG } from '../../../../_metronic/helpers';
// import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../ProfileStyle.module.css'
import { useParams } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Plus } from '@phosphor-icons/react';

type Props = {
  newProjectId?: number;
  agentUpdateAfterDelete: boolean
  setAgentUpdateAfterDelete: Dispatch<SetStateAction<boolean>>
  setCurrentTab?: Dispatch<SetStateAction<string>>
  setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;

}

export function AllAgents({ setRefreshChatPageOnAgentCreation, setAgentUpdateAfterDelete, agentUpdateAfterDelete, newProjectId, setCurrentTab }: Props) {
  const { agentId } = useParams()
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [ispromptDataLoading, setispromptDataLoading] = useState(false);
  const [agentData, setAgentData] = useState<AgentCardResponse2[]>([]);
  const [agentActiveConfig, setAgentActiveConfig] = useState<number>();
  const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
  // const [allReviews, setAllReviews] = useState<ReviewResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
  const [isScreenSmall, setIsScreenSmall] = useState(false);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const width = window.innerWidth;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [projectId, setProjectId] = useState<number>();
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const activeProject = localStorage.getItem("Active-Project");
    if (userId) {
      setLoggedInUserId(userId);
      getAllAgentRatings()
    }
    if (activeProject) {
      const parts = activeProject.split('-');
      const projectId = parseInt(parts[0]);

      if (!isNaN(projectId)) {
        getAgentsOfProject(projectId);
        fetchUserRole(projectId)
        fetchUserActiveAgentConfig(userId);
      }
    }
  }, [localStorage.getItem("Active-Project"), newProjectId]);

  const getAgentsOfProject = async (project_id: number) => {
    try {
      setLoading(true)
      setispromptDataLoading(true)
      const response = await getAgentsByProject(project_id, 1);
      const agent = response.agents;
      const totalpages = response.num_pages;
      setTotalPages(totalpages);
      setispromptDataLoading(false)
      setAgentData(agent);
      setLoading(false)
      setProjectId(project_id);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchUserActiveAgentConfig = async (id: any) => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        setAgentActiveConfig(parsedAgentID);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClose = () => {
    setShowCreateAppModal(false)
  }

  const getAllAgentRatings = async () => {
    try {
      const response = await getAllRatings();
      setRatingsData(response);
    } catch (error) {
      console.error(error);

    }
  };
  useEffect(() => {
    // Function to check and update the screen width
    function handleScreenWidth() {
      setIsScreenSmall(window.innerWidth <= 405);
    }

    // Add an event listener to listen for changes in screen width
    window.addEventListener('resize', handleScreenWidth);

    // Initial check when the component mounts
    handleScreenWidth();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleScreenWidth);
    };
  }, []);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      setLoading(true)
      setispromptDataLoading(true)
      const response = await getAgentsByProject(projectId, page);
      const agent = response.agents;
      const totalPages = response.num_pages;
      setTotalPages(totalPages);
      setispromptDataLoading(false)
      setAgentData(agent);
      setLoading(false)
    } catch (error) {
      console.error(error);
    }

  }
  const fetchUserRole = async (projectID: any) => {
    const result = await getUserRole(projectID);
    if (result) {
      setUserRole(result.role)
    }
  }
  return (
    <>
      <div>
        <div className='d-flex flex-wrap flex-stack mb-6' >
          <h3 className={` my-2 ${styles.title}`}>
            Agents
          </h3>

          <div className='d-flex align-items-center my-2'>

            {/* begin::Search */}
            <div className='d-flex align-items-center position-relative my-1'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className={`${styles.searchInput} form-control form-control-solid w-250px ps-14`}
                placeholder='Search agent'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ fontWeight: 300 }}

              />
            </div>
            {/* end::Search */}
            <div>
              <Link to="/configureAgent">
                <button className={` btn btn-md ${styles.customButton}`}>{
                  isScreenSmall ? <Plus size={20} weight="light" /> :
                    'Add Agent '
                }</button>
              </Link>
            </div>

            <CreateAppModal setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} getAgentsOfProject={getAgentsOfProject} naming='Agent' show={showCreateAppModal} handleClose={handleClose} currentUserId={loggedInUserId!} />
          </div>
        </div>
        <div className={`${styles.agentCardMain} row g-6 g-xl-9`} style={{flexDirection: "column", alignItems: "center"}} >
            <div className='row g-3 g-xl-9' style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
        
            {!ispromptDataLoading && loggedInUserId ? (
              <>
                {
                  agentData && agentData.length > 0 ? (
                    agentData.filter((item) => item.created_by !== "System")
                      .filter((item) => {
                        // Filter agents based on search query
                        return item.agent_name.toLowerCase().includes(searchTerm.toLowerCase());
                      })
                      .map((item: AgentCardResponse2, index: number) => {
                        return (
                          <div className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`}>
                            <Card2
                              setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                              setAgentUpdateAfterDelete={setAgentUpdateAfterDelete}
                              agentUpdateAfterDelete={agentUpdateAfterDelete}
                              setAgentActiveConfig={setAgentActiveConfig}
                              setAgentData={setAgentData}
                              key={index}
                              data={item}
                              currentUserId={loggedInUserId!}
                              viewMode='default'
                              allRatings={allRatings}
                              setRatingsData={setRatingsData}
                              setCurrentTab={setCurrentTab}
                              userRole={userRole}
                            />
                          </div>

                        );
                      })
                  ) : (
                    <p className={styles.muted}>No Agents Found</p>
                  )
                }
              </>
            ) : (
              <div className={styles.CircularProgress}>
                <CircularProgress /></div>
            )}

          </div>
        </div>
        {
          !ispromptDataLoading && <Stack className='pagination' style={{ position: agentData.length > 0 ? 'relative' : 'fixed', left: agentData.length > 0 ? '50%' : '60%' }} spacing={2} justifyContent="center" alignItems="center" mt={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(event, value) => handlePageChange(value)}
              sx={{ button: { color: 'grey' } }}
              variant='outlined'
            />
          </Stack>
        }

      </div>

    </>
  )
}
