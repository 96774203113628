import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { AllAgents } from './components/AllAgents'
import { MyAgents } from './components/MyAgents'
import { Templates } from './components/Templates'
import { ProfileHeader } from './ProfileHeader';
import styles from './ProfileStyle.module.css'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/crafted/pages/profile/overview',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
interface props {
  setProjectChange: Dispatch<SetStateAction<boolean>>
  agentUpdateAfterDelete: boolean;
  setAgentUpdateAfterDelete: Dispatch<SetStateAction<boolean>>
  setCurrentTab: Dispatch<SetStateAction<string>>
  setRefreshChatPageOnAgentCreation:Dispatch<SetStateAction<boolean>>

}


const ProfilePage = ({ setRefreshChatPageOnAgentCreation, setAgentUpdateAfterDelete, setProjectChange, agentUpdateAfterDelete, setCurrentTab }: props) => {
  const [newProjectId, setNewProjectId] = useState<number>();

  const handleProjectId = async (id: number) => {
    setNewProjectId(id);
  };



  return (
    <Routes>
      <Route
        element={
          <>
            <ProfileHeader setProjectChange={setProjectChange} onProjectIDArrival={handleProjectId} />
            <Outlet />
          </>
        }
      >
        <Route
          path='agents'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs} ><p className={styles.title}>Agents</p></PageTitle>
              < AllAgents setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} newProjectId={newProjectId} setCurrentTab={setCurrentTab} />
            </>
          }
        />
        <Route
          path='myagents'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}><p className={styles.title}>My Agents</p></PageTitle>
              <MyAgents setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} newProjectId={newProjectId} />
            </>
          }
        />
        <Route
          path='templates'
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}><p className={styles.title}>Featured Agents</p></PageTitle>
              <Templates setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} />
            </>
          }
        />
        <Route index element={<Navigate to='/project/agents/agents' />} />
      </Route>
    </Routes>
  )
}
export default ProfilePage


