import React, { useState, Dispatch, SetStateAction, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ChatCommentRequest, ChatCommentResponse, commentChat } from '../../../api';
import styles from '../../layout/components/AgentToolPanel.tsx/AgentToolPanel.module.css'
import SendIcon from '@mui/icons-material/Send'
import { Feedback } from './singleFeedback';



interface Props {
    setShowFeedbackModal: Dispatch<SetStateAction<boolean>>;
    showFeedbackModal: boolean;
    feedbackComments: ChatCommentResponse[];
    chat_id: number;
    user_email: string;
    isReadOnly?: boolean;
}

const Feedbacks = ({ setShowFeedbackModal, showFeedbackModal, feedbackComments, chat_id, user_email , isReadOnly}: Props) => {

    const modalBodyRef = useRef<HTMLDivElement | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [feedback, setFeedback] = useState('');
    const [comments, setComments] = useState<ChatCommentResponse[]>(feedbackComments);

    const handleClose = () => {
        setShowFeedbackModal(false);
    };

    const handleSendClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true)
        e.preventDefault()
        const request: ChatCommentRequest = {
            comment: feedback,
            chat_id: chat_id,
            created_by: user_email,
        }

        const response = await commentChat(request)
        if (response) {
            setComments(response.comments);
        }
        setFeedback('')
        setIsLoading(false)
    }
    const handleFeedbackChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFeedback(e.target.value)
    }
    const onEnterPress = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            setIsLoading(true)
            e.preventDefault()
            const request: ChatCommentRequest = {
                comment: feedback,
                chat_id: chat_id,
                created_by: user_email,
            }

            const response = await commentChat(request)
            if (response) {
                setComments(response.comments);
            }
            setFeedback('')
            setIsLoading(false)
        }
    }
    return (
        <div>
      
            <Modal
                show={showFeedbackModal}
                onHide={handleClose}
                backdrop='static'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                keyboard={true}
                dialogClassName="custom-modal-content"
            >
                <Modal.Header closeButton>
                    <Modal.Title  id='contained-modal-title-vcenter ' className='cardHeadings'>{isReadOnly ? 'Feedback Comments' : 'Additional Feedback'}</Modal.Title>
                </Modal.Header>

                <Modal.Body ref={modalBodyRef} className={styles.modalBody}>
                    {comments && comments.length > 0 ? (
                        <div style={{ maxHeight: '380px' }}>
                            <div className='comment-list'>
                                {comments.map((comment) => (
                                    <Feedback
                                        key={comment.id}
                                        comment={comment}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className='d-flex justify-content-center align-items-center'>
                            <div >No feedback(s) yet</div>
                        </div>
                    )}
                </Modal.Body>

                {!isReadOnly && (
                <form  >
                    <div className={`d-flex align-items-center justify-content-center ${styles.form}`} >
                        <div className={styles.textarea}>
                            <textarea
                                onKeyDown={onEnterPress}
                                id='user_comment'
                                name='user_comment'
                                rows={1}
                                className={`form-control ${styles.textArea}`}

                                placeholder='Write your comment here...'
                                required
                                value={feedback}
                                onChange={handleFeedbackChange}
                            />
                        </div>
                        <div >
                            <button
                                type='submit'
                                className={`'btn  mt-2  ${styles.Button}`}

                                onClick={handleSendClick}
                                disabled={!feedback.trim()} // Disable the button if comment is empty or contains only whitespace
                            >
                                {!isLoading ? (
                                    <SendIcon className={styles.icon} />
                                ) : (
                                    <span className='indicator-progress d-block'>
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button></div>
                    </div>
                </form>
                )}
            </Modal>
        </div>
    );
};

export default Feedbacks;
