import {  ChangeEvent, SetStateAction, Dispatch, useEffect } from 'react';
import Swal from 'sweetalert2';
import styles from './Step.module.scss'

interface Props {
    setYoutubeLinks: Dispatch<SetStateAction<string>>;
    youtubeLinks: string;
    setWebLinks: Dispatch<SetStateAction<string>>;
    webLinks: string;
    setytUrl:Dispatch<SetStateAction<string[]>>;
    setwebUrl:Dispatch<SetStateAction<string[]>>;
    clearLinks: () => void; 
    isClear: boolean;
    setIsClear: Dispatch<SetStateAction<boolean>>;
}

export const LinkInput = ({ setYoutubeLinks, youtubeLinks,setytUrl,setwebUrl, clearLinks, isClear, setIsClear }: Props) => {
    const handleInputChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
        setYoutubeLinks(e.target.value);
        const linksArray = e.target.value.split('\n').map((link) => link.trim());

        const youtubeUrls: string[] = [];
        const webUrls: string[] = [];

        for (const link of linksArray) {
            if (link === '') {
                continue;
            }
            if (isYoutubeUrl(link)) {
                youtubeUrls.push(link);
            } else {
                webUrls.push(link);
            }
        }

        setytUrl(youtubeUrls);
        setwebUrl(webUrls);

        // Check for duplicates in youtubeUrls and webUrls arrays
        const youtubeDuplicates = findDuplicates(youtubeUrls);
        const webDuplicates = findDuplicates(webUrls);

        if (youtubeDuplicates.length > 0 || webDuplicates.length > 0) {
            // Handle duplicates using Swal
            Swal.fire({
                icon: 'error',
                title: 'Duplicate link(s) found',
                text: `YouTube duplicates:\n${youtubeDuplicates.join(
                    '\n'
                )}\nWeb link duplicates:\n${webDuplicates.join('\n')}`,
            });
        }
    };

    const isYoutubeUrl = (url: string) => {
        // Regular expression to match YouTube video URLs
        const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+/;
        return youtubeRegex.test(url);
    };

    const findDuplicates = (array: string[]) => {
        const uniqueSet = new Set<string>();
        const duplicates: string[] = [];

        for (const item of array) {
            if (item === '') {
                continue;
            }
            if (uniqueSet.has(item)) {
                duplicates.push(item);
            } else {
                uniqueSet.add(item);
            }
        }

        return duplicates;
    };

    useEffect(() => {
        if (isClear){
            clearLinks();
            setIsClear(false);
        }
      }, [isClear]);

      useEffect(() => {
        const textarea = document.getElementById('youtubeTextarea');
        if (textarea) {
          textarea.style.height = 'auto'; 
          const newHeight = Math.min(215, textarea.scrollHeight); 
          textarea.style.height = `${newHeight}px`; 
        }
      }, [youtubeLinks]);
    
    return (
        <div className='overflow-auto'  style={{minHeight:"150px", }}>
        <div className='d-flex flex-column g-2'>
          <textarea
            id='youtubeTextarea'
            rows={3} 
            style={{ minHeight: '100px', resize: 'none', overflowY: 'auto', scrollbarWidth:"thin" }} 
            className='form-control form-control-lg form-control-solid fw-normal custom-scrollbar'
            placeholder={`Paste Link(s). Make sure each link is in a separate line`}
            value={youtubeLinks}
            onChange={handleInputChange}
          />
          <p className={styles.textDanger}>
            Enter only transcript-enabled Youtube links. For bulk indexing, make sure each link is in a separate line
          </p>
        </div>
      </div>

    );
};
