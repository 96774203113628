import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import {  getOneAgent } from '../../../../../api/api';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { AgentCardResponse, decryptData, fetchLoginCookie, sharePointLink } from '../../../../../api';
import styles from './Step.module.scss'
import { Tooltip } from '@mui/material';
import { CopySimple, Check } from "@phosphor-icons/react";

interface EmailAliasProps {
    emailAlias: string
}

export const EmailAlias = ({emailAlias}:EmailAliasProps) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleEmailClick = () => {
        navigator.clipboard.writeText(emailAlias!);
        setIsCopied(true);
    };

    return (
        <div className={styles.emailAlias} style={{ paddingTop: "2%", marginTop: "3%", marginBottom: "8rem", display: "flex", justifyContent: "center" }}>
            <InputGroup className="mb-3" style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <div style={{ flexGrow: 1 }} className={styles.emailingInput}>
                    <textarea style={{ padding: "11px 0 0 0", width: "100%", fontSize: "15px", paddingLeft:"14px" }}
                        className={styles.scrollableInput}
                        value={emailAlias}
                        readOnly
                        aria-label="Email indexing"
                        aria-describedby="basic-addon2"
                    />
                    <Tooltip title={isCopied ? "Email alias copied!" : "Copy email alias"}>
                        <button
                            onClick={handleEmailClick}
                            type='button'
                            className={`btn ${isCopied ? 'btn-success' : 'btn-primary'}`}
                            data-kt-menu-placement='bottom-end'
                            style={{ borderRadius: "4px" }}
                        >
                            {isCopied ? <Check size={27} color="#ffffff" weight="bold" /> : <CopySimple size={26} color="#ffffff" weight="bold" />}
                        </button>
                    </Tooltip>
                </div>
            </InputGroup>
        </div>

    )
}