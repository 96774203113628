import { SidebarFooter } from '../SidebarFooter';
import {SidebarMenuMain} from './SidebarMenuMain'
import { Dispatch, SetStateAction } from 'react'
import styles from './Sidebar.module.css';
import { Link } from 'react-router-dom';
interface props{
  projectChange:boolean;
  setProjectChange:Dispatch<SetStateAction<boolean>>;
  toggle:boolean;
  setToggle:Dispatch<SetStateAction<boolean>>;
}

const SidebarMenu = ({projectChange,setProjectChange,toggle,setToggle}:props) => {
  return (
    <div className='app-sidebar-menu overflow-hidden flex-column-fluid '   >
      <div 
        id='kt_app_sidebar_menu_wrapper'
        className={`app-sidebar-wrapper hover-scroll-overlay-y ${styles.sidebarMenu}`}
        data-kt-scroll='true'
        data-kt-scroll-activate='true'
        // data-kt-scroll-height='auto'
        data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
        data-kt-scroll-wrappers='#kt_app_sidebar_menu'
        data-kt-scroll-offset='5px'
        data-kt-scroll-save-state='true'
      
 
      >
        <div
          className='menu  menu-rounded menu-sub-indention '
          id='#kt_app_sidebar_menu'
          data-kt-menu='true'
     
          data-kt-menu-expand='false'
        >    
          <SidebarMenuMain setToggle={setToggle} toggle={toggle} projectChange={projectChange} setProjectChange={setProjectChange} />      
        </div>
      </div> 
      {/* <SidebarFooter/> */}    
    </div>   
  )
}

export {SidebarMenu}
