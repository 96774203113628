import { TotalCounts, getTotalCounts } from "../../../../../api";
import { useEffect, useState } from 'react'
import styles from "./style.module.css";

export const TopPanel = () => {
    const [totalCount, setTotalCount] = useState<TotalCounts>()
    useEffect(() => {
        getTotalCount()
    }, [])

    const getTotalCount = async () => {
        try {
            const response = await getTotalCounts()
            if (response.count_data) {
             
                setTotalCount(response)
           
            
            }

        }
        catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className={`d-flex w-100 justify-content-evenly ${styles.parent} flex-wrap`}>
                <div className={`${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.userCount}</div>
                    <span className={`${styles.text}`} >Users</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.userPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.teamCount}</div>
                    <span className={`${styles.text}`} >Teams</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.teamPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>
                {/* <div className={`${styles.hr}`}></div> */}

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.projectCount}</div>
                    <span className={`${styles.text}`} >Projects</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.projectPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.agentCount}</div>
                    <span className={`${styles.text}`} >Agents</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.agentPercentage}% increase in last 30 days</p>
                </div>

            </div>
        </>
    );
};

