import { User, deleteUsers } from '../../../../../../../api'
import { useListView } from '../../core/ListViewProvider'
import Swal from 'sweetalert2'
import { Dispatch, SetStateAction } from 'react'


interface Props {
  setAllUsers: Dispatch<SetStateAction<User[]>>;
  setFilteredUsers: Dispatch<SetStateAction<User[]>>;
}

const UsersListGrouping = ({ setAllUsers , setFilteredUsers}: Props) => {
  const { selected, clearSelected } = useListView()

  const showDeleteConfirmation = async () => {
    const result = await Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, Delete User!',
    });

    if (result.isConfirmed) {
      deleteSelectedUsers();
    }
  };

  const deleteSelectedUsers = async () => {
    const user_ids = selected.filter(id => typeof id === 'number') as number[]
    const response = await deleteUsers(user_ids)
    if (response) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'User(s) deleted successfully!',
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          clearSelected()
          if (response.length > 0) {
            const processedUsers = response.map((user) => ({
              id: user.id,
              name: `${user.first_name || ""} ${user.last_name || ""}`,
              email: user.email,
              organization:user.organization,
              role: user.is_superuser ? "Global Admin" : user.is_staff ? "Application Admin" : "User",
              date_joined: user.date_joined
                ? new Date(user.date_joined).toLocaleString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
                : 'No Data',

            }));
            setAllUsers(processedUsers);
            setFilteredUsers(processedUsers);
          }
        }
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Failed to Create a User'
      });
    };
  }

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5'>
        <span className='me-2'>{selected.length}</span> Selected
      </div>

      <button
        type='button'
        className='btn btn-danger'
        onClick={showDeleteConfirmation}
      >
        Delete Selected
      </button>
    </div>
  )
}

export { UsersListGrouping }
